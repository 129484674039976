import React, { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { injectIntl } from "react-intl";
import { withRouter, useLocation } from "react-router-dom";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import NavPills from "components/NavPills/NavPills.js";
import IconCard from "components-lib/IconCard/IconCard";
import InvoiceTable from "./InvoiceTable";
import ALL_INVOICES from "queries/InvoicesAndPayoutsQueries/allInvoices";
import ALL_PAYOUTS from "queries/InvoicesAndPayoutsQueries/allPayouts";
import Modal from "components-lib/Modal/Modal";
import PageHeader from "components-lib/PageHeader/PageHeader";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import InvoiceCard from "./InvoiceCards/InvoiceCard";
import { getValidationSchema, getValidationSchemaPayout } from "./validationSchema";
import INVOICE_CREATE from "queries/InvoicesAndPayoutsQueries/invoiceCreate"
import PAYOUT_CREATE from "queries/InvoicesAndPayoutsQueries/payoutCreate"
import Button from "components/CustomButtons/Button.js";
import { invoiceCreate, payoutCreate } from 'configFiles/roles'

const InvoicesListPage = (props) => {
    const { intl, history } = props;

    let location = useLocation();
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [assignModalOpenPayout, setAssignModalOpenPayout] = useState(false);
    const [maxCredits, setMaxCredits] = useState(false);
    const [activeState, setActiveState] = useState(0);

    const [loadInvoices, { data: dataInvoices, refetch: refetchInvoices }] = useLazyQuery(ALL_INVOICES);
    const [loadPayouts, { data: dataPayouts, refetch: refetchPayouts }] = useLazyQuery(ALL_PAYOUTS);
    const [userData] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [createInvoice] = useMutation(INVOICE_CREATE);
    const [createPayout] = useMutation(PAYOUT_CREATE);

    const handleCreateInvoice = (values, resetForm) => {
        createInvoice({
            variables: {
                input: values,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "invoicesList.createInvoiceSuccessfull", }), { variant: "success" });
            refetchInvoices();
            setAssignModalOpen(false);
            resetForm();
        }).catch((err) => {
            console.log('[error]', err)
        });
    };

    const handleCreatePayout = (values, resetForm) => {
        createPayout({
            variables: {
                input: values,
            },
        })
            .then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: "payoutsList.createPayoutSuccessfull", }), { variant: "success" });
                refetchPayouts();
                setAssignModalOpenPayout(false);
                resetForm();
            })
            .catch((err) => {
                console.log('[error]', err)
            });
    };

    const handleCloseModal = (resetForm) => {
        setAssignModalOpenPayout(false);
        setAssignModalOpen(false);
        resetForm();
    };

    const getActions = () => {

        if (activeState === 0) {
            if (invoiceCreate)
                return [
                    {
                        title: intl.formatMessage({ id: "invoicesList.addModal.title" }),
                        onClick: () => setAssignModalOpen(true),
                    }
                ]
        }
        else {
            if (payoutCreate)
                return [
                    {
                        title: intl.formatMessage({ id: "payoutsList.addModal.title" }),
                        onClick: () => setAssignModalOpenPayout(true),
                    }
                ];
        }
    };

    const renderAssignActions = (formikProps, type) => {
        return (
            <>
                <Button
                    disabled={!formikProps.values.identifier || !formikProps.values.companyId
                        || !formikProps.values.price || !formikProps.values.credit}
                    size="sm"
                    color="success"
                    onClick={(e) => formikProps.handleSubmit()}
                    round
                >
                    {(type) ? intl.formatMessage({ id: "invoicesList.assignModal.create" }) : intl.formatMessage({ id: "payoutsList.assignModal.create" })}
                </Button>
                <Button
                    size="sm"
                    color="primary"
                    onClick={() => handleCloseModal(formikProps.resetForm)}
                    round
                >
                    {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "invoicesList.table.title" })}
                actions={getActions()}
                withBackButton={false}
            />
            <Formik
                initialValues={userData}
                onSubmit={(values, { resetForm }) => { handleCreateInvoice(values, resetForm) }}
                validationSchema={getValidationSchema(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: "invoicesList.assignModal.title" })}
                        open={assignModalOpen}
                        onClose={() => setAssignModalOpen(false)}
                        actions={renderAssignActions(formikProps, true)}
                    >
                        <>
                            <InvoiceCard
                                data={formikProps.values}
                                type={true}
                                formikProps={formikProps}
                            />
                        </>
                    </Modal>
                )}
            </Formik>

            <Formik
                initialValues={userData}
                onSubmit={(values, { resetForm }) => { handleCreatePayout(values, resetForm) }}
                validationSchema={getValidationSchemaPayout(intl, maxCredits)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: "payoutsList.assignModal.title" })}
                        open={assignModalOpenPayout}
                        onClose={() => { setAssignModalOpenPayout(false) }}
                        actions={renderAssignActions(formikProps, false)}
                    >

                        <>
                            <InvoiceCard
                                data={formikProps.values}
                                type={false}
                                payoutCreate={payoutCreate}
                                invoiceCreate={invoiceCreate}
                                callbackMaxCredits={(newValue) => setMaxCredits(newValue)}
                                formikProps={formikProps}
                            />
                        </>

                    </Modal>
                )}
            </Formik>

            <IconCard
                icon={<CreditCardIcon />}
                title={(activeState === 0)
                    ? intl.formatMessage({ id: "invoicesList.title" })
                    : intl.formatMessage({ id: "payoutsList.title" })
                }
            >


                <NavPills
                    callback={(newValue) => setActiveState(newValue)}
                    color="info"
                    tabs={[
                        {
                            tabButton: intl.formatMessage({ id: "invoicesList.enabledToggle" }),
                            tabContent: (
                                <InvoiceTable
                                    data={dataInvoices}
                                    loadData={loadInvoices}
                                    defaultFilter={null}
                                    history={history}
                                    queryDataPath={['invoicesTable', 'items']}
                                    list="invoicesList"
                                    toggle={true}
                                />
                            ),
                        },
                        {
                            tabButton: intl.formatMessage({ id: "invoicesList.disabledToggle" }),
                            tabContent: (
                                <InvoiceTable
                                    data={dataPayouts}
                                    loadData={loadPayouts}
                                    defaultFilter={null}
                                    history={history}
                                    queryDataPath={['payoutsTable', 'items']}
                                    list="payoutsList"
                                    toggle={true}
                                />

                            ),
                        },

                    ]}
                    active={location.state ? Number(location.state) : 0}
                />
            </IconCard>
        </>
    );
};

export default withRouter(injectIntl(InvoicesListPage));