import React from 'react';
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import ChartistGraph from "react-chartist";

const IconCard = (props) => {
    const { children, title = '', chartData , color = 'primary', type} = props;



    return (
        <Card chart>
            <CardHeader color={color}>
            {chartData &&
                <ChartistGraph
                    className="ct-chart-white-colors"
                    data={chartData.data}
                    type={type}
                    options={chartData.options}
                    responsiveOptions={chartData.responsiveOptions}
                    listener={chartData.animation}
                />
            }
            </CardHeader>
            <CardBody>
                <h4>{title}</h4>
                {children}
            </CardBody>
        </Card>
    );
};

export default IconCard;