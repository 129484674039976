export const getAPIUrl = () => {
    const { host, protocol } = window.location;

    if (process.env.NODE_ENV === 'development')
        return 'https://api.test.echo.cultify.studio/api/admin'
    else
        return `${protocol}//api.${host}/api/admin`
}

export const getEchoDsAPIUrl = () => {
    const { host, protocol } = window.location;

    if (process.env.NODE_ENV === 'development')
        return 'https://api.test.echo.cultify.studio/api/admin'
    else
        return `${protocol}//api.${host}/api/admin`
}