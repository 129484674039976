import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import { ALL_INTERNAL_CAMPAIGNS, ALL_COMMERCIAL_CAMPAIGNS } from "queries/CampaignsQueries/allCampaigns"
import paths from "paths";
import PageHeader from "components-lib/PageHeader/PageHeader";
import IconCard from "components-lib/IconCard/IconCard";
import AddBoxIcon from '@material-ui/icons/AddBox';
import QueueIcon from '@material-ui/icons/Queue';
import CommercialCampaignTable from "./CommercialCampaignTable";
import InternalCampaignTable from "./InternalCampaignTable";
import CampaignTypeEnum from "common/enums/CampaignType";
import { commercialCampaignCreate, internalCampaignCreate } from 'configFiles/roles'

const CampaignListPage = (props) => {

    const { intl, history } = props

    const [campaignType, setCampaignType] = useState(null);
    const [queryVariables, setQueryVariables] = useState(null);


    useEffect(() => {
        if (history.location.pathname.indexOf(CampaignTypeEnum.INTERNAL) > -1) {
            setCampaignType("Internal");
            setQueryVariables({ type: "Internal" });
        }
        if (history.location.pathname.indexOf(CampaignTypeEnum.COMMERCIAL) > -1) {
            setCampaignType("Commercial");
            setQueryVariables({ type: "Commercial" });
        }
    }, [history.location])


    let campaignCreate = (campaignType === "Internal") ? internalCampaignCreate : commercialCampaignCreate




    const getActions = () => {
        if (campaignCreate)
            return [
                {
                    title: intl.formatMessage({ id: "campaigns.new" }),
                    onClick: () =>
                        campaignType === "Internal"
                            ? history.push(`/admin${generatePath(paths.campaigns.internalNew)}`)
                            : history.push(`/admin${generatePath(paths.campaigns.commercialNew)}`)

                },
            ];
    };

    return (
        <>
            <PageHeader
                title={
                    campaignType === "Internal"
                        ? intl.formatMessage({ id: "campaigns.internal.title" })
                        : intl.formatMessage({ id: "campaigns.commercial.title" })
                }
                actions={getActions()}
                withBackButton={false}
            />
            <IconCard
                icon={campaignType === "Commercial" ? <AddBoxIcon /> : <QueueIcon />}
                title={
                    campaignType === "Internal"
                        ? intl.formatMessage({ id: "campaigns.internal.header" })
                        : intl.formatMessage({ id: "campaigns.commercial.header" })
                }
            >
                {campaignType === "Commercial" ?
                    <CommercialCampaignTable
                        query={ALL_COMMERCIAL_CAMPAIGNS}
                        queryVariables={queryVariables}
                        history={history}
                    />
                    :
                    <InternalCampaignTable
                        query={ALL_INTERNAL_CAMPAIGNS}
                        queryVariables={queryVariables}
                        history={history}
                    />
                }
            </IconCard>


        </>
    )
}

export default injectIntl(withRouter(CampaignListPage))