import { useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import StoreIcon from '@material-ui/icons/Store';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import MultipleValuesField from "components-lib/MultipleValuesField/MultipleValuesField";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { pathOr } from "rambda";
import ALL_SEGMENTS from "queries/StoresQueries/allSegments";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { segmentList } from 'configFiles/roles'
import RestoreIcon from '@material-ui/icons/Restore';
import EditIcon from '@material-ui/icons/Edit';
import CustomInput from 'components/CustomInput/CustomInput';
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => styles(theme));

const BasicInfoCard = (props) => {
    const { intl, formikProps, storeUpdate, storeCreate, multipleUpdate } = props;
    const classes = useStyles();

    const { data: segmentData } = useQuery(ALL_SEGMENTS, { skip: (!storeUpdate) });
    const [availableSegments, setAvailableSegments] = useState([]);

    const [segmentChange, setSegmentChange] = useState(multipleUpdate ? false : true)
    const [areaChange, setAreaChange] = useState(multipleUpdate ? false : true)
    const [visitorsChange, setVisitorsChange] = useState(multipleUpdate ? false : true)

    useEffect(() => {
        const segments = pathOr([], ['segments', 'items'], segmentData).map(segment => {
            return {
                label: pathOr('', 'name', segment),
                value: pathOr('', 'id', segment)
            }
        })
        setAvailableSegments(segments);
    }, [segmentData])

    const renderContent = () => {
        if (!storeUpdate) {
            return (
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "storeDetail.storeCard.name" })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.name) ? formikProps.values.name : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "storeDetail.storeCard.category" })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.zones?.[0].preferences.segment?.id) ? formikProps.values.zones[0].preferences.segment?.name : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "storeDetail.storeCard.area" })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.zones?.[0].area) ? formikProps.values.zones[0].area : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "storeDetail.storeCard.visitRate" })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.zones?.[0].visitors) ? formikProps.values.zones[0].visitors : "-"}
                        </Box>
                    </Grid>
                </Grid>
            )
        }

        return (
            <>
                { (!multipleUpdate) &&
                    < FormikField
                        name="name"
                        labelText={intl.formatMessage({ id: "storeDetail.storeCard.name" })}
                        formikProps={formikProps}
                        highlightChange
                    />
                }
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        {!segmentChange ?
                            <MultipleValuesField
                                callback={(newValue) => setSegmentChange(newValue)}
                                labelText={intl.formatMessage({ id: "storeDetail.storeCard.category" })}
                            />

                            :
                            <FormikField
                                name={(storeCreate) ? "segmentId" : "zones.0.preferences.segment.id"}
                                labelText={intl.formatMessage({ id: "storeDetail.storeCard.category" })}
                                formikProps={formikProps}
                                type="select"
                                helperText={(multipleUpdate) &&
                                    <span
                                        className={classes.multiUpdate}
                                        style={{ color: "blue" }}
                                        onClick={() => { formikProps.setFieldValue("zones.0.preferences.segment.id", undefined); setSegmentChange(false) }}
                                    >
                                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                    </span>
                                }
                                options={availableSegments}
                            />
                        }
                    </Grid>
                </Grid>

                <Grid item sm={12}>
                    {!areaChange ?
                        <MultipleValuesField
                            callback={(newValue) => setAreaChange(newValue)}
                            labelText={intl.formatMessage({ id: "storeDetail.storeCard.area" })}
                        />
                        :
                        <FormikField
                            name={(storeCreate) ? "area" : "zones.0.area"}
                            labelText={intl.formatMessage({ id: "storeDetail.storeCard.area" })}
                            formikProps={formikProps}
                            helperText={(multipleUpdate) &&
                                <span
                                    className={classes.multiUpdate}
                                    style={{ color: "blue" }}
                                    onClick={() => { formikProps.setFieldValue("zones.0.area", undefined); setAreaChange(false) }}
                                >
                                    {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                </span>
                            }
                            highlightChange
                        />
                    }
                </Grid>

                <Grid item sm={12}>
                    {!visitorsChange ?
                        <MultipleValuesField
                            callback={(newValue) => setVisitorsChange(newValue)}
                            labelText={intl.formatMessage({ id: "storeDetail.storeCard.visitRate" })}
                        />
                        :
                        <FormikField
                            name={(storeCreate) ? "visitors" : "zones.0.visitors"}
                            labelText={intl.formatMessage({ id: "storeDetail.storeCard.visitRate" })}
                            formikProps={formikProps}
                            helperText={(multipleUpdate) &&
                                <span
                                    className={classes.multiUpdate}
                                    style={{ color: "blue" }}
                                    onClick={() => { formikProps.setFieldValue("zones.0.visitors", undefined); setVisitorsChange(false) }}
                                >
                                    {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                </span>
                            }
                            highlightChange
                        />
                    }
                </Grid>
            </>
        )
    }

    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: "storeDetail.title" })}
                icon={<StoreIcon />}>
                {renderContent()}
            </IconCard>
        </>
    );
};

export default withRouter(injectIntl(BasicInfoCard));
