import gql from 'graphql-tag';

const ALL_PAYOUTS = gql`
query PayoutsTable(
  $offset: Int, 
  $limit: Int, 
  $sort: [PayoutsTableSortInput!], 
  $filter: [PayoutsTableFilterInput]) {
    payoutsTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
        totalCount
        items {
            id
            bid
            description
            credit
            price
            company
            createdAt
            createdBy
        }
    }
}
`;

export default ALL_PAYOUTS;