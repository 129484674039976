import gql from 'graphql-tag';

const ALL_STREAMS = gql`
    query streamsTable($offset: Int, $limit: Int, $sort: [StreamsTableSortInput!], $filter: [StreamsTableFilterInput!]){
        streamsTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter){
            totalCount,
            items{
                id,
                bid,
                name,
                status,
                url,
                listenUrl
            }
        }
    }
`;

export default ALL_STREAMS;