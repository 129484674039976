import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeviceErrorCodesEnum from "common/enums/DeviceErrorCodesEnum";
import Modal from "components-lib/Modal/Modal";
import PageHeader from "components-lib/PageHeader/PageHeader";
import Button from "components/CustomButtons/Button.js";
import { pathOr } from "rambda";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import paths from 'paths';
import styles from "common/styles/widgets.js";
import StoreCard from './DetailCards/StoreCard';
import StatusCard from "./DetailCards/StatusCard";
import BasicInfoCard from "./DetailCards/BasicInfoCard";
import { useSnackbar } from 'notistack';
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import Loading from "components-lib/Loading/Loading";
import RESTART_DEVICE from "queries/DevicesQueries/restartDevice";
import DEVICE_REMOVE_ZONE from "queries/DevicesQueries/deviceRemoveZone";
import STORES_SELECT from "queries/StoresQueries/storesSelect";
import DEVICE_ASSIGN_ZONE from "queries/DevicesQueries/deviceAssignZone";
import DEVICE_DETAIL from "queries/DevicesQueries/deviceDetail";
import DEVICE_DISABLE from "queries/DevicesQueries/deviceDisable";
import DEVICE_ENABLE from "queries/DevicesQueries/deviceEnable";
import { deviceRestart, deviceAssign, deviceEn, deviceDis, deviceDetail, storeDetail, deviceIdentify, deviceTerminal } from 'configFiles/roles'
import DEVICE_IDENTIFY from 'queries/DevicesQueries/deviceIdentify';
import DEVICE_TERMINAL from 'queries/DevicesQueries/deviceTerminal';

const useStyles = makeStyles((theme) => styles(theme));

const DeviceDetailPage = (props) => {
    const { intl, history } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const deviceId = props.match.params.deviceId;
    const queryParams = { id: deviceId };

    const [restartDeviceMutation] = useMutation(RESTART_DEVICE);
    const [removeDeviceMutation] = useMutation(DEVICE_REMOVE_ZONE);
    const [deviceEnable] = useMutation(DEVICE_ASSIGN_ZONE);
    const [deactivateDeviceMutation] = useMutation(DEVICE_DISABLE);
    const [activateDeviceMutation] = useMutation(DEVICE_ENABLE);
    const { data: storesData } = useQuery(STORES_SELECT, { skip: !storeDetail });
    const { data: deviceTerminalData } = useQuery(DEVICE_TERMINAL, { variables: queryParams, skip: !deviceTerminal });
    const { loading, data, refetch } = useQuery(DEVICE_DETAIL, { variables: queryParams, skip: !deviceDetail });

    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [restartModalOpen, setRestartModalOpen] = useState(false);
    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const [activateModalOpen, setActivateModalOpen] = useState(false);
    const [storeId, setStoreId] = useState(false);
    const [availableStores, setAvailableStores] = useState(null)
    let hasStore = false;
    let isOnline = pathOr('', ['device', 'status'], data);

    useEffect(() => {
        let storesSelectItems = pathOr([], "stores.items", storesData).map(
            (store) => {
                return {
                    value: pathOr("", "id", store),
                    label: `${pathOr("", "id", store)} - ${pathOr(
                        "",
                        "name",
                        store
                    )}`,
                };
            }
        );
        setAvailableStores(storesSelectItems);
    }, [storesData]);

    if (!loading) {
        const storeName = pathOr('', ['device', 'zone', 'name'], data);
        if (storeName.length > 1) hasStore = true;
    }

    const deactivateDevice = () => {
        deactivateDeviceMutation({
            variables: {
                id: deviceId,
            }
        }).then(response => {
            refetch();
            enqueueSnackbar(intl.formatMessage({ id: 'deviceDetail.status.deviceDeactivationSuccessful' }), { variant: 'success', })
        }).catch(err => {
            console.log('[error]', err)
        });

        setDeactivateModalOpen(false);
    }

    const activateDevice = () => {
        activateDeviceMutation({
            variables: {
                id: deviceId,
            }
        }).then(response => {
            refetch();
            enqueueSnackbar(intl.formatMessage({ id: 'deviceDetail.status.deviceActivationSuccessful' }), {
                variant: 'success',
            })
        }).catch((err) => {
            console.log('[error]', err);
        });

        setActivateModalOpen(false);
    }

    const getActions = () => {
        let enabled = pathOr(false, ['device', 'isEnabled'], data)
        let help = [];
        //if (deviceRestart) {
            //if (isOnline === DeviceErrorCodesEnum.ONLINE.text)
                help.push(
                    {
                        title: intl.formatMessage({ id: "deviceDetail.status.restart" }),
                        onClick: () => setRestartModalOpen(true),
                    }
                )
        //}
        if (deviceAssign) {
            if (hasStore) {
                help.push(
                    {
                        title: intl.formatMessage({ id: "deviceList.removeDevice" }),
                        onClick: () => setRemoveModalOpen(true),
                    }
                )

                if (enabled) {
                    if (deviceEn)
                        help.push(
                            {
                                title: intl.formatMessage({ id: 'deviceDetail.status.deactivate' }),
                                onClick: () => setDeactivateModalOpen(true),
                            }
                        )
                } else {
                        if (deviceDis)
                            help.push(
                                {
                                    title: intl.formatMessage({ id: 'deviceDetail.status.activate' }),
                                    onClick: () => setActivateModalOpen(true),
                                }
                            )
                    }
            }
            else {
                help.push({
                    title: intl.formatMessage({ id: 'deviceDetail.player.assignPlayer.title' }),
                    onClick: () => setAddModalOpen(true),
                }
                )
            }

            if (isOnline === DeviceErrorCodesEnum.ONLINE.text)
                if (deviceRestart) {
                    help.push(
                        {
                            title: intl.formatMessage({ id: "deviceDetail.status.restart" }),
                            onClick: () => setRestartModalOpen(true),
                        }
                    )
                }
        }
        return help;
    };


    const [identifyDevice] = useMutation(DEVICE_IDENTIFY);

    const handleDeviceIdentiy = () => {
        identifyDevice({
            variables: {
                id: deviceId,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "deviceDetail.success.deviceIdentify" }), { variant: "success", });
        }).catch((err) => {
            console.log('[error]', err)
        });
    }

    const renderRestartModalButtons = () => {
        return (
            <>
                <Button
                    onClick={() => restartDevice()}
                    color="warning"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "deviceDetail.status.restart" })}
                </Button>
                <Button
                    onClick={() => setRestartModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "deviceList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    const renderRemoveModalButtons = () => {
        return (
            <>
                <Button
                    onClick={() => removeDevice()}
                    color="warning"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "deviceDetail.status.remove" })}
                </Button>
                <Button
                    onClick={() => setRemoveModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "deviceList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    const renderAddModalButtons = () => {
        return (
            <>
                <Button
                    disabled={!storeId}
                    onClick={() => addDevice()}
                    color="success"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'deviceDetail.player.assignPlayer.assign' })}
                </Button>
                <Button
                    onClick={() => setAddModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "deviceList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    const renderDeactivateModalButtons = () => {
        return (
            <>
                <Button
                    onClick={() => deactivateDevice()}
                    color="danger"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'deviceDetail.status.deactivate' })}
                </Button>
                <Button
                    onClick={() => setDeactivateModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "deviceList.assignModal.cancel" })}
                </Button>
            </>
        )
    }

    const renderActivateModalButtons = () => {
        return (
            <>
                <Button
                    onClick={() => activateDevice()}
                    color="success"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'deviceDetail.status.activate' })}
                </Button>
                <Button
                    onClick={() => setActivateModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "deviceList.assignModal.cancel" })}
                </Button>
            </>
        )
    }

    const restartDevice = () => {
        restartDeviceMutation({
            variables: {
                id: deviceId,
            },
        }).then((response) => {
            refetch();
            setRestartModalOpen(false);
            enqueueSnackbar(intl.formatMessage({ id: "deviceDetail.status.deviceRestartSuccessful" }), { variant: "success", });
        }).catch((err) => {
            console.log('[error]', err)
        });
    };

    const addDevice = () => {
        deviceEnable({
            variables: {
                id: deviceId,
                zoneId: storeId,
            },
        }).then((response) => {
            history.push(`/admin${generatePath(paths.devices.detail, { deviceId: deviceId })}`);
            enqueueSnackbar(intl.formatMessage({ id: "deviceDetail.player.assignPlayerSuccessful", }), { variant: "success", })
            refetch();
        }).catch((err) => {
        });
        setAddModalOpen(false);
    }

    const removeDevice = () => {
        removeDeviceMutation({
            variables: {
                id: deviceId,
            },
        }).then((response) => {
            history.push(`/admin${generatePath(paths.devices.list)}`);
            enqueueSnackbar(intl.formatMessage({ id: "deviceDetail.status.deviceRemoveSuccessful" }), { variant: "success", });
        }).catch((err) => {
        });
    };

    if (loading) return <Loading />;
    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "deviceDetail.title" })}
                actions={getActions()}
                subTitle={pathOr("", "device.bid", data)}
                handleBackAction={(e) => history.goBack()}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <BasicInfoCard
                        deviceBid={pathOr('', ['device', 'bid'], data)}
                        balenaId={pathOr('', ['device', 'balenaUuid'], data)}
                        deviceType={pathOr('', ['device', 'deviceType'], data)}
                        clientVersion={pathOr('', ['device', 'clientVersion'], data)}
                        identify={() => handleDeviceIdentiy()}
                        terminal={pathOr("", ["deviceTerminal", "url"], deviceTerminalData)}
                    />
                    {console.log()}
                    <StatusCard
                        deviceId={pathOr('', ['device', 'id'], data)}
                        deviceBid={pathOr('', ['device', 'bid'], data)}
                        zoneId={pathOr('', ['device', 'zone', 'id'], data)}
                        status={pathOr('', ['device', 'status'], data)}
                        lastHeartbeat={pathOr('', ['device', 'onlineAt'], data)}
                        isEnabled={pathOr(false, ['device', 'isEnabled'], data)}
                        refetchData={refetch}
                        player={pathOr('', ['device', 'player', 'status'], data)}
                        statusUpdatedAt={pathOr('', ['device', 'statusUpdatedAt'], data)}
                    />
                </Grid>

                {(hasStore && storeDetail) &&
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <StoreCard
                            storeDetail={storeDetail}
                            category={pathOr('', ['device', 'zone', 'store', 'segment'], data)}
                            name={pathOr('', ['device', 'zone', 'store', 'name'], data)}
                            area={pathOr('', ['device', 'zone', 'store', 'zones', [0], 'area'], data)}
                            visitors={pathOr('', ['device', 'zone', 'store', 'zones', [0], 'visitors'], data)}
                            deviceId={pathOr('', ['device', 'id'], data)}
                            storeId={pathOr('', ['device', 'zone', 'store', 'id'], data)}
                            storeAddress={pathOr('', ['device', 'zone', 'store', 'address'], data)}
                            storePostal={pathOr('', ['device', 'zone', 'store', 'postal'], data)}
                            storeCity={pathOr('', ['device', 'zone', 'store', 'city'], data)}
                        />
                    </Grid>
                }
            </Grid>
            <Modal
                title={intl.formatMessage({ id: "deviceDetail.status.removeModal.title" })}
                open={removeModalOpen}
                onClose={() => setRemoveModalOpen(false)}
                actions={renderRemoveModalButtons()}
            >
                {`${intl.formatMessage({ id: "deviceDetail.status.removeModal.text-1" })} ${deviceId} ${intl.formatMessage({ id: "deviceDetail.status.removeModal.text-2" })} ${pathOr("", 'device.zone.store.name', data)} ${pathOr("", 'device.zone.store.bid', data)} `}
            </Modal>
            <Modal
                title={intl.formatMessage({ id: 'deviceDetail.player.assignPlayer.title' })}
                open={addModalOpen}
                onClose={() => setAddModalOpen(false)}
                actions={renderAddModalButtons()}
            >
                <AutocompleteSelect
                    onChange={(item) => setStoreId(item.value)}
                    className={classes.selectStyle}
                    placeholder={intl.formatMessage({
                        id: "deviceList.assignModal.store",
                    })}
                    options={availableStores}
                />
            </Modal>
            <Modal
                title={intl.formatMessage({
                    id: "deviceDetail.status.restartModal.title"
                })}
                open={restartModalOpen}
                onClose={() => setRestartModalOpen(false)}
                actions={renderRestartModalButtons()}
            >
                {intl.formatMessage({
                    id: "deviceDetail.status.restartModal.text"
                })}
            </Modal>
            <Modal title={intl.formatMessage({ id: 'deviceDetail.status.deactivateModal.title' })} open={deactivateModalOpen} onClose={() => setDeactivateModalOpen(false)}
                actions={renderDeactivateModalButtons()}>
                {intl.formatMessage({ id: 'deviceDetail.status.deactivateModal.text' })}
            </Modal>
            <Modal title={intl.formatMessage({ id: 'deviceDetail.status.activateModal.title' })} open={activateModalOpen} onClose={() => setActivateModalOpen(false)}
                actions={renderActivateModalButtons()}>
                {intl.formatMessage({ id: 'deviceDetail.status.activateModal.text' })}
            </Modal>
        </>
    );
};

export default injectIntl(withRouter(DeviceDetailPage));
