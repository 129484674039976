import gql from 'graphql-tag';

export const COMMERCIAL_CAMPAIGN_REJECT = gql`
  mutation commercialCampaignReject($id: ID! $description: String) {
    commercialCampaignReject(id: $id, description: $description) {
      id
    }
  }
`;

export const INTERNAL_CAMPAIGN_REJECT = gql`
  mutation internalCampaignReject($id: ID! $description: String) {
    internalCampaignReject(id: $id, description: $description) {
      id
    }
  }
`;
