import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js"
import { pathOr } from "rambda";
import { internalCampaignDetail } from 'configFiles/roles'
import CampaignStatusEnum from "common/enums/CampaignStatusEnum";

const useStyles = makeStyles((theme) => styles(theme))

export const CampaignTable = (props) => {
    const classes = useStyles();

    const {
        queryVariables,
        history,
        query,
        intl,
        queryDataPath = ['internalCampaignsTable', 'items']
    } = props

    const getStatus = (status) => {
        if (status === CampaignStatusEnum.RUNNING)
            return <p style={{ color: "green" }}>{intl.formatMessage({ id: "campaigns.status.running" })}</p>
        else if (status === CampaignStatusEnum.AWAITING)
            return <p style={{ color: "orange" }}>{intl.formatMessage({ id: "campaigns.status.awaiting" })}</p>
        else if (status === CampaignStatusEnum.REJECTED)
            return <p style={{ color: "red" }}>{intl.formatMessage({ id: "campaigns.status.rejected" })}</p>
        else if (status === CampaignStatusEnum.FINISHED)
            return <p style={{ color: "gray" }}>{intl.formatMessage({ id: "campaigns.status.finished" })}</p>
        else if (status === CampaignStatusEnum.SCHEDULED)
            return <p style={{ color: "#7b1fa2" }}>{intl.formatMessage({ id: "campaigns.status.scheduled" })}</p>
        else if (status === CampaignStatusEnum.INACTIVE)
            return <p style={{ color: "#7b1fa2" }}>{intl.formatMessage({ id: "campaigns.status.inactive" })}</p>
        else
            return <p>{status}</p>
    }

    const getSpotType = (spot) => {
        if (spot === "Advertisement")
            return <p>{intl.formatMessage({ id: "campaigns.infoCard.Advertisement" })}</p>
        else
            return <p>{intl.formatMessage({ id: "campaigns.infoCard.Announcement" })}</p>
    }

    const handleCampaignDetail = (id) => {
        history.push(`/admin${generatePath(paths.campaigns.internalDetail, { campaignId: id })}`);
    }

    const getColumns = () => {
        const internalColumns = [
            {
                Header: "ID",
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.name" }),
                accessor: 'name',
                sortKey: 'name',
                filterKey: 'name',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.owner" }),
                accessor: 'owner',
                sortKey: 'owner',
                filterKey: 'owner',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.type" }),
                accessor: (rowData) => getSpotType(rowData.spotType),
                sortKey: 'spotType',
                filterKey: 'spotType',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: "Advertisement",
                        label: intl.formatMessage({ id: "campaigns.infoCard.Advertisement" }),
                    },
                    {
                        value: "Announcement",
                        label: intl.formatMessage({ id: "campaigns.infoCard.Announcement" }),
                    }
                ]
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.status" }),
                accessor: (rowData) => getStatus(rowData.status),
                sortKey: 'status',
                filterKey: 'status',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: CampaignStatusEnum.RUNNING,
                        label: intl.formatMessage({ id: "campaigns.status.running" }),
                    },
                    {
                        value: CampaignStatusEnum.AWAITING,
                        label: intl.formatMessage({ id: "campaigns.status.awaiting" }),
                    },
                    {
                        value: CampaignStatusEnum.SCHEDULED,
                        label: intl.formatMessage({ id: "campaigns.status.scheduled" }),
                    },
                    {
                        value: CampaignStatusEnum.FINISHED,
                        label: intl.formatMessage({ id: "campaigns.status.finished" }),
                    },

                    {
                        value: CampaignStatusEnum.REJECTED,
                        label: intl.formatMessage({ id: "campaigns.status.rejected" }),
                    },
                    {
                        value: CampaignStatusEnum.INACTIVE,
                        label: intl.formatMessage({ id: "campaigns.status.inactive" }),
                    },
                ]
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.start" }),
                accessor: (rowData) =>
                    new Date(pathOr(0, 'validFrom', rowData)).toLocaleString('sk'),
                sortKey: 'validFrom',
                filterKey: "validFrom",
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'date',
            },
            {
                Header: intl.formatMessage({ id: "campaigns.table.end" }),
                accessor: (rowData) =>
                    new Date(pathOr(0, 'validTo', rowData)).toLocaleString('sk'),
                sortKey: 'validTo',
                filterKey: "validTo",
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'date',
            },
            (internalCampaignDetail) ?
                {
                    Header: intl.formatMessage({ id: 'companyList.table.actions' }),
                    accessor: (rowData) =>
                        <Button
                            round
                            className={classes.tenantButtons}
                            size="sm"
                            color="info"
                            onClick={() => handleCampaignDetail(rowData.id)}
                        >
                            {intl.formatMessage({ id: 'campaigns.detail' })}
                        </Button>
                } : { Header: " " }
        ]
        return internalColumns

    }
    return (
        <>
            <QueryTable
                queryDataPath={queryDataPath}
                query={query}
                columns={getColumns()}
            />
        </>
    )
}

export default injectIntl(CampaignTable);