import StoreIcon from '@material-ui/icons/Store';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { pathOr } from "rambda";

const LocationsCard = (props) => {
    const {
        data,
        intl,
        internal,
        pathType
    } = props;

    let locations = pathOr([], [pathType, 'criteria', [0], 'locations'], data).map(item => ( <p>{item.name}</p>))
    let companies = pathOr([], [pathType, 'criteria', [0], 'companies'], data).map(item => (<p>{item.name}</p>))
    let segments = pathOr([], [pathType, 'criteria', [0], 'segments'], data).map(item => (<p>{item.name}</p>))
    let zones = pathOr([], [pathType, 'criteria', [0], 'zones'], data).map(item => (<p>{item.name}</p>))
    let locationsCount = pathOr('', [pathType, 'criteria', [0], 'match', 'locationsCount' ], data)
    let segmentsCount = pathOr('', [pathType, 'criteria', [0], 'match', 'segmentsCount' ], data)
    let companiesCount = pathOr('', [pathType, 'criteria', [0], 'match', 'companiesCount' ], data)
    let zonesCount = pathOr('', [pathType, 'criteria', [0], 'match', 'zonesCount' ], data)

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.targetingCard.title' })}
            icon={<StoreIcon />}
        >

            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>{intl.formatMessage({ id: 'campaigns.locationsCard.locations' })}</TableCell>
                            <TableCell>{intl.formatMessage({ id: 'campaigns.locationsCard.segments' })}</TableCell>
                            {!internal && <TableCell>{intl.formatMessage({ id: 'campaigns.locationsCard.companies' })}</TableCell>}
                            <TableCell>{intl.formatMessage({ id: 'campaigns.locationsCard.stores' })}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell><b>{intl.formatMessage({ id: 'campaigns.locationsCard.table.name' })}</b></TableCell>
                            <TableCell>{(locations.length > 0) ? locations : intl.formatMessage({ id: 'campaigns.locationsCard.all' })}</TableCell>
                            <TableCell>{(segments.length > 0) ? segments : intl.formatMessage({ id: 'campaigns.locationsCard.all' })}</TableCell>
                            {!internal && <TableCell>{(companies.length > 0) ? companies : intl.formatMessage({ id: 'campaigns.locationsCard.all' })}</TableCell>}
                            <TableCell>{(zones.length > 0) ? zones : intl.formatMessage({ id: 'campaigns.locationsCard.all' })}</TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell><b>{intl.formatMessage({ id: 'campaigns.locationsCard.table.count' })}</b></TableCell>
                            <TableCell>{(locationsCount) ? locationsCount : "0"}</TableCell>
                            <TableCell>{(segmentsCount) ? segmentsCount : "0"}</TableCell>
                            {!internal && <TableCell>{(companiesCount) ? companiesCount : "0"}</TableCell>}
                            <TableCell>{(zonesCount) ? zonesCount : "0"}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </IconCard>
    </>
};

export default injectIntl(LocationsCard);
