import gql from 'graphql-tag';

export const COMMERCIAL_CAMPAIGN_APPROVE = gql`
  mutation commercialCampaignApprove($id: ID!$description: String) {
    commercialCampaignApprove(id: $id, description: $description) {
    id
  }
  }
`;

export const INTERNAL_CAMPAIGN_APPROVE = gql`
  mutation internalCampaignApprove($id: ID!$description: String) {
    internalCampaignApprove(id: $id, description: $description) {
    id
  }
  }
`;