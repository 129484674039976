import gql from 'graphql-tag';

const STORES_BY_COMPANY = (companyId) => gql`
query storesTableByCompany(
  $offset: Int, 
  $limit: Int, 
  $sort: [StoresTableSortInput!], 
  $filter: [StoresTableFilterInput]){
    storesTableByCompany(
      companyId: ${companyId}
      offset:$offset, 
      limit: $limit, 
      sort: $sort, 
      filter: $filter){
      totalCount,
      items{
        id
        bid    
        name
        segment
        address
        deviceStatus
        playerStatus 
      }
    }
  }
`;

export default STORES_BY_COMPANY;