import gql from 'graphql-tag';

const PAYOUT_DETAIL = gql`
query payout($id: ID!){
	payout(id:$id){
  	id,
    bid,
    createdAt,
   	description
    credit
    price
    createdAt
    description
    identifier
    createdBy{
    	id
      fullName
    }
    company{
    	id
      bid
      name
      address
      contactPhone
      contactEmail
      postal
      city
      country
      contactFirstName
      contactLastName
    }       
  }
}
`;

export default PAYOUT_DETAIL;