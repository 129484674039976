//import { dangerColor } from "assets/jss/material-dashboard-pro-react.js";
const devicesListPageStyles = (theme) => {

  return {
    iconAlign: {
      verticalAlign: "text-bottom"
    },
    floatRight: {
      float: "right"
    },
    tenantButtons: {
      marginRight: theme.spacing(3)
    },
    marginBottom: {
      marginBottom: theme.spacing(6)
    },
    tenantCardButtons: {
      position: "absolute",
      width: "100%",
      bottom: "0",
    },
    tenantCardButtonsMargin: {
      float: "right",
      marginRight: theme.spacing(2)
    },
    selectStyle: {
      margin: "0 10px 20px 10px",
      width: "300px"
    },
    warning: {
      color: "#EE9600"
    },
    warningIcon: {
      fontSize: '2px',
      verticalAlign: "middle",
      lineHeight: "normal",
    },
    pointer: {
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: '#fce4ec',
      }
    },
    statusCircle: {
      borderRadius: '50px',
      width: '15px',
      height: '15px',
    },
    multiUpdate: {
      cursor: 'pointer',
      padding: '5x',
      "&:hover": {
        backgroundColor: '#fce4ec',
        borderRadius: "5px"
      }
    },
    copyToClipboard: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: '#fce4ec',
      }
    }
  }
}
export default devicesListPageStyles
