import gql from 'graphql-tag';

const STORES_UPDATE = gql`
mutation storeUpdateBulk($ids: [ID!]!, $input: StoreUpdateInput!){
    storeUpdateBulk(ids: $ids, input: $input){
        name
        address
        postal
        city
        country
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
    }
}
`;

export default STORES_UPDATE;