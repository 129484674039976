import { Grid } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { defaultColors, colors } from "common/styles/configLayout.js";
const useStyles = makeStyles((theme) => styles(theme));

const AppSlider = (props) => {
  const {
    sliderName = 'Slider',
    sliderChange,
    minValue = 0,
    maxValue = 100,
    defaultValue = 50,
    marks = [
      {
        value: minValue,
        label: <p style={{ paddingTop: "5px" }}>0</p>
      },
      {
        value: defaultValue,
        label: <p style={{ paddingTop: "5px" }}>50</p>
      },
      {
        value: maxValue - 1,
        label: <p style={{ paddingTop: "5px" }}>100</p>
      },
    ],
    valueLabel,
    icon = false
  } = props;

  const classes = useStyles();

  const [value, setValue] = useState();

  const muiTheme = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          color: defaultColors.one,
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: colors.main,
          border: '2px solid black',
          marginTop: -8,
          marginLeft: -12,
          '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
          },
        },
        active: {},
        valueLabel: {
          left: 'calc(-50% + 4px)',
          color: colors.two,
        },
        track: {
          height: 6,
          borderRadius: 4,
        },
        rail: {
          height: 6,
          borderRadius: 4,
        },
      }
    }
  });

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < minValue) {
      setValue(minValue);
    } else if (value > maxValue) {
      setValue(maxValue);
    }
  };

  const getChange = (change) => {
    return (
      <>
        <Grid item sm={12}>
          <p>{sliderName}</p>
          <Grid container
            spacing={2}
            error={props.error}
            style={props.style}
          >
            {icon &&
              <Grid item>
                {icon}
              </Grid>
            }
            <Grid item xs style={{ paddingTop: "0.2em" }}>
              <ThemeProvider theme={muiTheme}>
                <Slider
                  className={classes.slider}
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={defaultValue}
                  step={1}
                  min={minValue}
                  max={maxValue}
                  value={(typeof value === "number" ? value : 0) || (typeof change === "number" ? change : 0)}
                  valueLabelFormat={valueLabel}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  marks={marks}
                //valueLabelFormat={valueLabelFormat}
                />
              </ThemeProvider>
            </Grid>
            <Grid item style={{ paddingLeft: "2em" }}>
              <Input
                //style={(value !== originalValue ) ? { backgroundColor: '#FFF8CC' } : { backgroundColor: '' }}
                defaultValue={value}
                className={classes.input}
                value={value || change}
                margin="dense"
                onChange={handleInputChange}
                onBlur={handleBlur()}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 100,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderStatus = () => {
    return (
      <>
        {getChange(sliderChange)}
      </>
    )
  }

  return (
    renderStatus()
  );
};

export default injectIntl(AppSlider);
