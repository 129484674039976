import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import { pathOr } from "rambda";
import { default as React, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";

import { getMultipleValidationSchema } from "./validationSchema";
import { Formik } from "formik";
import STORES_UPDATE from 'queries/StoresQueries/storesUpdate';
import Loading from "components-lib/Loading/Loading";
import { storeUpdate, storeDel, deviceAssign, companyDetail } from 'configFiles/roles'
import moment from 'moment';

import BasicInfoCard from "./StoreCards/BasicInfoCard";
import StatusCard from "./StoreCards/StatusCard";
import MediaCard from "./StoreCards/MediaCard";
import TimePlanCard from "./StoreCards/TimePlanCard"
import ButtonsCard from "./StoreCards/ButtonsCard"

import MaintainerCard from "./StoreCards/MaintainerCard";
import RestoreIcon from '@material-ui/icons/Restore';


const useStyles = makeStyles((theme) => styles(theme));

const StoreDetailPage = (props) => {

  const storeId = (props.button[0]) ? props.button[props.button.length - 1] : 20;
  const { intl, history, storeData, storeLoading, refetchData, refetchStores, callbackOpen, callbackTableOpen, handleDrawerOpen } = props;
  const { enqueueSnackbar } = useSnackbar();
  let userData = ({});

  const [formikStoreData, setFormikStoreData] = useState({});
  const [forbiddenTopics, setForbiddenTopics] = useState([]);
  const [inactiveTimes, setInactiveTimes] = useState([])
  const [buttons, setButtons] = useState([])
  const [times, setTimes] = useState([]);
  const [haveDevice, setHaveDevice] = useState(false)
  const [updateValues, setUpdateValues] = useState();
  const [updateModal, setUpdateModal] = useState(false)

  const [updateStores] = useMutation(STORES_UPDATE);

  useEffect(() => {
    setFormikStoreData(pathOr({}, ['stores', 'items'], storeData));
  }, [storeData]);

  const handleUpdateStore = () => {
    let parseForbiddenTopicsIds = forbiddenTopics.map(topic => topic.value);
    let parseButtons = buttons?.map(item => ({
      button: item.button,
      mediaId: item.value
    }))
    let parseInTimes = []
    for (let i = 0; i < inactiveTimes.length; i++) {
      if (inactiveTimes[i]?.from !== undefined || inactiveTimes[i]?.to !== undefined)
        parseInTimes.push({
          from: inactiveTimes[i]?.from,
          to: inactiveTimes[i]?.to
        });
    }

    const result = { ...updateValues };
    delete result.id;
    delete result.bid;
    delete result.segment;
    delete result.zones
    delete result.company;
    delete result.__typename;

    let preferences = { ...updateValues?.zones?.[0]?.preferences }
    delete preferences.segment
    delete preferences.beginJingleMedia
    delete preferences.endJingleMedia
    delete preferences.musicStream
    delete preferences.musicPlaybackOrder

    let helpArray = {
      ...result,
      area: (updateValues?.zones?.[0]?.area) ? Number(updateValues?.zones?.[0]?.area) : undefined,
      visitors: (updateValues?.zones?.[0]?.visitors) ? Number(updateValues?.zones?.[0]?.visitors) : undefined,
      preferences: {
        ...preferences,
        segmentId: (updateValues?.zones?.[0]?.preferences?.segment?.id) ? Number(updateValues?.zones?.[0]?.preferences?.segment?.id) : undefined,
        beginJingleMediaId: (updateValues?.zones?.[0]?.preferences?.beginJingleMedia?.id) ? Number(updateValues?.zones?.[0]?.preferences?.beginJingleMedia?.id) : undefined,
        endJingleMediaId: (updateValues?.zones?.[0]?.preferences?.endJingleMedia?.id) ? Number(updateValues?.zones?.[0]?.preferences?.endJingleMedia?.id) : undefined,
        musicPlaybackOrder: (!(updateValues?.zones?.[0]?.preferences?.musicPlaybackOrder)) ? undefined : ((updateValues?.zones?.[0]?.preferences?.musicPlaybackOrder) === true) ? "Random" : "Normal",
        musicStreamId: (updateValues?.zones?.[0]?.preferences?.musicStream?.id) ? Number(updateValues?.zones?.[0]?.preferences?.musicStream?.id) : undefined,
        activeTimes: (!times || times?.length === 0) ? undefined : times,
        buttons: (!parseButtons || parseButtons?.length === 0) ? undefined : parseButtons,
        forbiddenTopicIds: (!parseForbiddenTopicsIds || parseForbiddenTopicsIds?.length === 0) ? undefined : parseForbiddenTopicsIds,
        inactiveTimes: (!parseInTimes || parseInTimes?.length === 0) ? undefined : parseInTimes,
      }
    }

    updateStores({
      variables: {
        ids: props.button,
        input: helpArray,
      }
    }).then((response) => {
      enqueueSnackbar(<span style={{ zIndex: "tooltip" }}> {intl.formatMessage({ id: "storeDetail.updateStores.successful", })}</span>, { variant: "success" });
      refetchData();
      refetchStores();
    }).catch((err) => {
      console.log(err);
    });
    setUpdateModal(false);
    callbackOpen(false)
    callbackTableOpen(true);
  };

  const goToConfirmation = (values, resetForm) => {
    setUpdateValues(values);
    setUpdateModal(true);
    callbackTableOpen(false);
  };

  const renderConfirmActions = () => {
    return (
      <>
        <Button
          onClick={() => { handleUpdateStore() }}
          color="success"
          round
          size="sm"
        >
          {intl.formatMessage({ id: 'storeList.multiUpdate.button.confirm' })}
        </Button>
        <Button
          onClick={() => { setUpdateModal(false); callbackTableOpen(true) }}
          color="primary"
          round
          size="sm"
        >
          {intl.formatMessage({ id: 'storeList.multiUpdate.button.back' })}
        </Button>
      </>
    );
  };



  if (storeLoading) return <Loading />
  return (
    <>
      <Formik
        onSubmit={(values, { resetForm }) => { goToConfirmation(values, resetForm) }}
        enableReinitialize
        initialValues={userData}
        validationSchema={getMultipleValidationSchema(intl)}
      >
        {(formikProps) => (
          <>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <BasicInfoCard
                  data={formikStoreData}
                  formikProps={formikProps}
                  storeData={storeData}
                  storeUpdate={storeUpdate}
                  multipleUpdate={true}
                />
                <TimePlanCard
                  times={times}
                  callbackTimes={(newValue) => setTimes(newValue)}
                  data={formikProps.values}
                  formikProps={formikProps}
                  storeData={storeData}
                  inactiveTimes={inactiveTimes}
                  callbackInactiveTimes={(newValue) => setInactiveTimes(newValue)}
                  storeId={storeId}
                  storeUpdate={storeUpdate}
                  multipleUpdate={true}
                />

                <ButtonsCard
                  companyId={1}
                  storeUpdate={storeUpdate}
                  numberOfButtons={10}
                  callbackButtons={(newValue) => setButtons(newValue)}
                  buttons={buttons}
                  data={formikStoreData}
                  multipleUpdate={true}
                  formikProps={formikProps}
                />

                <StatusCard
                  deviceId={pathOr('', ['store', 'zones', [0], 'device', 'id'], storeData)}
                  deviceBid={pathOr('', ['store', 'zones', [0], 'device', 'bid'], storeData)}
                  zoneId={pathOr('', ['store', 'zones', [0], 'id'], storeData)}
                  status={pathOr('', ['store', 'zones', [0], 'device', 'status'], storeData)}
                  lastHeartbeat={pathOr('', ['store', 'zones', [0], 'device', 'onlineAt'], storeData)}
                  player={pathOr('', ['store', 'zones', [0], 'device', 'player', 'status'], storeData)}
                  refetchData={refetchData}
                  data={formikStoreData}
                  isAssigned={(pathOr('', ['store', 'zones', [0], 'device'], storeData))}
                  formikProps={formikProps}
                  callbackHaveDevice={(newValue) => setHaveDevice(newValue)}
                  storeUpdate={storeUpdate}
                  multipleUpdate={true}
                />
                {//(haveDevice) &&
                  <MediaCard
                    data={formikStoreData}
                    formikProps={formikProps}
                    storeData={storeData}
                    forbidden={forbiddenTopics}
                    callbackForbidden={(newValue) => { setForbiddenTopics(newValue) }}
                    companyId={pathOr('', ['store', 'company', 'id'], storeData)}
                    storeUpdate={storeUpdate}
                    multipleUpdate={true}
                  />
                }
                <MaintainerCard
                  data={formikStoreData}
                  formikProps={formikProps}
                  storeData={storeData}
                  storeUpdate={storeUpdate}
                  multipleUpdate={true}
                />
              </Grid>

            </Grid>
            <div style={{ zIndex: 999, position: "fixed", bottom: "20px", right: "20px" }} >
              <Button
                round
                color="primary"
                onClick={(e) => { formikProps.handleSubmit() }}
              >
                {intl.formatMessage({ id: 'storeList.multiUpdate.button.save' })}
              </Button>
              <Button
                round
                color="danger"
                onClick={() => {
                  formikProps.resetForm();
                  callbackOpen(false);
                  //handleDrawerOpen(true)
                }}
              >
                {intl.formatMessage({ id: 'storeList.multiUpdate.button.reset' })}&nbsp;&nbsp; <RestoreIcon />
              </Button>
              {/*<Button onClick={() => { formikProps.resetForm(); callbackOpen(false) }}>
                {intl.formatMessage({ id: 'storeList.multiUpdate.button.back' })}
              </Button>*/}
            </div>
          </>
        )}
      </Formik>

      <Modal
        title={intl.formatMessage({ id: 'storeList.multiUpdate.modal.title' })}
        open={updateModal}
        onClose={() => setUpdateModal(false)}
        actions={renderConfirmActions()}
      >
        {intl.formatMessage({ id: 'storeList.multiUpdate.modal.body' })}
      </Modal>
    </>
  );
};

export default injectIntl(withRouter(StoreDetailPage));
