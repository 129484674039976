import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import { default as React, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import { Grid } from "@material-ui/core";
import { colors } from "common/styles/configLayout.js";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import STORE_DETAIL from "queries/StoresQueries/storeDetail";
import { useQuery } from "@apollo/react-hooks";
import { pathOr } from "rambda";
import Loading from "components-lib/Loading/Loading";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import { COMMERCIAL_CAMPAIGN_DETAIL, INTERNAL_CAMPAIGN_DETAIL } from "queries/CampaignsQueries/campaignDetail";

const useStyles = makeStyles((theme) => styles(theme));

const days = [
    { day: "Monday" },
    { day: "Tuesday" },
    { day: "Wednesday" },
    { day: "Thursday" },
    { day: "Friday" },
    { day: "Saturday" },
    { day: "Sunday" }
]

const ScheduleCard = (props) => {

    const {
        intl,
        update,
        formikProps,
        callbackTimes,
        helperText,
        storeId,
        campaignId,
        multipleUpdate,
        withoutOpenInfo,
        readOnly,
        type,
        tms
    } = props;

    const { data: storeData, loading } = useQuery(STORE_DETAIL, { variables: { id: storeId }, skip: !storeId });
    let query = (type=== "Internal") ? INTERNAL_CAMPAIGN_DETAIL : COMMERCIAL_CAMPAIGN_DETAIL
    let pathType = (type=== "Internal") ? 'internalCampaign' : 'commercialCampaign'

    const { data: campaignData, loading: campaignLoading, refetch: refetchData } = useQuery(query, {
        variables: {
            id: campaignId
        }, skip: !campaignId
    });
    const helpTimes = (storeData)
        ? formikProps?.values?.zones?.[0]?.preferences?.activeTimes
        : (type=== "Internal")
            ? formikProps?.values?.internalCampaign?.activeTimes
            : formikProps?.values?.commercialCampaign?.activeTimes

    const classes = useStyles();

    const [rows, setRows] = useState([])

    const [times, setTimes] = useState((!tms)
        ? (storeData)
            ? pathOr([], ['store', 'zones', [0], 'preferences', 'activeTimes'], storeData)
            : (pathOr([], [pathType, 'activeTimes'], campaignData))
        : tms)

    useEffect(() => {
        if (!multipleUpdate)
            setTimes((!tms)
                ? (storeData)
                    ? pathOr([], ['store', 'zones', [0], 'preferences', 'activeTimes'], storeData)
                    : (pathOr([], [pathType, 'activeTimes'], campaignData))
                : tms)
    }, [storeData, campaignData, tms]);

    const handleCheckIsOpen = (day) => {
        let help = null
        if (times?.length > 0)
            times.filter(filt => filt.weekDay.includes(day)).map(item => (
                help = true
            ))

        if (help === true) return true
        else return false
    }

    const handleGiveFrom = (day) => {
        let help = null
        if (times?.length > 0)
            times.filter(filt => filt.weekDay.includes(day)).map(item => (
                help = item.from
            ))
        return help
    }

    const handleGiveTo = (day) => {
        let help = null
        if (times?.length > 0)
            times.filter(filt => filt.weekDay.includes(day)).map(item => (
                help = item.to
            ))
        return help
    }
    const handleCheckIsChanged = (day) => {
        let originalValue = { weekDay: day, from: null, to: null }
        let currentValue = { weekDay: day, from: null, to: null }
        if (helpTimes?.length > 0)
            (helpTimes).filter(filt => filt.weekDay.includes(day)).map(item => (
                originalValue = item
            ))

        if (times?.length > 0)
            times.filter(filt => filt.weekDay.includes(day)).map(item => (
                currentValue = item
            ))

        let inputStyles = ((originalValue?.from?.toString().substring(0, 5) !== currentValue?.from?.toString().substring(0, 5))
            || originalValue?.to?.toString().substring(0, 5) !== currentValue?.to?.toString().substring(0, 5))
            ? { backgroundColor: '#FFF8CC' }
            : {};

        return (inputStyles)
    }

    const handleTimeChange = (day, from, to, checked) => {
        let helpHours = times
        let helpNumber = 1

        helpHours.map((item, index) => {
            if (checked === true) {
                if (item.weekDay === day) {
                    delete helpHours[index]
                }
                helpNumber = 1
            }
            else {
                if (item.weekDay === day) {
                    delete helpHours[index]
                    helpNumber = 0
                }
            }

        })
        if (helpNumber === 1) {
            helpHours.push({
                weekDay: day,
                from: from,
                to: to
            })
        }
        let j = 0
        let arr = []
        for (let i = 0; i < helpHours.length; i++) {
            if (helpHours[i]) {
                arr[j] = helpHours[i]
                j++
            }
        }
        handleSetTimes(arr)
    }

    const handleSetTimes = (arr) => {
        callbackTimes(arr)
    }

    const renderContent = () => {
        if (!update || readOnly) {
            return (
                <>
                    {days?.map((item, index) => (
                        <Grid container spacing={2}>
                            {handleCheckIsOpen(item.day) ?
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: `weekDay.${item.day}` })}{(!withoutOpenInfo) && <span style={{ color: "green" }}>({intl.formatMessage({ id: 'storeDetail.openingHours.open' })})</span>}
                                    <Box className={classes.floatRight}>
                                        {handleGiveFrom(item.day)} - {handleGiveTo(item.day)}
                                    </Box>
                                </Grid>
                                :
                                <Grid item sm={12}>
                                    {intl.formatMessage({ id: `weekDay.${item.day}` })} {(!withoutOpenInfo) && <span style={{ color: "red" }}>({intl.formatMessage({ id: 'storeDetail.openingHours.closed' })})</span>}
                                    <Box className={classes.floatRight}>-</Box>
                                </Grid>
                            }
                        </Grid >
                    ))}
                </>
            )
        }
        return (
            <>
                {days?.map((item, index) => (
                    <Grid item
                        style={handleCheckIsChanged(item.day)}
                        key={index}
                        sm={12}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: colors.main }}
                                    checked={handleCheckIsOpen(item.day)}
                                    onClick={(e) => handleTimeChange(item.day, "00:00", "23:59", e.target.checked)}
                                />
                            }
                            classes={{ label: classes.label }}
                            label={props.day}
                        />
                        {handleCheckIsOpen(item.day) ?
                            <>
                                {intl.formatMessage({ id: `weekDay.${item.day}` })} {(!withoutOpenInfo) && <span style={{ color: "green" }}>({intl.formatMessage({ id: 'storeDetail.openingHours.open' })})</span>}
                                <form noValidate>
                                    <label>{intl.formatMessage({ id: "commissionsList.table.pickerStart" })} </label>
                                    <TextField
                                        id="time"
                                        type="time"
                                        value={handleGiveFrom(item.day)}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300,
                                        }}
                                        onChange={(e) => handleTimeChange(item.day, e.target.value, handleGiveTo(item.day), true)}
                                    />
                                    <label>   {intl.formatMessage({ id: "commissionsList.table.pickerEnd" })} </label>
                                    <TextField
                                        id="time"
                                        type="time"
                                        className={classes.textField}
                                        value={handleGiveTo(item.day)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={(e) => handleTimeChange(item.day, handleGiveFrom(item.day), e.target.value, true)}
                                    />

                                </form>
                            </>
                            : <>{intl.formatMessage({ id: `weekDay.${item.day}` })} {(!withoutOpenInfo) && <span style={{ color: "red" }}>({intl.formatMessage({ id: 'storeDetail.openingHours.closed' })})</span>}</>
                        }
                    </Grid >
                ))}
                <Grid item sm={12}>
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                    <hr style={{ marginTop: "1px", marginBottom: "0px" }} />
                </Grid>
            </>
        )
    }

    if (loading) return <Loading />;
    return (<>{renderContent()}</>)
};

export default injectIntl(ScheduleCard);
//769