import * as Yup from 'yup';

export const getValidationSchema = (intl) =>
    Yup.object().shape({
        creditValue: Yup.number()
            .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        defaultSpotPlaybackPrice: Yup.number()
            .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
        commissionRate: Yup.number()
            .min(0, intl.formatMessage({ id: 'validation.number.min' }, [0]))
            .max(100, intl.formatMessage({ id: 'validation.number.max' }, [100]))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable(),
    });

export default getValidationSchema;