import Api from '../api/Api';
;
export default class Zone extends Api {
    async get(value) {
        const response = await this.call(`
            query CampaignCrtieriaOptions($input: CampaignCriteriaOptionsInput!) {
                campaignCriteriaOptions(input: $input) {
                    zones {
                        id
                        name
                        groups
                    }
                }
            }
        `, {
            input: {
                campaign: this.campaign,
                criteria: value
            }
        });
        if (!Array.isArray(response.campaignCriteriaOptions.zones)) {
            throw new Error('Response data does not contain zones key');
        }
        const options = response.campaignCriteriaOptions.zones;
        const groups = options.flatMap(z => z.groups).filter((v, i, s) => s.indexOf(v) === i).map((v, i) => ({ id: (i + 1).toString(), name: v }));
        const nodes = [];
        for (const group of groups) {
            nodes.push({
                id: `_${group.id}`,
                name: group.name,
                children: options
                    .filter(zone => zone.groups.includes(group.name))
                    .map(zone => ({
                    id: zone.id,
                    name: zone.name,
                    children: []
                }))
            });
        }
        return this.prepareTree(nodes);
    }
}
