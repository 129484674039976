import * as Yup from 'yup';
import { phoneRegex } from "helpers/helpers";

export const getNewValidationSchema = (intl) =>
    Yup.object().shape({
        contactFirstName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactLastName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactEmail: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),

        area: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' }, [0])))
            .typeError(intl.formatMessage({ id: 'validation.number' })),
        companyId: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        segmentId: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        area: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' }, [0])))
            .typeError(intl.formatMessage({ id: 'validation.number' })),
        visitors: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' }, [0])))
            .typeError(intl.formatMessage({ id: 'validation.number' })),
        postal: Yup.string()
            .matches(/^[0-9]+$/, intl.formatMessage({ id: 'validation.number' }))
            .min(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            .max(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            .nullable(),
        contactPhone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .matches(/^[+,0-9]+$/, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
    });

export const getDetailValidationSchema = (intl) =>
    Yup.object().shape({
        contactFirstName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactLastName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactEmail: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactPhone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .matches(/^[+,0-9]+$/, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
        postal: Yup.string()
            .matches(/^[0-9]+$/, intl.formatMessage({ id: 'validation.number' }))
            .min(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            .max(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            .nullable(),
    });

export const getMultipleValidationSchema = (intl) =>
    Yup.object().shape({
        contactEmail: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .nullable(),
        contactPhone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .matches(/^[+,0-9]+$/, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
        postal: Yup.string()
            .matches(/^[0-9]+$/, intl.formatMessage({ id: 'validation.number' }))
            .min(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            .max(5, intl.formatMessage({ id: 'validation.string.exact' }, [5]))
            .nullable(),
    });
