import { gql } from "apollo-boost";

export const OVERVIEW = gql`
query Overview {
    overview {
        companies {
        totalCount
      }
      stores {
        totalCount
        noPlayerCount
      }
      users {
        totalCount
        activatedCount
        unactivatedCount
        blockedCount
      }
      devices {
        totalCount
        assignedCount
        unassignedCount
        offlineCount
      }
      streams {
        totalCount
        mostListened {
          id
          name
        }
      }
      media {
        totalCount
        awaitingApprovalCount
      }
      invoices {
        totalCount
        totalCredit
        totalPrice
      }
      payouts {
        totalCount
        totalCredit
        totalPrice
      }
      internalCampaigns {
        runningCount
        awaitingApprovalCount
      }
      commercialCampaigns {
        runningCount
        awaitingApprovalCount
      }
      commissions {
        period
        credit
        price
      }
    }
  }
`;