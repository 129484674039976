import * as Yup from 'yup';
import { phoneRegex } from "../../helpers/helpers";

export const getValidationSchemaDetail = (intl) =>
    Yup.object().shape({
        contactFirstName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactLastName: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactEmail: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        contactPhone: Yup.string()
            .matches(phoneRegex, intl.formatMessage({ id: 'validation.phoneNumber' }))
            .nullable(),
    });

export default getValidationSchemaDetail;