import React from 'react';
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import PersonIcon from '@material-ui/icons/Person';
import IconCard from "components-lib/IconCard/IconCard";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid,
  Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { defaultColors } from "common/styles/configLayout.js";
import moment from "moment";
import EuroIcon from '@material-ui/icons/Euro';
import { commissionList } from 'configFiles/roles'
import styles from "common/styles/widgetStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import Box from "@material-ui/core/Box";
import { generatePath } from "react-router-dom";
import paths from "paths";

const useStyles = makeStyles((theme) => styles(theme))

const UsersCard = (props) => {
  const {
    commissionsData,
    actualMonth,
    history,
    firstMonth,
    secondMonth,
    thirdMonth,
    intl
  } = props;
  const classes = useStyles();


  const getMonth = (month) => {
    let helpMonth = month;
    if (month === 0) helpMonth = 12
    if (month === -1) helpMonth = 11
    if (month === -2) helpMonth = 10
    if (month === -3) helpMonth = 9
    if (month === -4) helpMonth = 8

    switch (helpMonth) {
      case 1: return intl.formatMessage({ id: 'commissionsList.month.january' });
      case 2: return intl.formatMessage({ id: 'commissionsList.month.february' });
      case 3: return intl.formatMessage({ id: 'commissionsList.month.march' });
      case 4: return intl.formatMessage({ id: 'commissionsList.month.april' });
      case 5: return intl.formatMessage({ id: 'commissionsList.month.may' });
      case 6: return intl.formatMessage({ id: 'commissionsList.month.june' });
      case 7: return intl.formatMessage({ id: 'commissionsList.month.july' });
      case 8: return intl.formatMessage({ id: 'commissionsList.month.august' });
      case 9: return intl.formatMessage({ id: 'commissionsList.month.september' });
      case 10: return intl.formatMessage({ id: 'commissionsList.month.october' });
      case 11: return intl.formatMessage({ id: 'commissionsList.month.november' });
      case 12: return intl.formatMessage({ id: 'commissionsList.month.december' });
      default: return helpMonth
    }
  }

  let totalCount = commissionsData?.[0]?.credit + commissionsData?.[1]?.credit +
    commissionsData?.[2]?.credit + commissionsData?.[3]?.credit + commissionsData?.[4]?.credit
  let data = [
    {
      name: getMonth(actualMonth),
      registred: commissionsData?.[4]?.credit
    },
    {
      name: getMonth(actualMonth - 1),
      registred: commissionsData?.[3]?.credit
    },
    {
      name: getMonth(actualMonth - 2),
      registred: commissionsData?.[2]?.credit
    },
    {
      name: getMonth(actualMonth - 3),
      registred: commissionsData?.[1]?.credit
    },
    {
      name: getMonth(actualMonth - 4),
      registred: commissionsData?.[0]?.credit
    },
  ]


  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "dashboardCard.commissions.title" })}
        icon={<EuroIcon />}
        height="80%"
      >
        <ResponsiveContainer width="100%" height={200}>
          <BarChart
            data={data}
            margin={{ top: 5, left: -25, right: 10, bottom: 15 }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar name={intl.formatMessage({ id: "dashboardCard.commissions.title" })} dataKey="registred" stackId="a" barSize={25} fill={defaultColors.one} />
            <Legend
              wrapperStyle={{ bottom: -20 }}
            />
          </BarChart>
        </ResponsiveContainer>
        <br />
        <hr />
        <Grid container >
          <Grid item sm={12}>
            <b>{intl.formatMessage({ id: "storeDetail.printedPhotosCard.allPhotos" })}: {totalCount} C</b>
            <Box className={classes.floatRight}>
              {(commissionList) &&
                <Button
                  style={{ marginTop: "-10px" }}
                  size="sm"
                  round
                  color="info"
                  onClick={() => history.push({
                    pathname: `/admin${generatePath(paths.commissions.list)}`
                  })}
                >
                  {intl.formatMessage({ id: "dashboardCard.commissions.button" })}
                </Button>
              }
            </Box>
          </Grid>
          <Grid item sm={12}><br /></Grid>
        </Grid>
      </IconCard>
    </>
  )
}

export default injectIntl(UsersCard);
