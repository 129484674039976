import gql from 'graphql-tag';

const STREAM_DISCOVER = gql`
mutation streamDiscover{
  streamDiscover{
    id
  }
}
`;

export default STREAM_DISCOVER;