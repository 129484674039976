import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import OperationTable from "./OperationTable";
import { useLocation } from 'react-router-dom';
import ALL_OPERATIONS from "queries/OperationsQueries/allOperations";

const OperationListPage = (props) => {
    const { intl } = props;
    const { history } = props;
    let location = useLocation();
    let orFilter = location.state ? location.state : null

    const renderOperationsTable = () => {
        return (
            <OperationTable
                query={ALL_OPERATIONS}
                orFilter={orFilter}
                history={history}
            />
        );
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "operationsList.title" })}
                withBackButton={false}
            />
            <IconCard
                icon={<SwapHorizIcon />}
                title={intl.formatMessage({ id: "operationsList.table.title" })}
            >
                {renderOperationsTable()}
            </IconCard>
        </>
    );
};

export default withRouter(injectIntl(OperationListPage));