import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from "components/CustomButtons/Button.js";
import { useMutation } from 'react-apollo';
import paths from "paths";
import { generatePath } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { defaultColors } from "common/styles/configLayout.js";
import { useSnackbar } from "notistack";
import { pathOr } from "rambda";

// core components
import PageHeader from 'components-lib/PageHeader/PageHeader';
import { Grid } from "@material-ui/core";
import IconCard from 'components-lib/IconCard/IconCard';

import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";
import Step4 from "./WizardSteps/Step4.js";
import Box from "@material-ui/core/Box";
import clsx from 'clsx';
import StepConnector from '@material-ui/core/StepConnector';
import { COMMERCIAL_CAMPAIGN_CREATE, INTERNAL_CAMPAIGN_CREATE } from "queries/CampaignsQueries/campaignCreate";
import InfoIcon from '@material-ui/icons/Info';
import RoomIcon from '@material-ui/icons/Room';
import SendIcon from '@material-ui/icons/Send';
import AddBoxIcon from '@material-ui/icons/AddBox';
import QueueIcon from '@material-ui/icons/Queue';
import CampaignTypeEnum from "common/enums/CampaignType";
import MusicVideoIcon from '@material-ui/icons/MusicVideo';



const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);



const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#bdbdbd',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: defaultColors.one,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: defaultColors.one,
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    floatRight: {
        float: "right"
    },
}));


const CampaignNewBidPage = (props) => {
    const classes = useStyles();
    const { history, intl, axios } = props;

    const [campaignType, setCampaignType] = useState(null);
    const [queryVariables, setQueryVariables] = useState(null);

    useEffect(() => {
        if (history.location.pathname.indexOf(CampaignTypeEnum.INTERNAL) > -1) {
            setCampaignType("Internal");
            setQueryVariables({ type: "Internal" });
        }
        if (history.location.pathname.indexOf(CampaignTypeEnum.COMMERCIAL) > -1) {
            setCampaignType("Commercial");
            setQueryVariables({ type: "Commercial" });
        }
    }, [history.location])

    const [activeStep, setActiveStep] = useState(0);

    const [createCommercialCampaign] = useMutation(COMMERCIAL_CAMPAIGN_CREATE);
    const [createInternalCampaign] = useMutation(INTERNAL_CAMPAIGN_CREATE);

    const [companyId, setCompanyId] = useState(null)


    const steps = getSteps();
    const { enqueueSnackbar } = useSnackbar();
    const handleNext = () => {
        setCompanyId(valuesStep1?.companyId)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 0) setInitialData(valuesStep1)
        if (activeStep === 1) setInitialData(valuesStep2)
        if (activeStep === 2) {
            setInitialData(valuesStep3);
            setTimes(times);
            setCriteria(criteria)
        }

        if (companyId !== valuesStep1?.companyId) {
            setInitialData({
                ...valuesStep1,
                spotIds: [],
                minSpotInterval: 0,
                budget: null,
                maxBid: undefined
            });

            setCriteria({
                locationIds: ["*"],
                segmentIds: ["*"],
                companyIds: ["*"],
                zoneIds: ["*"]
            })
        }
    };

    const handleBack = () => {
        setCompanyId(valuesStep1?.companyId)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (activeStep === 0) setInitialData(valuesStep1)
        if (activeStep === 1) setInitialData(valuesStep2)
        if (activeStep === 2) {
            setInitialData(valuesStep3);
            setTimes(times);
            setCriteria(criteria)
        }
    };
    function getSteps() {
        return [
            intl.formatMessage({ id: "campaigns.wizard.basicInfo" }),
            intl.formatMessage({ id: "campaigns.wizard.media" }),
            intl.formatMessage({ id: "campaigns.wizard.budgetAndTimePlan" }),
            intl.formatMessage({ id: "campaigns.wizard.confirm" }),
        ];
    }

    const [valid, setValid] = useState(false);
    const [times, setTimes] = useState([
        { weekDay: "Monday", from: "00:00", to: "23:59" },
        { weekDay: "Tuesday", from: "00:00", to: "23:59" },
        { weekDay: "Wednesday", from: "00:00", to: "23:59" },
        { weekDay: "Thursday", from: "00:00", to: "23:59" },
        { weekDay: "Friday", from: "00:00", to: "23:59" },
        { weekDay: "Saturday", from: "00:00", to: "23:59" },
        { weekDay: "Sunday", from: "00:00", to: "23:59" }
    ])
    const [criteria, setCriteria] = useState({
        locationIds: ["*"],
        segmentIds: ["*"],
        companyIds: ["*"],
        zoneIds: ["*"]
    })
    const [valuesStep1, setValuesStep1] = useState({});
    const [valuesStep2, setValuesStep2] = useState({});
    const [valuesStep3, setValuesStep3] = useState({});
    const [initialData, setInitialData] = useState({
        topicIds: [],
        spotIds: [],
        minSpotInterval: 10,
    })

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <Step1
                    campaignType={campaignType}
                    initialData={initialData}
                    callback={(newValue) => setValuesStep1(newValue)}
                    callbackValid={(newValue) => setValid(newValue)}
                />;
            case 1:
                return <Step2
                    campaignType={campaignType}
                    initialData={initialData}
                    spotType={valuesStep1?.spotType}
                    callback={(newValue) => setValuesStep2(newValue)}
                    callbackValid={(newValue) => setValid(newValue)}
                    companyId={valuesStep1?.companyId}
                />;
            case 2:
                return <Step3
                    campaignType={campaignType}
                    initialData={initialData}
                    axios={axios}
                    callback={(newValue) => setValuesStep3(newValue)}
                    callbackValid={(newValue) => setValid(newValue)}
                    companyId={valuesStep1?.companyId}
                    callbackTimes={(newValue) => setTimes(newValue)}
                    times={times}
                    criteria={criteria}
                    callbackCriteria={(newValue) => setCriteria(newValue)}
                />;
            case 3:
                return <Step4
                    campaignType={campaignType}
                    companyId={valuesStep1?.companyId}
                    initialData={{
                        ...valuesStep1,
                        ...valuesStep2,
                        ...valuesStep3,
                    }}
                    times={times}
                    criteria={criteria}
                />;
            default:
                return 'Unknown step';
        }
    }




    const setTitle = (active) => {
        if (activeStep === 0) return intl.formatMessage({ id: "campaigns.wizard.basicInfo.description" })
        else if (activeStep === 1) return intl.formatMessage({ id: "campaigns.wizard.media.description" })
        else if (activeStep === 2) return intl.formatMessage({ id: "campaigns.wizard.budgetAndTimePlan.description" })
        else return intl.formatMessage({ id: "campaigns.wizard.confirm.description" })
    }


    const handleCreateCampaign = () => {
        let helpCriteria = {
            locationIds: (criteria?.locationIds[0] === "*") ? [] : criteria?.locationIds,
            segmentIds: (criteria?.segmentIds[0] === "*") ? [] : criteria?.segmentIds,
            companyIds: (criteria?.companyIds[0] === "*") ? [] : criteria?.companyIds,
            zoneIds: (criteria?.zoneIds[0] === "*") ? [] : criteria?.zoneIds
        }

        let setTopicIds = valuesStep1?.topicIds?.map(topic => Number(topic));
        let setSpotIds = valuesStep2?.spotIds?.map(spot => Number(spot));
        let setSpotType = (campaignType === "Internal")
            ?
            (valuesStep1?.spotType) ?
                setSpotType = valuesStep1?.spotType
                :
                setSpotType = "Advertisement"
            :
            setSpotType = "Advertisement"

        let setBudget = (valuesStep3?.budget) ? valuesStep3?.budget : 0.0
        let setMaxBid = (valuesStep3?.maxBid) ? valuesStep3?.maxBid : 0.0


        let inputData = {
            ...valuesStep1,
            ...valuesStep2,
            ...valuesStep3,
            spotType: (campaignType === "Internal") ? setSpotType : undefined,
            activeTimes: times,
            criteria: helpCriteria,
            topicIds: setTopicIds,
            spotIds: setSpotIds,
            budget: (campaignType === "Commercial") ? setBudget : undefined,
            maxBid: (campaignType === "Commercial") ? setMaxBid : undefined,
        }
        if (campaignType === "Internal") {
            createInternalCampaign({
                variables: {
                    input: inputData
                }
            }).then((response) => {
                const id = pathOr("", ["data", "internalCampaignCreate", "id"], response);
                history.push(`/admin${generatePath(paths.campaigns.internalDetail, { campaignId: Number(id) })}`)
                enqueueSnackbar(intl.formatMessage({ id: "campaigns.create.success" }), { variant: 'success' });
            }).catch((err) => {
                console.log(err);
            });
        } else {
            createCommercialCampaign({
                variables: {
                    input: inputData
                }
            }).then((response) => {
                const id = pathOr("", ["data", "commercialCampaignCreate", "id"], response);
                history.push(`/admin${generatePath(paths.campaigns.commercialDetail, { campaignId: Number(id) })}`)
                enqueueSnackbar(intl.formatMessage({ id: "campaigns.create.success" }), { variant: 'success' });
            }).catch((err) => {
                console.log(err);
            });
        }

    };

    //Minimalny interval opakovania pre pouzivatela (dni)
    function ColorlibStepIcon(props) {
        const classes = useColorlibStepIconStyles();
        const { active, completed } = props;

        const icons = {
            1: <InfoIcon />,
            2: <MusicVideoIcon />,
            3: <RoomIcon />,
            4: <SendIcon />,
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    return (
        <>
            <PageHeader
                title={(campaignType === "Commercial")
                    ? intl.formatMessage({ id: "campaigns.new.commercial.title" })
                    : intl.formatMessage({ id: "campaigns.new.internal.title" })
                }
                handleBackAction={(e) => history.goBack()}
            />
            <Grid item><br /></Grid>
            <Grid container justify="center">
                <Grid item sm={8}>
                    <IconCard
                        icon={campaignType === "Commercial" ? <AddBoxIcon /> : <QueueIcon />}
                        title={setTitle(activeStep)}
                    >
                        <Grid item><hr /></Grid>
                        <Grid item>
                            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>

                            {getStepContent(activeStep)}

                            <Box className={classes.floatRight}>
                                {(activeStep !== 0) &&
                                    <Button round disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        {intl.formatMessage({ id: 'deviceDetail.status.back' })}
                                    </Button>
                                }

                                <Button
                                    round
                                    disabled={valid ? false : true}
                                    variant="contained"
                                    color="success"
                                    onClick={(activeStep === 3) ? handleCreateCampaign : handleNext}
                                >
                                    {activeStep === steps.length - 1 ? intl.formatMessage({ id: 'campaigns.button.submit' }) : intl.formatMessage({ id: 'campaigns.button.continue' })}
                                </Button>
                            </Box>
                        </Grid>

                    </IconCard>
                </Grid>
            </Grid>
        </>
    );
}

export default injectIntl(CampaignNewBidPage);