import React from 'react';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Formik } from 'formik';
import { pathOr } from 'rambda';
import FormikField from 'components-lib/FormikField/FormikField';
import { validationSchemaStep1, validationSchemaStep1Internal } from "./validationSchema";
import { injectIntl } from 'react-intl';
import ALL_COMPANIES from "queries/CompaniesQueries/selectCompanies"
import { ALL_TOPICS } from "queries/CampaignsQueries/allTopics"
import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
import MEDIAS_BY_COMPANY from "queries/MediaQueries/mediasByCompany";

const Step1 = ({ intl, callback, callbackValid, initialData, campaignType }) => {

  const { data: companiesData } = useQuery(ALL_COMPANIES)
  const { data: topicsData } = useQuery(ALL_TOPICS)
  const [companyId, setCompanyId] = useState(null)
  const [spotType, setSpotType] = useState(null)
  let spotTypeFilter;
  if (spotType) {
    if (spotType === "Announcement")
      spotTypeFilter = "Announcement"
    else
      spotTypeFilter = "InternalSpot"
  }
  else {
    if (campaignType === "Internal")
      spotTypeFilter = "InternalSpot"
    else
      spotTypeFilter = "CommercialSpot"
  }
  const getCompaniesOptions = () => {
    const comapniesOptions = [];
    pathOr([], ['companies', 'items'], companiesData).map(company => {
      return (comapniesOptions.push({
        value: pathOr("", "id", company),
        label: `${pathOr("", "name", company)}`,
      }))
    })
    return comapniesOptions
  }

  const getTopicOptions = () => {
    const topicOptions = [];
    pathOr([], ['topics', 'items'], topicsData).map(topic => {
      topicOptions.push({
        label: topic.name,
        value: topic.id
      })
    })
    return topicOptions
  }

  const { data: companyData } = useQuery(COMPANY_DETAIL, { variables: { id: companyId }, skip: !companyId });

  const { data: mediasData } = useQuery(MEDIAS_BY_COMPANY(Number(companyId)),
    {
      variables: {
        filter: [
          {
            acceptanceStatus: {
              eq: "Approved"
            },
            category: {
              eq: spotTypeFilter
            }
          }
        ]
      },
      skip: !companyId
    }
  );

  let balance = pathOr('', ['company', 'account', 'balance'], companyData)
  let media = pathOr('', ['mediasByCompany', 'totalCount'], mediasData)
  const spotTypeOptions = [
    {
      value: "Announcement",
      label: intl.formatMessage({ id: 'campaigns.infoCard.Announcement' })
    },
    {
      value: "Advertisement",
      label: intl.formatMessage({ id: 'campaigns.infoCard.Advertisement' })
    },
  ]


  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialData}
        validationSchema={(campaignType === "Commercial") ? validationSchemaStep1(intl) : validationSchemaStep1Internal(intl)}
      >
        {formikProps => (
          <Grid container>
            {(campaignType === "Commercial")
              ? callbackValid(formikProps.isValid && formikProps.values.name &&
                formikProps.values.companyId && formikProps.values.validFrom &&
                formikProps.values.validTo && balance >= 1 && media > 0 && (formikProps.values?.topicIds?.length > 0) && (formikProps.values?.topicIds?.[0] !== 'AllNotifications'))
              :
              callbackValid(formikProps.isValid && formikProps.values.name &&
                formikProps.values.companyId && formikProps.values.validFrom &&
                formikProps.values.validTo && formikProps.values.spotType && media > 0)
            }

            {callback(formikProps.values)}
            {setCompanyId(formikProps.values?.companyId)}
            {setSpotType(formikProps.values?.spotType)}
            {/*(getCompaniesOptions()?.length === 1) && formikProps.setFieldValue("companyId", getCompaniesOptions()?.[0]?.value)*/}
            <Grid item sm={12}>
              <FormikField
                name="name"
                labelText={intl.formatMessage({ id: 'campaigns.infoCard.name' })}
                formikProps={formikProps}
                data={formikProps.values}
                highlightChange
              />
            </Grid>
            <Grid item sm={12}>
              <FormikField
                type="select"
                name="companyId"
                labelText={intl.formatMessage({ id: 'campaigns.infoCard.company' })}
                options={getCompaniesOptions()}
                formikProps={formikProps}
                data={formikProps.values}
                highlightChange
              />
            </Grid>
            <> {(campaignType === "Commercial") ?
              <>{formikProps.values.companyId &&
                <>{(balance < 1) ?
                  <span style={{ fontSize: "0.8em", color: "red" }}>{intl.formatMessage({ id: 'campaigns.infoCard.withoutCredits' })}</span>
                  : (media === 0) &&
                  <span style={{ fontSize: "0.8em", color: "red" }}>{intl.formatMessage({ id: 'campaigns.infoCard.withoutMedia' })}</span>
                }
                </>
              }</>
              :
              (media === 0) &&
              <span style={{ fontSize: "0.8em", color: "red" }}>{intl.formatMessage({ id: 'campaigns.infoCard.withoutMedia' })}</span>
            }</>
            {(campaignType === "Internal") &&
              <Grid item sm={12}>
                <FormikField
                  name="spotType"
                  labelText={intl.formatMessage({ id: 'campaigns.infoCard.type' })}
                  formikProps={formikProps}
                  data={formikProps.values}
                  type="select"
                  highlightChange
                  options={spotTypeOptions}
                />
              </Grid>
            }
            {(campaignType === "Commercial") &&
              <Grid item sm={12}>
                <FormikField
                  name="topicIds"
                  labelText={intl.formatMessage({ id: "campaigns.infoCard.topics" })}
                  formikProps={formikProps}
                  data={formikProps.values}
                  type="multiSelect"
                  highlightChange
                  options={getTopicOptions()}
                />
              </Grid>
            }
            <Grid container spacing={3}>
              <Grid item sm={6}>
                <br />
                <FormikField
                  name="validFrom"
                  labelText={intl.formatMessage({ id: 'campaigns.infoCard.startDate' })}
                  formikProps={formikProps}
                  data={formikProps.values}
                  highlightChange
                  type="picker2"
                />
              </Grid>
              <Grid item sm={6}>
                <br />
                <FormikField
                  name="validTo"
                  labelText={intl.formatMessage({ id: 'campaigns.infoCard.endDate' })}
                  formikProps={formikProps}
                  data={formikProps.values}
                  highlightChange
                  type="picker2"
                />
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <br />
              <FormikField
                name="description"
                type="multiline"
                labelText={intl.formatMessage({ id: "invoicesList.assignModal.description" })}
                helperText={170 - pathOr('', ['values', 'description'], formikProps).length}
                formikProps={formikProps}
                inputProps={{ rows: 2 }}
                highlightChange
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
}

export default injectIntl(Step1);