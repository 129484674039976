import React from "react";
import { injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import IconCard from "components-lib/IconCard/IconCard";
import Favorite from "@material-ui/icons/Favorite";
import FormikField from "../../../../components-lib/FormikField/FormikField";
import TestHoursCard from "./TestHoursCard";

const ScheduleCard = (props) => {
    const {
        intl,
        formikProps,
        times,
        callbackTimes
    } = props;


    const handleTimeChange = (mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
        thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
        isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
        isSaturdayEnabled, isSundayEnabled) => {
        const resss =
            [
                isMondayEnabled &&
                {
                    weekDay: "Monday",
                    from: mondayFrom,
                    to: mondayTo
                },

                isTuesdayEnabled &&
                {
                    weekDay: "Tuesday",
                    from: tuesdayFrom,
                    to: tuesdayTo
                },

                isWednesdayEnabled &&
                {
                    weekDay: "Wednesday",
                    from: wednesdayFrom,
                    to: wednesdayTo
                },

                isThursdayEnabled &&
                {
                    weekDay: "Thursday",
                    from: thursdayFrom,
                    to: thursdayTo
                },

                isFridayEnabled &&
                {
                    weekDay: "Friday",
                    from: fridayFrom,
                    to: fridayTo
                },

                isSaturdayEnabled &&
                {
                    weekDay: "Saturday",
                    from: saturdayFrom,
                    to: saturdayTo
                },

                isSundayEnabled &&
                {
                    weekDay: "Sunday",
                    from: sundayFrom,
                    to: sundayTo
                },
            ]

        let help = [];
        let j = 0;
        for (let i = 0; i < resss.length; i++) {
            if (resss[i] !== false) {
                help[j] = resss[i]
                j++;
            }
        }
        //console.log("help")
        //console.log(help)
        callbackTimes(help)
    }

    /*const handleOpenHolidays = (event) => {
        callbackOpenHolidays(event);
    };

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const spotsInterval = [
        { value: "0", label: "0 minút", },
        { value: "5", label: "5 minút", },
        { value: "10", label: "10 minút", },
        { value: "15", label: "15 minút", },
        { value: "20", label: "20 minút", },
        { value: "25", label: "25 minút", },
        { value: "30", label: "30 minút", },
    ];

    const [checkedOpenHolidaysListening, setCheckedOpenHolidaysListening] = useState(openHolidays);
*/

    const renderOpenHours = () => {
        return (

            <TestHoursCard
                times={times}
                handleTimeChange={handleTimeChange}
            />
        )
    }


    /*const renderOfflinePlay = () => {
         return (
             <>
                 <Grid container spacing={3}>
                     <Grid item sm={12}>
                         Otvorené cez sviatky
 
                         <Box className={classes.floatRight}>
                             <Switch
                                 checked={openHolidays}
                                 onChange={(event) => {
                                     handleOpenHolidays(event.target.checked)
                                 }
                                 }
                                 value="openHolidays"
                                 classes={{
                                     switchBase: classes.switchBase,
                                     checked: classes.switchChecked,
                                     thumb: classes.switchIcon,
                                     track: classes.switchBar,
                                 }}
                             />
                         </Box>
                         {openHolidays ? <><br /><label>Otvorené</label></> : <><br /><label>Zatvorené</label></>
                         }
                     </Grid>
                 </Grid>
             </>
         );
     };
 */
    return (
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.scheduleCard.title' })}
            icon={<Favorite />}
        >
            <Grid item sm={12}>
                <FormikField
                    disabled={false}
                    name="campaign.minSpotInterval"
                    labelText={intl.formatMessage({ id: 'campaigns.scheduleCard.repeat' })}
                    formikProps={formikProps}
                    data={formikProps.values}
                    highlightChange
                />
            </Grid>
            <Grid item sm={12}>
                <hr style={{ margin: 0 }} />
            </Grid>

            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {renderOpenHours()}
                </Grid>
                <br /><br />


            </Grid>
        </IconCard>
    )

};

export default injectIntl(ScheduleCard);
