import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import MusicVideoIcon from '@material-ui/icons/MusicVideo';
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import { pathOr } from "rambda";
import MEDIA_DOWNLOAD from "queries/MediaQueries/mediaDownload";
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FolderIcon from '@material-ui/icons/Folder';

const useStyles = makeStyles((theme) => styles(theme));


const SpotsCard = (props) => {
    const { intl, spots = [], } = props;

    const classes = useStyles();

    /**
     * MEDIA
     */
    const [mediaDownload] = useMutation(MEDIA_DOWNLOAD);

    const [trackPlaying, setTrackPlaying] = useState(null);
    const [isPlaying, setIsPlaying] = useState(null);

    const handlePlay = (dataId) => {
        trackPlaying && handleStopPlay()
        mediaDownload({
            variables: {
                id: dataId
            }
        }).then(response => {
            const stream = new Audio((pathOr('', ['data', 'mediaDownload', 'url'], response)));
            setTrackPlaying(stream);
            setIsPlaying(dataId);
            stream.play();
        })
    }

    useEffect(() => {
        return () => {
            trackPlaying && handleStopPlay()
        }
    }, trackPlaying)

    const handleStopPlay = () => {
        trackPlaying.pause();
        setTrackPlaying(null);
        setIsPlaying(null);
    }

    const getActions = (data) => {
        const dataId = pathOr('', ['media', 'id'], data)

        return <>

            {isPlaying && isPlaying === dataId
                ? <PauseCircleFilledIcon
                    className={classes.pointer}
                    onClick={() => handleStopPlay()}
                />
                : <PlayCircleFilledOutlinedIcon
                    className={classes.pointer}
                    onClick={() => { handlePlay(dataId) }}
                />
            }

        </>
    }
    //END


    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.spotsCard.title' })}
            icon={<MusicVideoIcon />}
        >
            <List className={classes.root}>
                {spots.map(media =>
                    <ListItem >
                        <ListItemIcon>
                            {getActions(media)}
                        </ListItemIcon>
                        <ListItemText
                            style={{ whiteSpace: "nowrap" }}
                            primary={<span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{pathOr('', ['media', 'name'], media)}</span>}
                            secondary={pathOr('', ['media', 'bid'], media)}
                        />
                        <Box className={classes.floatRight}>
                            <ListItemText >{pathOr('', 'playCount', media)} {intl.formatMessage({ id: 'campaigns.spotsCard.count' })}</ListItemText>
                        </Box>
                    </ListItem>
                )}
            </List>
        </IconCard>
    </>
};

export default injectIntl(SpotsCard);
