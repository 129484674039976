import { Grid } from "@material-ui/core";
import Favorite from "@material-ui/icons/Favorite";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import FormikField from "components-lib/FormikField/FormikField";

const BudgetCard = (props) => {
    const { intl, formikProps, } = props;

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.budgetCard.title' })}
            icon={<Favorite />}
        >

            <Grid container>
                <Grid item sm={12}>
                    <FormikField
                        name="campaign.budget"
                        labelText={intl.formatMessage({ id: 'campaigns.budgetCard.budgetTotal' })}
                        formikProps={formikProps}
                        data={formikProps.values}
                        highlightChange
                    />
                </Grid>
                <Grid item sm={12}>
                    <FormikField
                        name="campaign.maxBid"
                        labelText={intl.formatMessage({ id: 'campaigns.budgetCard.MaxBid' })}
                        formikProps={formikProps}
                        data={formikProps.values}
                        highlightChange
                    />
                </Grid>
            </Grid>

        </IconCard>
    </>
};

export default injectIntl(BudgetCard);
