import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import FormikField from "components-lib/FormikField/FormikField";
import { pathOr } from "rambda";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import SELECT_COMPANIES from "queries/CompaniesQueries/selectCompanies"
import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
import { Grid } from "@material-ui/core";

const InvoiceCard = (props) => {
  const {
    intl,
    formikProps,
    loading,
    type,
    callbackMaxCredits,
    payoutCreate,
    invocieCreate
  } = props;

  let helpCompId = (formikProps.values.companyId) ? formikProps.values.companyId : 2
  const queryParams = { id: helpCompId }
  const { data: companiesData } = useQuery(SELECT_COMPANIES)
  const [companies, setCompanies] = useState([]);
  const { data: dataDetail } = useQuery(COMPANY_DETAIL, { variables: queryParams, skip: (!payoutCreate, !invocieCreate) })
  let balance = pathOr("", ["company", "account", "balance"], dataDetail)

  useEffect(() => {
    let companiesSelectItems = pathOr([], "companies.items", companiesData).map(
      (company) => {
        return {
          value: pathOr("", "id", company),
          label: `${pathOr("", "name", company)}`,
        };
      }
    );
    setCompanies(companiesSelectItems);
  }, [companiesData]);

  const getRemains = (cred) => {
    callbackMaxCredits(balance)
    let final = (cred)
      ? Number(balance) - Number(cred)
      : balance
    return <>
      {(final >= 0)
        ? <span style={{ color: "black" }}>{intl.formatMessage({ id: 'campaigns.budgetCard.balanceOfCredits' })} {final} C</span>
        : ""
      }
    </>
  }

  if (loading) return "Loading..."
  return (
    <>
      <FormikField
        type="select"
        name="companyId"
        labelText={intl.formatMessage({ id: "invoicesList.assignModal.company" })}
        options={companies}
        formikProps={formikProps}
        highlightChange
      />
      {(!type) ?
        <>
          {formikProps.values.companyId &&
            <>
              <FormikField
                name="price"
                labelText={intl.formatMessage({ id: "invoicesList.assignModal.price" })}
                formikProps={formikProps}
                highlightChange
              />
              <FormikField
                name="credit"
                labelText={
                  <p>
                    {intl.formatMessage({ id: "invoicesList.assignModal.credit" })}
                    {" (Max. " + balance + " C)"}
                  </p>
                }
                formikProps={formikProps}
                highlightChange
              />
              <Grid item sm={12}>
                {getRemains(formikProps.values.credit)}
              </Grid>
            </>
          }
        </>
        :
        <>
          <FormikField
            name="price"
            labelText={intl.formatMessage({ id: "invoicesList.assignModal.price" })}
            formikProps={formikProps}
            highlightChange
          />
          <FormikField
            name="credit"
            labelText={<p>{intl.formatMessage({ id: "invoicesList.assignModal.credit" })} {" (C)"}</p>}
            formikProps={formikProps}
            highlightChange
          />
        </>
      }

      <FormikField
        name="identifier"
        labelText={(type)
          ? intl.formatMessage({ id: "invoicesList.assignModal.identifier" })
          : intl.formatMessage({ id: "payoutsList.assignModal.identifier" })
        }
        formikProps={formikProps}
        highlightChange
      />

      <FormikField
        name="description"
        type="multiline"
        labelText={intl.formatMessage({ id: "invoicesList.assignModal.description" })}
        helperText={100 - pathOr('', ['values', 'description'], formikProps).length}
        formikProps={formikProps}
        inputProps={{ rows: 2 }}
        highlightChange
      />
    </>
  );
};

export default withRouter(injectIntl(InvoiceCard));
