import gql from 'graphql-tag';

const ALL_MEDIA_REJECTED = gql`
query mediaTable(
  $offset: Int
  $limit: Int
  $sort: [MediaTableSortInput!]
  $filter: [MediaTableFilterInput]
) {
  mediaTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
    totalCount
    items {
      id
      bid
      name
      status
      category
      size
      duration
      owner
      acceptanceStatus
      createdAt
      acceptanceDescription
    }
  }
}
`;

export default ALL_MEDIA_REJECTED;