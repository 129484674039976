import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LocationInput from "./tree/Tree";
import SegmentInput from "./tree/Tree";
import CompanyInput from "./tree/Tree";
import ZoneInput from "./tree/Tree";
import useLocation from "../../hook/form/useLocation";
import useSegment from "../../hook/form/useSegment";
import useCompany from "../../hook/form/useCompany";
import useZone from "../../hook/form/useZone";
import Lemmatizer from "../../service/lemmatize/Lemmatizer";
import getLocationName from "../../service/location/getName";
import TopCheckedRetriever from "../../service/tree/util/TopCheckedRetriever";
import TreeCheckedSetter from "../../service/tree/util/TreeCheckedSetter";
import { injectIntl } from "react-intl";

const Form = ({ axios, campaign, onChange, intl, ...rest }) => {
    const value = {
        locationIds: rest?.value?.locationIds || [],
        segmentIds: rest?.value?.segmentIds || [],
        companyIds: rest?.value?.companyIds || [],
        zoneIds: rest?.value?.zoneIds || [],
    };
    const [locationTree, checkedLocationIds] = useLocation(axios, campaign, value);
    const [segmentTree, checkedSegmentIds] = useSegment(axios, campaign, value);
    const [companyTree, checkedCompanyIds] = useCompany(axios, campaign, value);
    const [zoneTree, checkedZoneIds] = useZone(axios, campaign, value);
    const locationTreeData = locationTree?.toObject() || null;
    const segmentTreeData = segmentTree?.toObject() || null;
    const companyTreeData = companyTree?.toObject() || null;
    const zoneTreeData = zoneTree?.toObject() || null;
    const locationLemmatizer = new Lemmatizer(intl.formatMessage({ id: 'campaigns.campaignWidget.location1' }), intl.formatMessage({ id: 'campaigns.campaignWidget.location2' }), intl.formatMessage({ id: 'campaigns.campaignWidget.location3' }), intl.formatMessage({ id: 'campaigns.campaignWidget.location1' }));
    const segmentLemmatizer = new Lemmatizer(intl.formatMessage({ id: 'campaigns.campaignWidget.segment1' }), intl.formatMessage({ id: 'campaigns.campaignWidget.segment2' }), intl.formatMessage({ id: 'campaigns.campaignWidget.segment3' }), intl.formatMessage({ id: 'campaigns.campaignWidget.segment1' }));
    const companyLemmatizer = new Lemmatizer(intl.formatMessage({ id: 'campaigns.campaignWidget.company1' }), intl.formatMessage({ id: 'campaigns.campaignWidget.company2' }), intl.formatMessage({ id: 'campaigns.campaignWidget.company3' }), intl.formatMessage({ id: 'campaigns.campaignWidget.company1' }));
    const zoneLemmatizer = new Lemmatizer(intl.formatMessage({ id: 'campaigns.campaignWidget.store1' }), intl.formatMessage({ id: 'campaigns.campaignWidget.store2' }), intl.formatMessage({ id: 'campaigns.campaignWidget.store3' }), intl.formatMessage({ id: 'campaigns.campaignWidget.store1' }));
    const uniqueIds = (ids) => ids.filter((id, index) => ids.indexOf(id) === index);
    const preparePlaceholder = (lemmatizer, ids) => {
        return ids.length === 1 && ids[0] === '*' ? intl.formatMessage({ id: 'campaigns.campaignWidget.all' }) : lemmatizer.get(ids.length);
    };
    const onLocationItemChange = (id, isChecked) => {
        onChange({
            ...value,
            locationIds: setItemChecked(locationTree, id, isChecked)
        });
    };
    const onSegmentItemChange = (id, isChecked) => {
        onChange({
            ...value,
            segmentIds: setItemChecked(segmentTree, id, isChecked)
        });
    };
    const onCompanyItemChange = (id, isChecked) => {
        onChange({
            ...value,
            companyIds: setItemChecked(companyTree, id, isChecked)
        });
    };
    const onZoneItemChange = (id, isChecked) => {
        onChange({
            ...value,
            zoneIds: setItemChecked(zoneTree, id, isChecked)
        });
    };
    const setItemChecked = (tree, id, isChecked) => {
        if (tree === null)
            return [];
        TreeCheckedSetter.set(tree, [id], isChecked);
        return uniqueIds(TopCheckedRetriever.get(tree).map(node => node.getId()));
    };
    return (React.createElement(Grid, { container: true, spacing: 3 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h2" }, intl.formatMessage({ id: 'campaigns.campaignWidget.store2' }))),
        React.createElement(Grid, { item: true, xs: 12, md: 12, lg: 12 },
            React.createElement(LocationInput, { label: intl.formatMessage({ id: 'campaigns.campaignWidget.location2' }), placeholder: preparePlaceholder(locationLemmatizer, checkedLocationIds), tree: locationTreeData, nodeNamePreparer: getLocationName, selectedIds: value.locationIds, onChange: onLocationItemChange })),
        React.createElement(Grid, { item: true, xs: 12, md: 12, lg: 12 },
            React.createElement(SegmentInput, { label: intl.formatMessage({ id: 'campaigns.campaignWidget.segment2' }), placeholder: preparePlaceholder(segmentLemmatizer, checkedSegmentIds), tree: segmentTreeData, selectedIds: value.segmentIds, onChange: onSegmentItemChange })),
        React.createElement(Grid, { item: true, xs: 12, md: 12, lg: 12 },
            React.createElement(CompanyInput, { label: intl.formatMessage({ id: 'campaigns.campaignWidget.company2' }), placeholder: preparePlaceholder(companyLemmatizer, checkedCompanyIds), tree: companyTreeData, selectedIds: value.companyIds, onChange: onCompanyItemChange })),
        React.createElement(Grid, { item: true, xs: 12, md: 12, lg: 12 },
            React.createElement(ZoneInput, { label: intl.formatMessage({ id: 'campaigns.campaignWidget.store2' }), placeholder: preparePlaceholder(zoneLemmatizer, checkedZoneIds), tree: zoneTreeData, selectedIds: value.zoneIds, onChange: onZoneItemChange }))));
};
export default injectIntl(Form);