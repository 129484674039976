import gql from 'graphql-tag';

const COMMISSIONS_EXPORT = gql`
mutation commissionsExport($input: CommissionsExportInput) {
  commissionsExport(input: $input) {
    type
    url
    expiresAt
  }
}`

export default COMMISSIONS_EXPORT