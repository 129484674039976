import gql from 'graphql-tag';

export const ALL_TOPICS = gql`
    query topics{
        topics{
            items{
                id
                name
            }
        }
    }
`;