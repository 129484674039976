import React from 'react';
import { Grid } from '@material-ui/core';
import ChartCard from 'components-lib/ChartCard/ChartCard';
import InfoIcon from '@material-ui/icons/Info';
import Button from "components/CustomButtons/Button.js";
import styles from "common/styles/widgetStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import { generatePath } from "react-router-dom";
import paths from "paths";
import { commissionList } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme))

const CommissionsCard = (props) => {
  const {
    intl,
    firstMonth,
    secondMonth,
    thirdMonth,
    actualMonth,
    history
  } = props;
  const classes = useStyles();
  let total = Number(firstMonth + secondMonth + thirdMonth).toFixed(2)


  const getMonth = (month) => {
    let helpMonth = month;
    if (month === 0) helpMonth = 12
    if (month === -1) helpMonth = 11
    if (month === -2) helpMonth = 10
    if (month === -3) helpMonth = 9
    if (month === -4) helpMonth = 8

    switch (helpMonth) {
      case 1: return intl.formatMessage({ id: 'commissionsList.month.january' });
      case 2: return intl.formatMessage({ id: 'commissionsList.month.february' });
      case 3: return intl.formatMessage({ id: 'commissionsList.month.march' });
      case 4: return intl.formatMessage({ id: 'commissionsList.month.april' });
      case 5: return intl.formatMessage({ id: 'commissionsList.month.may' });
      case 6: return intl.formatMessage({ id: 'commissionsList.month.june' });
      case 7: return intl.formatMessage({ id: 'commissionsList.month.july' });
      case 8: return intl.formatMessage({ id: 'commissionsList.month.august' });
      case 9: return intl.formatMessage({ id: 'commissionsList.month.september' });
      case 10: return intl.formatMessage({ id: 'commissionsList.month.october' });
      case 11: return intl.formatMessage({ id: 'commissionsList.month.november' });
      case 12: return intl.formatMessage({ id: 'commissionsList.month.december' });
      default: return helpMonth
    }
  }

  /*const data = [
    { name: getMonth(actualMonth - 4), com: (minusFour) ? parseFloat(minusFour).toFixed(2) : "0" },
    { name: getMonth(actualMonth - 3), com: (minusThree) ? parseFloat(minusThree).toFixed(2) : "0" },
    { name: getMonth(actualMonth - 2), com: (minusTwo) ? parseFloat(minusTwo).toFixed(2) : "0" },
    { name: getMonth(actualMonth - 1), com: (minusOne) ? parseFloat(minusOne).toFixed(2) : "0" },
    { name: getMonth(actualMonth), com: (actual) ? parseFloat(actual).toFixed(2) : "0" }
  ]*/

  const chartData = {
    data: {
      labels: [getMonth(actualMonth - 2), getMonth(actualMonth - 1), getMonth(actualMonth)],
      series: [[firstMonth, secondMonth, thirdMonth]],
    },
    options: {
      axisX: {
        showGrid: true
      }
    },
    animation: {
      draw: function (data) {
        if (data.type === "bar") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * 80,
              dur: 500,
              from: 0,
              to: 1,
              easing: "ease"
            }
          });
        }
      }
    }
  };

  const getProvisionsTable = () => {
    return (
      <Grid container spacing={2}>
        <Grid item sm={12}>
          {getMonth(actualMonth)}
          <Box className={classes.floatRight}>
            {thirdMonth} €
          </Box>
        </Grid>
        <Grid item sm={12}>
          {getMonth(actualMonth - 1)}
          <Box className={classes.floatRight}>
            {secondMonth} €
          </Box>
        </Grid>
        <Grid item sm={12}>
          {getMonth(actualMonth - 2)}
          <Box className={classes.floatRight}>
            {firstMonth} €
          </Box>
        </Grid>
      </Grid>
    )
  }
  return (
    <ChartCard
      color="primary"
      chartData={chartData}
      title={intl.formatMessage({ id: "dashboardCard.commissions.title" })}
      type="Bar"
      className={classes.cardStyle}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          {getProvisionsTable()}
        </Grid>
      </Grid>
      <hr />
      <Grid container >
        <Grid item sm={12}>
          <span><InfoIcon color='info' className={classes.iconAlign} />
            {intl.formatMessage({ id: "dashboardCard.commissions.allCommissions" })}: {total} &euro;
          </span>
          {(commissionList) &&
            <Button
              className={classes.floatRight}
              size="sm"
              round
              color="info"
              onClick={() => history.push({
                pathname: `/admin${generatePath(paths.commissions)}`
              })}
            >
              {intl.formatMessage({ id: "dashboardCard.commissions.button" })}
            </Button>
          }
        </Grid>
      </Grid>

    </ChartCard>
  )
}

export default injectIntl(CommissionsCard);
