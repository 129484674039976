import gql from 'graphql-tag';

const ALL_OPERATIONS = gql`
query operationsTable(
    $offset: Int
    $limit: Int
    $sort: [OperationsTableSortInput!]
    $filter: [OperationsTableFilterInput]
  ) {
    operationsTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
      totalCount,
      items {
        id
        bid
        type
        amount
        completedAt
        sourceId
        sourceBid
        sourceType
        targetId
        targetBid
        targetType
      }
    }
  }
`;

export default ALL_OPERATIONS;