import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Business from "@material-ui/icons/Business";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import Button from "components/CustomButtons/Button";
import { generatePath } from "react-router-dom";
import paths from "paths";

const useStyles = makeStyles((theme) => styles(theme));

const CompanyCard = (props) => {
    const {
        intl,
        history,
        companyName = '',
        companyId = 0,
        companyAddress = '',
        companyPostal = '',
        companyCity = '',
        companyContact = '',
        companyMail = '',
        companyPhone = '',
        companyDetail
    } = props;
    const classes = useStyles();
    const renderCompanyDetailsButton = () => {
        if (companyDetail)
            return (
                <>
                    <hr />
                    <Button
                        className={classes.floatRight}
                        color="info"
                        size="sm"
                        round
                        onClick={() => { history.push(`/admin${generatePath(paths.companies.detail, { companyId: companyId, })}`) }}
                    >
                        {intl.formatMessage({ id: 'campaigns.companyCard.details.button' })}
                    </Button>
                </>
            )
    }

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.companyCard.title' })}
            icon={<Business />}
        >
            <>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.companyCard.name' })}
                        <Box className={classes.floatRight}>
                            {(companyName) ? companyName : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.companyCard.address' })}
                        <Box className={classes.floatRight}>
                            {(!companyAddress && !companyPostal && !companyCity) && "-"}
                            {(companyAddress) ? companyAddress + ", " : ""}
                            {(companyPostal) ? companyPostal + ", " : ""}
                            {(companyCity) ? companyCity : ""}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.companyCard.contact' })}
                        <Box className={classes.floatRight}>
                            {(companyContact) ? companyContact : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.companyCard.email' })}
                        <Box className={classes.floatRight}>
                            {(companyMail) ? companyMail : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.companyCard.phone' })}
                        <Box className={classes.floatRight}>
                            {(companyPhone) ? companyPhone : "-"}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    {renderCompanyDetailsButton()}
                </Grid>
            </>
        </IconCard>
    </>
};

export default injectIntl(CompanyCard);
