import { Grid } from "@material-ui/core";
import Favorite from "@material-ui/icons/Favorite";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import FormControl from "@material-ui/core/FormControl";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import { useQuery } from "@apollo/react-hooks";
import MEDIAS_BY_COMPANY from "queries/StoresQueries/mediasByCompany";

import { pathOr } from "rambda";

const SpotsCard = (props) => {
    const {
        intl,
        callback,
        spType,
        internal,
        compId,
    } = props;

    let spotTypeFilter
    if (spType) {
        if (spType === "Announcement")
            spotTypeFilter = "Announcement"
        else
            spotTypeFilter = "InternalSpot"
    }
    else {
        if (internal)
            spotTypeFilter = "InternalSpot"
        else
            spotTypeFilter = "CommercialSpot"
    }


    // let companyId=6 //TEMPORARY

    const { data: mediasData } = useQuery(MEDIAS_BY_COMPANY(compId),
        {
            variables: {
                filter: [
                    {
                        category: {
                            eq: spotTypeFilter
                        },
                        acceptanceStatus: {
                            eq: "Approved"
                        }
                    }

                ]
            },
            skip: !compId
        }
    );


    const getMediasOptions = () => {
        const mediasOptions = [];
        pathOr([], ['mediasByCompany', 'items'], mediasData).map(media => {
            mediasOptions.push({
                label: media.name,
                value: media.id
            })
            return ""
        })
        return mediasOptions
    }

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.spotsCard.title' })}
            icon={<Favorite />}
        >
            <>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {compId &&
                            <FormControl fullWidth>
                                <AutocompleteSelect
                                    placeholder={intl.formatMessage({ id: 'campaigns.spotsCard.chooseSpots' })}
                                    isMulti
                                    //value={spotsVal}
                                    onChange={(e) => callback(e)}
                                    options={getMediasOptions()}
                                />
                            </FormControl>
                        }


                    </Grid>
                </Grid>
            </>
        </IconCard>
    </>
};

export default injectIntl(SpotsCard);
