import React from 'react';
import { injectIntl } from 'react-intl';
import { DateTime } from "luxon";
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import { makeStyles } from '@material-ui/core/styles';
import { invoiceDetail, payoutDetail} from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme))

export const InvoicesTable = (props) => {
    const classes = useStyles();

    const { history, data, loadData, intl, defaultFilter = null, queryDataPath, list, toggle } = props

    const onCompanyDetail = (id, type) => {
        history.push({
            pathname: `/admin${generatePath(paths.invoices.detail, { invoiceId: id })}`,
            state: type
        });
    }

    const getColumns = () => {
        let col = (list === "invoicesList")
            ?
            [
                {
                    Header: intl.formatMessage({ id: `${list}.table.ID` }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.identifier' }),
                    accessor: "identifier",
                    sortKey: "identifier",
                    filterKey: 'identifier',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.company' }),
                    accessor: "company",
                    sortKey: "company",
                    filterKey: 'company',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.description' }),
                    accessor: 'description',
                    sortKey: "description",
                    filterKey: "description",
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.price' }),
                    accessor: (rowData) => rowData.price + " €",
                    sortKey: "price",
                    filterKey: "price",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.credit' }),
                    accessor: (rowData) => rowData.credit + " c",
                    sortKey: "credit",
                    filterKey: "credit",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.date' }),
                    accessor: (rowData) => rowData.createdAt !== null
                        ? DateTime.fromISO(rowData.createdAt).toFormat("dd.MM.yyyy")
                        : "",
                    sortKey: "createdAt",
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.createdBy' }),
                    accessor: 'createdBy',
                    sortKey: "createdBy",
                    filterKey: "createdBy",
                    filterOperator: 'contains'
                },
                (invoiceDetail) ?
                    {
                        Header: intl.formatMessage({ id: 'companyList.table.actions' }),
                        accessor: (rowData) => <Button round className={classes.tenantButtons} size="sm" color="info"
                            onClick={() => onCompanyDetail(rowData.id, "invoice")}>{intl.formatMessage({ id: 'companyList.table.actions.detail' })}</Button>
                    } : { Header: " " }
            ]
            :
            [
                {
                    Header: intl.formatMessage({ id: `${list}.table.ID` }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.company' }),
                    accessor: "company",
                    sortKey: "company",
                    filterKey: 'company',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.description' }),
                    accessor: 'description',
                    sortKey: "description",
                    filterKey: "description",
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.price' }),
                    accessor: (rowData) => rowData.price + " €",
                    sortKey: "price",
                    filterKey: "price",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.credit' }),
                    accessor: (rowData) => rowData.credit + " C",
                    sortKey: "credit",
                    filterKey: "credit",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.date' }),
                    accessor: (rowData) => rowData.createdAt !== null
                        ? DateTime.fromISO(rowData.createdAt).toFormat("dd.MM.yyyy")
                        : "",
                    sortKey: "createdAt",
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'invoicesList.table.createdBy' }),
                    accessor: 'createdBy',
                    sortKey: "createdBy",
                    filterKey: "createdBy",
                    filterOperator: 'contains'
                },
                (payoutDetail) ?
                    {
                        Header: intl.formatMessage({ id: 'companyList.table.actions' }),
                        accessor: (rowData) => <Button round className={classes.tenantButtons} size="sm" color="info"
                            onClick={() => onCompanyDetail(rowData.id, "payout")}>{intl.formatMessage({ id: 'companyList.table.actions.detail' })}</Button>
                    } : { Header: " " }
            ]
        return col
    }

    return (
        <QueryTableRefresh
            data={data}
            loadData={loadData}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            toggle={toggle}
            columns={getColumns()}
        />
    )
}

export default injectIntl(InvoicesTable);