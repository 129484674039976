import gql from 'graphql-tag';

const ALL_INVOICES = gql`
query InvoicesTable(
    $offset: Int, 
    $limit: Int, 
    $sort: [InvoicesTableSortInput!], 
    $filter: [InvoicesTableFilterInput]) {
      invoicesTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
          totalCount
          items {
              id
              bid
              identifier
              description
              credit
              price
              company
              createdAt
              createdBy
          }
      }
  }
`;

export default ALL_INVOICES;