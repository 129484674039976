import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import paths from "paths";
import { pathOr } from "rambda";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { isInRoles } from "helpers/helpers";
import { makeStyles } from "@material-ui/core/styles";
import RadioIcon from '@material-ui/icons/Radio';
import RoleEnum from "common/enums/RoleEnum";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import IconCard from "components-lib/IconCard/IconCard";
import Modal from "components-lib/Modal/Modal";
import PageHeader from "components-lib/PageHeader/PageHeader";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import Input from "@material-ui/core/Input";
import styles from "common/styles/widgets.js";
import DevicesTable from "./DevicesTable";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ALL_STORES from "queries/StoresQueries/allStores";
import DEVICE_ASSIGN_ZONE from "queries/DevicesQueries/deviceAssignZone";
import DEVICES_QUERY_DISABLED from "queries/DevicesQueries/getDevicesDisabled";
import DEVICES_QUERY_ENABLED from "queries/DevicesQueries/getDevicesEnabled";
import DEVICES_QUERY from 'queries/DevicesQueries/getDevices';
import DEVICE_DISCOVER from 'queries/DevicesQueries/deviceDiscover';
import { deviceList, deviceDetail, storeList, deviceDisc, deviceAssign } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme));

const DevicesListPage = (props) => {
    const { intl, history } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [deviceEnable] = useMutation(DEVICE_ASSIGN_ZONE);
    const [deviceDiscover] = useMutation(DEVICE_DISCOVER);
    const { data: storesData } = useQuery(ALL_STORES, { skip: (!deviceAssign, !storeList) });
    const { data: devData } = useQuery(DEVICES_QUERY,
        {
            variables: {
                filter: [
                    {
                        zone: {
                            isNull: true
                        }
                    }
                ]
            }
        },
        { skip: !deviceDetail }
    );

    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [deviceId, setDeviceId] = useState(-1);
    const [storeId, setStoreId] = useState(-1);
    const [stores, setStores] = useState([]);
    const [avDevices, setAvDevices] = useState({});
    const [activeState, setActiveState] = useState(0);
    const [discoverResponse, setDiscoverResponse] = useState([]);
    const [discoverModalOpen, setDiscoverModalOpen] = useState(false);

    useEffect(() => {
        let deviceSelectItems = pathOr([], 'devices.items', devData).map((device) => {
            return {
                value: pathOr('', 'id', device),
                label: `${pathOr('', 'id', device)} - ${pathOr('', 'bid', device)}`
            }
        })
        setAvDevices(deviceSelectItems);
    }, [devData]);

    useEffect(() => {
        let storesSelectItems = pathOr([], "storesTable.items", storesData).map(
            (store) => {
                return {
                    value: pathOr("", "id", store),
                    label: `${pathOr("", "id", store)} - ${pathOr(
                        "",
                        "name",
                        store
                    )}`,
                };
            }
        );
        setStores(storesSelectItems);
    }, [storesData]);

    const assignDevice = () => {
        deviceEnable({
            variables: {
                id: deviceId,
                zoneId: storeId,
            },
        }).then((response) => {
            history.push(`/admin${generatePath(paths.devices.detail, { deviceId: deviceId })}`);
            enqueueSnackbar(intl.formatMessage({ id: "deviceDetail.player.assignPlayerSuccessful" }), { variant: "success", });
        }).catch((err) => {
            console.log('[error]', err)
        });
        setAssignModalOpen(false);
    };

    const discoverDevice = () => {
        deviceDiscover()
            .then((response) => {
                setDiscoverResponse(response.data.deviceDiscover)
                setDiscoverModalOpen(true);
                enqueueSnackbar(intl.formatMessage({ id: "deviceDetail.success.browseStock" }), { variant: "success", });
            }).catch((err) => {
                console.log('[error]', err)
            });
    };

    const renderModalButtons = () => {
        return (
            <>
                <Button
                    disabled={(storeId === -1 || deviceId === -1)}
                    onClick={() => assignDevice()}
                    color="success"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "deviceList.assignModal.assign" })}
                </Button>
                <Button
                    onClick={() => setAssignModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "deviceList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    const renderDiscoverModalButtons = () => {
        return (
            <Button
                onClick={() => setDiscoverModalOpen(false)}
                color="success"
                round
                size="sm"
            >
                ok
            </Button>
        );
    };

    const getActions = () => {
        if (activeState === 0) {
            if (deviceAssign && storeList && deviceDetail)
                return [
                    {
                        title: intl.formatMessage({ id: "deviceList.assignDevice" }),
                        onClick: () => setAssignModalOpen(true),
                    },
                ];
        }
        else {
            if (deviceDisc)
                return [
                    {
                        title: intl.formatMessage({ id: "deviceList.discoverDevice" }),
                        onClick: () => discoverDevice(),
                    },
                ];
        }
    };

    const renderTables = () => {
        return (
            <>
                {deviceDisc ?
                    <NavPills
                        color="info"
                        active={activeState}
                        callback={(newValue) => setActiveState(newValue)}
                        tabs={[
                            {
                                tabButton: intl.formatMessage({ id: "deviceList.enabledToggle" }),
                                tabContent: (
                                    <DevicesTable
                                        defaultFilter={{ isAssigned: { eq: true } }}
                                        history={history}
                                        query={DEVICES_QUERY_ENABLED}
                                    />
                                ),
                            },
                            {
                                tabButton: intl.formatMessage({ id: "deviceList.disabledToggle" }),
                                tabContent: (
                                    <DevicesTable
                                        defaultFilter={{ isAssigned: { eq: false } }}
                                        history={history}
                                        disabled={true}
                                        query={DEVICES_QUERY_DISABLED}
                                    />
                                ),
                            },
                        ]}
                    />
                    :
                    <DevicesTable
                        defaultFilter={{ isAssigned: { eq: true } }}
                        history={history}
                        query={DEVICES_QUERY_ENABLED}
                    />
                }
            </>
        );
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "deviceList.title" })}
                actions={getActions()}
                withBackButton={false}
            />
            <IconCard
                icon={<RadioIcon />}
                title={intl.formatMessage({ id: "deviceList.table.title" })}
            >
                {renderTables()}
            </IconCard>
            <Modal
                title={intl.formatMessage({ id: "deviceList.assignModal.title" })}
                open={assignModalOpen}
                onClose={() => setAssignModalOpen(false)}
                actions={renderModalButtons()}
            >
                <Grid container className={classes.selectStyle} spacing={2}>
                    <Grid item sm={12}>
                        <AutocompleteSelect
                            placeholder={intl.formatMessage({ id: "deviceList.assignModal.device" })}
                            label={intl.formatMessage({ id: "deviceDetail.title" })}
                            options={avDevices}
                            onChange={(item) => setDeviceId(item.value)}
                            input={<Input />}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <AutocompleteSelect
                            label={intl.formatMessage({ id: "deviceList.table.store" })}
                            placeholder={intl.formatMessage({ id: "deviceList.assignModal.store", })}
                            options={stores}
                            onChange={(item) => setStoreId(item.value)}
                            input={<Input />}
                        />
                    </Grid>
                </Grid>
            </Modal>
            <Modal
                title={intl.formatMessage({ id: "deviceList.discoverModal.title" })}
                open={discoverModalOpen}
                onClose={() => setDiscoverModalOpen(false)}
                actions={renderDiscoverModalButtons()}
            >
                {(discoverResponse?.length !== 0) ?
                    <>
                        {intl.formatMessage({ id: "deviceList.discoverModal.textYes" }, [discoverResponse.length])}
                        <hr />
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>#</b></TableCell>
                                    <TableCell><b>ID</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {discoverResponse.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell>{row.bid}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                    :
                    <>{intl.formatMessage({ id: "deviceList.discoverModal.textNo" })}</>
                }
            </Modal>
        </>
    );
};

export default withRouter(injectIntl(DevicesListPage));
