import gql from 'graphql-tag';

const STORE_CREATE = gql`
    mutation storeCreate($input: StoreCreateInput!){
        storeCreate(input: $input){
            id
            name
            address
            postal
            city
            country
            contactFirstName
            contactLastName
            contactPhone
            contactEmail
            zones{
                preferences{
                    segment{
                        id
                    }
                }
            }
        }
    }
`;

export default STORE_CREATE;