import QueryTable from 'components-lib/QueryTable/QueryTable';
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import { invoiceDetail, companyDetail, commercialCampaignDetail, payoutDetail, userDetail } from 'configFiles/roles'

export const OperationsTable = (props) => {

    const { history, query, intl, defaultFilter, orFilter, queryDataPath = ['operationsTable', 'items'] } = props
    const { enqueueSnackbar } = useSnackbar();

    const onDetail = (id, type) => {
        if (type === "Company") {
            (companyDetail)
                ? history.push(`/admin${generatePath(paths.companies.detail, { companyId: id })}`)
                : enqueueSnackbar(intl.formatMessage({ id: 'operationsList.table.notPermission' }), { variant: "error" });
        } else if (type === "CommercialCampaign") {
            (commercialCampaignDetail)
                ? history.push({
                    pathname: `/admin${generatePath(paths.campaigns.commercialDetail, { campaignId: id })}`,
                    state: "freephoto"
                })
                : enqueueSnackbar(intl.formatMessage({ id: 'operationsList.table.notPermission' }), { variant: "error" });
        } else if (type === "Invoice") {
            (invoiceDetail)
                ? history.push({
                    pathname: `/admin${generatePath(paths.invoices.detail, { invoiceId: id })}`,
                    state: "invoice"
                })
                : enqueueSnackbar(intl.formatMessage({ id: 'operationsList.table.notPermission' }), { variant: "error" });
        } else if (type === "Payout") {
            (payoutDetail)
                ? history.push({
                    pathname: `/admin${generatePath(paths.invoices.detail, { invoiceId: id })}`,
                    state: "payout"
                })
                : enqueueSnackbar(intl.formatMessage({ id: 'operationsList.table.notPermission' }), { variant: "error" });
        } else if (type === "User") {
            (userDetail)
                ? history.push(`/admin${generatePath(paths.users.detail, { userId: id })}`)
                : enqueueSnackbar(intl.formatMessage({ id: 'operationsList.table.notPermission' }), { variant: "error" });
        } else
            enqueueSnackbar(intl.formatMessage({ id: 'operationsList.table.notPermission' }), { variant: "error" });
    }

    return (
        <QueryTable
            query={query}
            permanentFilter={defaultFilter}
            orFilter={orFilter}
            queryDataPath={queryDataPath}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.date' }),
                    accessor: (rowData) => new Date(rowData.completedAt).toLocaleString('sk'),
                    sortKey: "completedAt",
                    filterKey: "completedAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.sourceID' }),
                    accessor: (rowData) => <span
                        style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => onDetail(rowData.sourceId, rowData.sourceType, rowData.sourceBid)} >
                        {rowData.sourceBid}</span>,
                    sortKey: 'sourceBid',
                    filterKey: 'sourceBid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.operation' }),
                    accessor: "type",
                    sortKey: 'type',
                    filterKey: 'type',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: 'Income',
                            label: 'Income'
                        },
                        {
                            value: 'Outcome',
                            label: 'Outcome'
                        },
                        {
                            value: 'Transfer',
                            label: 'Transfer'
                        }
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.destinationID' }),
                    accessor: (rowData) => <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => onDetail(rowData.targetId, rowData.targetType, rowData.targetBid)}>
                        {rowData.targetBid}</span>,
                    sortKey: "targetBid",
                    filterKey: "targetBid",
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'operationsList.table.price' }),
                    accessor: (rowData) => rowData.amount + " C",
                    sortKey: "amount",
                    filterKey: "amount",
                    filterOperator: 'eq',
                    filterDataType: 'number',
                }
            ]}
        />
    )
}

export default injectIntl(OperationsTable);