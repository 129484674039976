import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import {
  getErrorMessageByErrorCode,
  getUserToken,
  processLogout,
} from "../helpers/helpers";
import { onError } from "apollo-link-error";
import { pathOr } from "rambda";
import paths from "../paths";
import { getAPIUrl } from "./config";

export const getApolloClient = (enqueueSnackbar, history, intl) => {
  const cache = new InMemoryCache();
  const httpLink = new HttpLink({
    uri: getAPIUrl(),
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        const errorCode = pathOr("", ["extensions", "code"], error);
        enqueueSnackbar(getErrorMessageByErrorCode(errorCode, intl), {
          variant: "error",
        });

        if (errorCode === "UnauthorizedUserError") {
          processLogout();
          history.push(paths.auth.login);
        }
        return ""
      });
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getUserToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token || "",
      },
    };
  });

  const client = new ApolloClient({
    // Provide required constructor fields
    cache: cache,
    link: ApolloLink.from([authLink, errorLink, httpLink]),

    // Provide some optional constructor fields
    name: "react-web-client",
    version: "1.3",
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "none",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "none",
      },
      mutate: {
        errorPolicy: "none",
      },
    },
  });

  return client;
};
