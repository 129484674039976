import React from "react";
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import paths from "paths";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import WarningIcon from '@material-ui/icons/Warning';
import IconCard from "components-lib/IconCard/IconCard";
import Button from "components/CustomButtons/Button";
import styles from "common/styles/widgetStyle.js";
import { commercialCampaignList, internalCampaignList } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme));

const CampaignsCard = (props) => {

    const {
        history,
        intl,
        allCommercialCampaigns,
        awaitingCommercialCampaigns,
        allInternalCampaigns,
        awaitingInternalCampaigns
    } = props;

    const classes = useStyles();

    const renderDetailButtons = () => {
        return (
            <Box>
                {commercialCampaignList &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.campaigns.commercial)}`
                        })}
                    >
                        {intl.formatMessage({ id: "dashboardCard.commercialCampaigns.button" })}
                    </Button>
                }
                {internalCampaignList &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.campaigns.internal)}`
                        })}
                    >
                        {intl.formatMessage({ id: "dashboardCard.internalCampaigns.button" })}
                    </Button>
                }
            </Box>
        )
    }

    const renderAwaitingCampaigns = (commercial) => {

        let campaign = (commercial) ? awaitingCommercialCampaigns : awaitingInternalCampaigns
        if (campaign > 0) {
            return (
                <div style={{ color: '#EE9600' }}>
                    {(commercial)
                        ? intl.formatMessage({ id: "dashboardCard.campaigns.awaitingCommercialCampaigns" })
                        : intl.formatMessage({ id: "dashboardCard.campaigns.awaitingInternalCampaigns" })
                    }
                    <Box className={classes.floatRight} >
                        <WarningIcon style={{ fontSize: 18 }} className={classes.warningIcon} /><span>{(commercial) ? awaitingCommercialCampaigns : awaitingInternalCampaigns}</span>
                    </Box>
                </div>
            )
        }
        return <>
            {(commercial)
                ? intl.formatMessage({ id: "dashboardCard.campaigns.awaitingCommercialCampaigns" })
                : intl.formatMessage({ id: "dashboardCard.campaigns.awaitingInternalCampaigns" })
            }
            <Box className={classes.floatRight}>
                {awaitingCommercialCampaigns}
            </Box>
        </>
    }

    return <>
        <IconCard
            title={intl.formatMessage({ id: "dashboardCard.campaigns.title" })}
            icon={<AddToPhotosIcon />}
            height="80%"
            actions={renderDetailButtons()}
        >
            <Grid container spacing={2}>
                {commercialCampaignList &&
                    <>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "dashboardCard.campaigns.allCommercialCampaigns" })}
                            <Box className={classes.floatRight}>
                                {allCommercialCampaigns}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {renderAwaitingCampaigns(true)}
                        </Grid>
                    </>
                }
                {internalCampaignList &&
                    <>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "dashboardCard.campaigns.allInternalCampaigns" })}
                            <Box className={classes.floatRight}>
                                {allInternalCampaigns}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {renderAwaitingCampaigns()}
                        </Grid>
                    </>
                }
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(CampaignsCard);
