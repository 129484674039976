import * as Yup from 'yup';

export const getValidationSchemaInternal = (intl) =>
    Yup.object().shape({
        campaign: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            companyId: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            validFrom: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            validTo: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            minSpotInterval: Yup.number()
                .min(0, (intl.formatMessage({ id: 'validation.number.min' }, [3])))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            description: Yup.string()
                .max(100, intl.formatMessage({ id: 'validation.string.max' }, [100]))
                .nullable()
        }),
        //spotType: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    })


export const getValidationSchemaCommercial = (intl, parsed) =>
    Yup.object().shape({
        campaign: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            companyId: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            validFrom: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            validTo: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            maxBid: Yup.number()
                .min(parsed, (intl.formatMessage({ id: 'validation.number.min' }, [parsed])))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            budget: Yup.number()
                .min(1, (intl.formatMessage({ id: 'validation.number.min' }, [1])))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            minSpotInterval: Yup.number()
                .min(0, (intl.formatMessage({ id: 'validation.number.positive' })))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            description: Yup.string()
                .max(100, intl.formatMessage({ id: 'validation.string.max' }, [100]))
                .nullable()

        })
    })

export const getValidationSchemaDetailInternal = (intl, validFrom) =>
    Yup.object().shape({
        internalCampaign: Yup.object().shape({
            //validFrom: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            minSpotInterval: Yup.number()
                .min(0, (intl.formatMessage({ id: 'validation.number.positive' })))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            description: Yup.string()
                .max(100, intl.formatMessage({ id: 'validation.string.max' }, [100]))
                .nullable(),
            validTo: Yup.date()
                .required(intl.formatMessage({ id: 'validation.required' }))
                .typeError(intl.formatMessage({ id: 'validation.date' }))
                .min(validFrom, intl.formatMessage({ id: 'validation.graterDate' })).nullable(),

        })
    })

export const getValidationSchemaDetailCommercial = (intl, parsed) =>
    Yup.object().shape({
        commercialCampaign: Yup.object().shape({
            //validFrom: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            maxBid: Yup.number()
                .min(parsed, (intl.formatMessage({ id: 'validation.number.min' }, [parsed])))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            minSpotInterval: Yup.number()
                .min(0, (intl.formatMessage({ id: 'validation.number.positive' })))
                .typeError(intl.formatMessage({ id: 'validation.number' }))
                .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
            description: Yup.string()
                .max(100, intl.formatMessage({ id: 'validation.string.max' }, [100]))
                .nullable(),
            validFrom: Yup.date()
                .required(intl.formatMessage({ id: 'validation.required' }))
                .typeError(intl.formatMessage({ id: 'validation.date' }))
                .nullable(),
            validTo: Yup.date()
                .required(intl.formatMessage({ id: 'validation.required' }))
                .typeError(intl.formatMessage({ id: 'validation.date' }))
                .min(Yup.ref('validFrom'), intl.formatMessage({ id: 'validation.graterDate' })).nullable(),

        })
    })

