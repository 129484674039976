import { useMutation, useQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import Button from "components/CustomButtons/Button.js";
import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import React, { useState, useEffect } from "react";
import { injectIntl } from 'react-intl';
import { generatePath, withRouter } from "react-router-dom";
import paths from 'paths';
import SelectCompanyCard from './StoreCards/SelectCompanyCard';
import MaintainerCard from './StoreCards/MaintainerCard';
import BasicInfoCard from './StoreCards/BasicInfoCard';
import AddressCard from './StoreCards/AddressCard';
import STORE_CREATE from 'queries/StoresQueries/storeCreate';
import { getNewValidationSchema } from "./validationSchema";
import { storeCreate } from 'configFiles/roles'
import STORE_DETAIL from "queries/StoresQueries/storeDetail";
import { useLocation } from 'react-router-dom';
import ALL_STREAM_NAMES from "queries/StoresQueries/allStreamNames";

const StoreNewPage = (props) => {
    const { history, intl } = props;

    const { enqueueSnackbar } = useSnackbar();
    const [createStore] = useMutation(STORE_CREATE);
    const [city, setCity] = useState([])
    const [availableStreams, setAvailableStreams] = useState([])

    let location = useLocation();
    let storeData = location.state ? location.state : null
    const { data: allStreamsData } = useQuery(ALL_STREAM_NAMES, { skip: !storeCreate })

    useEffect(() => {
        let streamsObj = pathOr([], ['streams', 'items'], allStreamsData).map((stream) => {
            return {
                value: stream.id,
                label: stream.name
            }
        })
        setAvailableStreams(streamsObj);
    }, [allStreamsData])

    console.log(availableStreams?.[0]?.value)

    let userData = (storeData) ? (
        {
            contactFirstName: `${pathOr('', ["store", 'contactFirstName'], storeData)}`,
            contactLastName: `${pathOr('', ["store", 'contactLastName'], storeData)}`,
            contactPhone: `${pathOr('', ["store", 'contactPhone'], storeData)}`,
            contactEmail: `${pathOr('', ["store", 'contactEmail'], storeData)}`,
            companyId: `${pathOr('', ["store", 'company', 'id'], storeData)}`,
            country: `${pathOr('', ["store", 'country'], storeData)}`,
            zones: {
                0: {
                    area: `${pathOr('', ["store", 'zones', [0], 'area'], storeData)}`,
                    visitors: `${pathOr('', ["store", 'zones', [0], 'visitors'], storeData)}`,
                    preferences: {
                        segment: {
                            id: `${pathOr('', ["store", 'zones', [0], 'preferences', 'segment', 'id'], storeData)}`,
                        }
                    }
                }
            }


            //segment: storeData?.store?.zones?.[0]?.preferences?.segmet?.id }
        }
    ) : (
        {
            contactEmail: '@',
        }
    );

    const handleCreateStore = (values) => {

        console.log("values", values)
        const formData = { ...values };
        delete formData.area;
        delete formData.visitors;
        delete formData.zones;
        delete formData.segmentId;
        delete formData.latitude;
        delete formData.longtitude;

        const parseFloatArea = parseFloat(values.area);
        const parseFloatVisitors = parseFloat(values.visitors)
        let times = [
            { weekDay: "Monday", from: "00:00", to: "23:59" },
            { weekDay: "Tuesday", from: "00:00", to: "23:59" },
            { weekDay: "Wednesday", from: "00:00", to: "23:59" },
            { weekDay: "Thursday", from: "00:00", to: "23:59" },
            { weekDay: "Friday", from: "00:00", to: "23:59" },
            { weekDay: "Saturday", from: "00:00", to: "23:59" },
            { weekDay: "Sunday", from: "00:00", to: "23:59" }
        ]
        const parseSegment = values.segmentId
        let setCityLabel = city.label;
        const result = {
            ...formData,
            area: parseFloatArea,
            visitors: parseFloatVisitors,
            latitude: Number(values.latitude),
            longtitude: Number(values.longtitude),
            city: setCityLabel,
            preferences: {
                segmentId: parseSegment,
                activeTimes: times,
                musicPlaybackOrder: "Random",
                musicStreamId: (availableStreams?.[0]?.value) ? Number(availableStreams?.[0]?.value) : undefined
            }
        }
        createStore({
            variables: {
                input: { ...result }
            }
        }).then((response) => {
            const companyName = pathOr('', ['data', 'storeCreate', 'company.name'], response);
            const id = pathOr('', ['data', 'storeCreate', 'id'], response);
            enqueueSnackbar(intl.formatMessage({ id: 'storeDetail.create.successful' }), { variant: 'success' });
            history.push(`/admin${generatePath(paths.stores.detail, { storeId: id })}`)
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: 'storeList.addStore.title' })}
                handleBackAction={(e) => history.goBack()}
            />
            <Formik
                initialValues={userData}
                onSubmit={(values) => { handleCreateStore(values); }}
                validationSchema={getNewValidationSchema(intl)}
            >
                {(formikProps) =>
                    <>
                        <Grid container spacing={3}>
                            <Grid item md={6} lg={4}>
                                <BasicInfoCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    storeUpdate={storeCreate}
                                    storeCreate={true}
                                />
                                <MaintainerCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    storeUpdate={storeCreate}
                                    storeCreate={true}
                                />
                                <AddressCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    callbackCity={(newValue) => setCity(newValue)}
                                    storeUpdate={storeCreate}
                                    storeCreate={true}

                                />
                            </Grid>
                            <Grid item md={6} lg={4}>
                                <SelectCompanyCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    storeUpdate={storeCreate}
                                    storeCreate={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <div style={{ position: "fixed", bottom: "20px", right: "20px", }} >
                                    <Button
                                        round
                                        size="medium"
                                        color="primary"
                                        type="submit"
                                        onClick={() => formikProps.handleSubmit()}
                                    >
                                        {intl.formatMessage({ id: 'createUser.submit' })}
                                    </Button>
                                    <Button
                                        round
                                        size="medium"
                                        onClick={(e) => history.push(`/admin${paths.stores.list}`)}
                                    >
                                        {intl.formatMessage({ id: 'createUser.cancel' })}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                }
            </Formik>
        </>
    );
};

export default withRouter(injectIntl(StoreNewPage));