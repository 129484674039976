import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react'
import Datetime from "react-datetime";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddIcon from '@material-ui/icons/Add';
import { injectIntl } from "react-intl";
import STORE_DETAIL from "queries/StoresQueries/storeDetail";
import { useQuery } from "@apollo/react-hooks";
import { pathOr } from "rambda";
import moment from 'moment';
import styles from "common/styles/widgets.js";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => styles(theme));

const InheritTimes = (props) => {

  const {
    intl,
    callback,
    storeId,
    storeUpdate,
    helperText,
    multipleUpdate
  } = props;
  const { data: storeData } = useQuery(STORE_DETAIL, { variables: { id: storeId } });
  const classes = useStyles();

  const [rows, setRows] = useState([])
  useEffect(() => {
    if (!multipleUpdate) {
      setRows(((Object.values(pathOr([], ['store', 'zones', [0], 'preferences', 'inactiveTimes'], storeData))?.map(item =>
      (
        {
          from: moment(item?.from),
          to: moment(item?.to)
        }

      )))))
    }
  }, [storeData]);

  const handleChangeFrom = idx => value => {
    const row = [...rows];
    row[idx] = {
      from: value,
      to: row[idx].to,
    };
    setRows(row);
    callback(row)
  };

  const handleChangeTo = idx => value => {
    const row = [...rows];
    row[idx] = {
      from: row[idx].from,
      to: value
    };

    setRows(row);
    callback(row)
  };

  const handleAddRow = () => {
    const item = {
      from: undefined,
      to: undefined
    };
    setRows([...rows, item]);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const row = [...rows]
    row.splice(idx, 1)
    console.log(row)
    setRows(row)
    callback(row)
  }
  return (

    <div>
      {(storeUpdate) ?
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center">  </th>
                    <th className="text-center"> {intl.formatMessage({ id: 'queryTable.filter.pickerStart' })} </th>
                    <th className="text-center"> {intl.formatMessage({ id: 'queryTable.filter.pickerEnd' })}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {rows.map((item, idx) => (
                    <tr id="addr0" key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        <Datetime
                          //type="datetime-local"
                          name="from"
                          value={rows[idx].from}
                          onChange={handleChangeFrom(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <Datetime
                          type="datetime-local"
                          name="to"
                          value={rows[idx].to}
                          onChange={handleChangeTo(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <DeleteRoundedIcon
                          size="sm"
                          className={classes.pointer}
                          onClick={handleRemoveSpecificRow(idx)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <AddIcon
                size="sm"
                onClick={handleAddRow}
                className={classes.pointer}
              />
            </div>
          </div>
          <Grid item sm={12}>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            <hr style={{ marginTop: "1px", marginBottom: "0px" }} />
          </Grid>
        </div>
        :
        <Grid container spacing={1}>
          {rows?.map((item, idx) => (
            <Grid item sm={12}>
              {console.log(item)}Od - Do
              <Box className={classes.floatRight}>
                {moment(item.from).format("DD.MM.YYYY, HH:mm")} -  {moment(item.to).format("DD.MM.YYYY, HH:mm")}
              </Box>
            </Grid>
          ))}
        </Grid>
      }
    </div>
  );
}


export default injectIntl(InheritTimes);
