import LoginPage from "views/Pages/LoginPage.js";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from '@material-ui/icons/Settings';
import paths from "paths";
import DashboardPage from "pages/Dashboard/DashboardPage";
import LogoutPage from "pages/Logout/LogoutPage";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SettingsPage from "pages/Settings/SettingsPage";
import GroupPage from "pages/Groups/GroupPage";
import DevicesListPage from "pages/Devices/DevicesListPage";
import DeviceDetailPage from "pages/Devices/DeviceDetailPage";
import CameraEnhance from '@material-ui/icons/CameraEnhance';
import ForgottenPassword from "./pages/ForgottenPassword/ForgottenPassword";
import UserListPage from "pages/Users/UserListPage";
import UserDetailPage from "pages/Users/UserDetailPage";
import ProfilePage from "pages/Profile/ProfilePage"
import UserNewPage from "pages/Users/UserNewPage";
import StoreListPage from 'pages/Stores/StoreListPage';
import StoreNewPage from 'pages/Stores/StoreNewPage';
import StoreDetailPage from 'pages/Stores/StoreDetailPage';
import CompanyListPage from 'pages/Companies/CompanyListPage';
import CompanyDetailPage from 'pages/Companies/CompanyDetailPage';
import CompanyNewPage from 'pages/Companies/CompanyNewPage';
import StreamListPage from "./pages/Streams/StreamListPage";
import MediaPage from "./pages/Media/MediaListPage";
import CommissionListPage from "pages/Commissions/CommissionListPage";
import OperationListPage from "pages/Operations/OperationListPage";
import StoreIcon from '@material-ui/icons/Store';
import RadioIcon from '@material-ui/icons/Radio';
import AddBoxIcon from '@material-ui/icons/AddBox';
import QueueIcon from '@material-ui/icons/Queue';
import BusinessIcon from '@material-ui/icons/Business';
import CommissionDetailPage from 'pages/Commissions/CommissionDetailPage';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import MusicVideoIcon from '@material-ui/icons/MusicVideo';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CampaignListPage from "./pages/Campaigns/CampaignListPage";
import CampaignNewPage from "./pages/Campaigns/CampaignNewPage";
import CampaignNewBidPage from "./pages/Campaigns/CampaignNewBidPage";
import CampaignDetailPage from "./pages/Campaigns/CampaignDetailPage";
import InvoiceListPage from "pages/Invoices/InvoiceListPage";
import InvoiceDetailPage from 'pages/Invoices/InvoiceDetailPage';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ChangeDashboard from "./pages/ChangeDashboard/ChangeDashboard";
import CampaignAxiosPage from "pages/Campaigns/CampaignAxiosPage";
import {
  companyList, companyDetail, companyCreate,
  deviceList, deviceDetail,
  storeList, storeCreate, storeDetail,
  commercialCampaignList, commercialCampaignCreate, commercialCampaignDetail,
  internalCampaignList, internalCampaignCreate, internalCampaignDetail,
  dashboardList, streamList, mediaList,
  userList, userDetail, userCreate,
  invoiceList, invoiceDetail,
  commissionList, commissionDetail,
  operationList, groupList, settingsDetail
} from 'configFiles/roles'


const getRoutes = (intl) => ([
  {
    path: paths.changeDashboard,
    name: intl.formatMessage({ id: 'changeDashboard.menu' }),
    icon: DoubleArrowIcon,
    changeDashboard: true,
    component: ChangeDashboard,
    layout: '/admin'
  },
  (dashboardList) ?
    {
      path: paths.dashboard,
      name: intl.formatMessage({ id: 'routes.dashboard' }),
      hideInMenu: (!dashboardList) && true,
      icon: DashboardIcon,
      component: DashboardPage,
      //decorate: true,
      layout: '/admin'
    } : { hideInMenu: true },

  (deviceList) ?
    {
      path: paths.devices.list,
      name: intl.formatMessage({ id: 'routes.devices' }),
      hideInMenu: (!deviceList) && true,
      icon: RadioIcon,
      component: DevicesListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (deviceDetail) ?
    {
      path: paths.devices.detail,
      name: intl.formatMessage({ id: 'routes.devices.detail' }),
      hideInMenu: true,
      icon: CameraEnhance,
      component: DeviceDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (storeList) ?
    {
      path: paths.stores.list,
      name: intl.formatMessage({ id: 'routes.stores' }),
      decorateSpecial: (!dashboardList && !deviceList) ? true : false,
      hideInMenu: (!storeList) && true,
      icon: StoreIcon,
      component: StoreListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (storeCreate) ?
    {
      path: paths.stores.new,
      name: intl.formatMessage({ id: 'routes.stores.new' }),
      hideInMenu: true,
      icon: StoreIcon,
      component: StoreNewPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (storeDetail) ?
    {
      path: paths.stores.detail,
      name: intl.formatMessage({ id: 'routes.stores.detail' }),
      hideInMenu: true,
      icon: StoreIcon,
      component: StoreDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (companyList) ?
    {
      path: paths.companies.list,
      name: intl.formatMessage({ id: 'routes.companies' }),
      hideInMenu: (!companyList) && true,
      icon: BusinessIcon,
      component: CompanyListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (companyDetail) ?
    {
      path: paths.companies.detail,
      name: intl.formatMessage({ id: 'routes.companies.detail' }),
      hideInMenu: true,
      icon: BusinessIcon,
      component: CompanyDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (companyCreate) ?
    {
      path: paths.companies.new,
      name: intl.formatMessage({ id: 'routes.companies.new' }),
      hideInMenu: true,
      icon: BusinessIcon,
      component: CompanyNewPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (commercialCampaignList) ?
    {
      path: paths.campaigns.commercial,
      name: intl.formatMessage({ id: 'routes.campaigns.commercial' }),
      hideInMenu: (!commercialCampaignList) && true,
      icon: AddBoxIcon,
      component: CampaignListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (commercialCampaignCreate) ?
    {
      path: paths.campaigns.commercialNew,
      hideInMenu: true,
      name: intl.formatMessage({ id: 'routes.campaigns.new' }),
      icon: AddBoxIcon,
      component: CampaignAxiosPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (commercialCampaignDetail) ?
    {
      path: paths.campaigns.commercialDetail,
      hideInMenu: true,
      name: intl.formatMessage({ id: 'routes.campaigns.detail' }),
      icon: AddBoxIcon,
      component: CampaignDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (internalCampaignList) ?
    {
      path: paths.campaigns.internal,
      name: intl.formatMessage({ id: 'routes.campaigns.internal' }),
      hideInMenu: (!internalCampaignList) && true,
      icon: QueueIcon,
      component: CampaignListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (internalCampaignCreate) ?
    {
      path: paths.campaigns.internalNew,
      hideInMenu: true,
      name: intl.formatMessage({ id: 'routes.campaigns.new' }),
      icon: AddBoxIcon,
      component: CampaignAxiosPage,
      layout: '/admin',
    } : { hideInMenu: true },
  (internalCampaignDetail) ?
    {
      path: paths.campaigns.internalDetail,
      hideInMenu: true,
      name: intl.formatMessage({ id: 'routes.campaigns.detail' }),
      icon: AddBoxIcon,
      component: CampaignDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (streamList) ?
    {
      path: paths.streams,
      name: intl.formatMessage({ id: 'routes.streams' }),
      hideInMenu: (!streamList) && true,
      icon: QueueMusicIcon,
      component: StreamListPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (mediaList) ?
    {
      path: paths.media,
      name: intl.formatMessage({ id: 'routes.media' }),
      hideInMenu: (!mediaList) && true,
      icon: MusicVideoIcon,
      component: MediaPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (userList) ?
    {
      path: paths.users.list,
      name: intl.formatMessage({ id: 'routes.users' }),
      hideInMenu: (!userList) && true,
      icon: AssignmentIndIcon,
      component: UserListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (userCreate) ?
    {
      path: paths.users.new,
      name: intl.formatMessage({ id: 'routes.users.new' }),
      component: UserNewPage,
      hideInMenu: true,
      layout: '/admin'
    } : { hideInMenu: true },
  (userDetail) ?
    {
      path: paths.users.detail,
      name: intl.formatMessage({ id: 'routes.users.detail' }),
      hideInMenu: true,
      component: UserDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (invoiceList) ?
    {
      path: paths.invoices.list,
      name: intl.formatMessage({ id: 'routes.invoices' }),
      hideInMenu: (!invoiceList) && true,
      icon: CreditCardIcon,
      component: InvoiceListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (invoiceDetail) ?
    {
      path: paths.invoices.detail,
      name: intl.formatMessage({ id: 'routes.invoices.detail' }),
      hideInMenu: true,
      icon: CreditCardIcon,
      component: InvoiceDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (commissionList) ?
    {
      path: paths.commissions.list,
      name: intl.formatMessage({ id: 'routes.commissions' }),
      hideInMenu: (!commissionList) && true,
      icon: EuroSymbolIcon,
      component: CommissionListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (commissionDetail) ?
    {
      path: paths.commissions.detail,
      name: intl.formatMessage({ id: 'routes.commissions.detail' }),
      hideInMenu: true,
      icon: CreditCardIcon,
      component: CommissionDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (operationList) ?
    {
      path: paths.operations.list,
      name: intl.formatMessage({ id: 'routes.operations' }),
      hideInMenu: (!operationList) && true,
      icon: SwapHorizIcon,
      component: OperationListPage,
      layout: '/admin'
    } : { hideInMenu: true },

  {
    path: paths.auth.login,
    name: intl.formatMessage({ id: 'routes.login' }),
    hideInMenu: true,
    component: LoginPage,
    layout: '/auth'
  },
  {
    path: paths.profile,
    name: intl.formatMessage({ id: 'routes.profile' }),
    decorate: true,
    icon: AccountCircleIcon,
    component: ProfilePage,
    layout: '/admin'
  },


  (groupList) ?
    {
      path: paths.groups.list,
      name: intl.formatMessage({ id: 'routes.groups' }),
      hideInMenu: (!groupList) && true,
      component: GroupPage,
      icon: GroupAddIcon,
      layout: '/admin'
    } : { hideInMenu: true },

  (settingsDetail) ?
    {
      path: paths.settings,
      name: intl.formatMessage({ id: 'routes.settings' }),
      hideInMenu: (!settingsDetail) && true,
      component: SettingsPage,
      icon: SettingsIcon,
      layout: '/admin'
    } : { hideInMenu: true },

  {
    path: paths.auth.logout,
    name: intl.formatMessage({ id: 'routes.logout' }),
    component: LogoutPage,
    icon: ExitToAppIcon,
    layout: '/admin'
  },
  {},
  {
    path: paths.auth.forgottenPassword,
    name: intl.formatMessage({ id: 'routes.forgottenPassword' }),
    component: ForgottenPassword,
    hideInMenu: true,
    layout: '/auth'
  }
]);

export default getRoutes;
