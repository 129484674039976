import React, { useState, useEffect } from "react";
import { Grid } from '@material-ui/core';
import { useQuery, useMutation } from 'react-apollo';
import { Formik } from 'formik';
import { pathOr } from 'rambda';
import FormikField from 'components-lib/FormikField/FormikField';
import { validationSchemaStep2 } from "./validationSchema";
import { injectIntl } from 'react-intl';
import MEDIAS_BY_COMPANY from "queries/MediaQueries/mediasByCompany";
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import MEDIA_DOWNLOAD from "queries/MediaQueries/mediaDownload";
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => styles(theme));

const Step2 = ({ intl, callback, callbackValid, initialData, companyId, spotType, campaignType }) => {


  let spotTypeFilter;
  if (spotType) {
    if (spotType === "Announcement")
      spotTypeFilter = "Announcement"
    else
      spotTypeFilter = "InternalSpot"
  }
  else {
    if (campaignType === "Internal")
      spotTypeFilter = "InternalSpot"
    else
      spotTypeFilter = "CommercialSpot"
  }

  const { data: mediasData } = useQuery(MEDIAS_BY_COMPANY(Number(companyId)),
    {
      variables: {
        filter: [
          {
            acceptanceStatus: {
              eq: "Approved"
            },
            category: {
              eq: spotTypeFilter
            }
          }
        ]
      },
      skip: !companyId
    }
  );

  const [mediaDownload] = useMutation(MEDIA_DOWNLOAD);

  const [trackPlaying, setTrackPlaying] = useState(null);
  const [isPlaying, setIsPlaying] = useState(null);

  const handlePlay = (dataId) => {
    trackPlaying && handleStopPlay()
    mediaDownload({
      variables: {
        id: dataId
      }
    }).then(response => {
      const stream = new Audio((pathOr('', ['data', 'mediaDownload', 'url'], response)));
      setTrackPlaying(stream);
      setIsPlaying(dataId);
      stream.play();
    })
  }

  useEffect(() => {
    return () => {
      trackPlaying && handleStopPlay()
    }
  }, trackPlaying)

  const handleStopPlay = () => {
    trackPlaying.pause();
    setTrackPlaying(null);
    setIsPlaying(null);
  }



  const getMediasOptions = () => {
    const mediasOptions = [];
    pathOr([], ['mediasByCompany', 'items'], mediasData).map(media => {
      mediasOptions.push({
        label: media.name,
        value: media.id
      })
      return ""
    })
    return mediasOptions
  }

  const getMedia = (media, param) => {
    let mediaOptions = [];
    pathOr([], ['mediasByCompany', 'items'], mediasData).filter(m => (m.id) === media).map(med => {
      mediaOptions.push({
        name: med.name,
        id: med.id
      })
    })

    return <>
      {(mediaOptions?.map(med => (
        <>
          <ListItemIcon>
            {isPlaying && isPlaying === med.id
              ? <PauseCircleFilledIcon
                className={classes.pointer}
                onClick={() => handleStopPlay()}>
              </PauseCircleFilledIcon>
              : <PlayCircleFilledOutlinedIcon
                className={classes.pointer}
                onClick={() => { handlePlay(med.id) }}
              />
            }
          </ListItemIcon>
          <ListItemText>{med.name}</ListItemText>
        </>
      )))}
    </>
  }

  const classes = useStyles();
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialData}
        validationSchema={validationSchemaStep2(intl)}
      >
        {formikProps => (
          <>
            <Grid container>
              {callbackValid(formikProps.isValid && (formikProps.values?.spotIds?.length > 0) && (formikProps.values?.spotIds?.[0] !== 'AllNotifications'))}
              {callback(formikProps?.values)}
              <Grid item sm={12}>
                <FormikField
                  name="spotIds"
                  labelText={intl.formatMessage({ id: 'campaigns.wizard.step2.pictures' })}
                  formikProps={formikProps}
                  data={formikProps.values}
                  type="multiSelect"
                  options={getMediasOptions()}
                  highlightChange
                />
              </Grid>
            </Grid>

            {(formikProps.values?.spotIds?.length > 0) && (formikProps.values?.spotIds?.[0] !== 'AllNotifications') &&
              <>
                <br />
                <hr />
                <List component="nav" aria-label="main mailbox folders">
                  {(formikProps.values?.spotIds)?.map(item =>
                    <ListItem>
                      {getMedia(item, "name")}
                    </ListItem>
                  )}
                </List>
                <hr />
              </>
            }
          </>
        )}
      </Formik>
    </>
  );
}

export default injectIntl(Step2);