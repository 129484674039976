import gql from 'graphql-tag';

const ALL_COMMISSION = gql`
query companyCommissionsTable (
  $offset: Int, 
  $limit:Int, 
  $sort:[CompanyCommissionsTableSortInput!],
  $filter:[CompanyCommissionsTableFilterInput]
) {
  companyCommissionsTable (
  offset: $offset, limit:$limit, sort: $sort, filter: $filter
  ) {
      totalCount,
      items {
        id
        bid
        companyId
        companyBid
        companyName
        storesCount
        period
        spotsCount
        value
      }
  }
}
`;

export default ALL_COMMISSION;