import { useMutation } from '@apollo/react-hooks';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RadioIcon from '@material-ui/icons/Radio';
import AutocompleteSelect from 'components-lib/AutocompleteSelect/AutocompleteSelect';
import IconCard from "components-lib/IconCard/IconCard";
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import paths from 'paths';
import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import DEVICE_ASSIGN_ZONE from "queries/DevicesQueries/deviceAssignZone";
import DevicePlaybackEnum from "common/enums/DevicePlaybackEnum";
import DeviceErrorCodesEnum from 'common/enums/DeviceErrorCodesEnum';
import moment from "moment";
import Box from "@material-ui/core/Box";
import Slider from "components-lib/Slider/Slider";
import { deviceAssign, deviceDetail } from 'configFiles/roles'
import VolumeUp from '@material-ui/icons/VolumeUp';
import FormikField from "components-lib/FormikField/FormikField";
import RestoreIcon from '@material-ui/icons/Restore';
import EditIcon from '@material-ui/icons/Edit';
import MultipleValuesField from "components-lib/MultipleValuesField/MultipleValuesField";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => styles(theme));

const StatusCard = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const {
        intl,
        formikProps,
        status,
        lastHeartbeat,
        player,
        deviceBid,
        zoneId,
        refetchData,
        history,
        isAssigned,
        deviceId,
        availableDevices,
        refetchDevice,
        data,
        storeUpdate,
        multipleUpdate,
        callbackHaveDevice,
        balenaId,
        deviceType,
        clientVersion
    } = props;

    const [changeModalOpen, setChangeModalOpen] = useState(false);
    const [chosenPlayerToChange, setChosenPlayerToChange] = useState(null);

    const [deviceAssignZone] = useMutation(DEVICE_ASSIGN_ZONE);
    const [haveDevice, setHaveDevice] = useState(true)

    const [streamVolumeChange, setStreamVolumeChange] = useState(multipleUpdate ? false : true);
    const [spotVolumeChange, setSpotVolumeChange] = useState(multipleUpdate ? false : true);
    const [jingleVolumeChange, setJingleVolumeChange] = useState(multipleUpdate ? false : true);
    const [announcementVolumeChange, setAnnouncementVolumeChange] = useState(multipleUpdate ? false : true);
    const [musicVolumeChange, setMusicVolumeChange] = useState(multipleUpdate ? false : true);


    useEffect(() => {
        const zone = (Object.values(data))?.map(item => {
            return item?.zones?.[0]?.device?.id
        })
        if (zone.length > 1) setHaveDevice(!zone.includes(undefined))
        else setHaveDevice(false)

    }, [data])

    const changeDevice = () => {
        deviceAssignZone({
            variables: {
                id: chosenPlayerToChange.value,
                zoneId: zoneId
            }
        }).then(() => {
            enqueueSnackbar(intl.formatMessage({ id: 'deviceDetail.player.assignPlayerSuccessful' }), { variant: 'success' });
            setChangeModalOpen(false);
            setChosenPlayerToChange(null)
            refetchData()
            refetchDevice()
        }).catch((err) => {
            console.log('[error]', err);
        });
    }

    const renderChangeActions = (change) => {
        return (
            <>
                <Button
                    disabled={(chosenPlayerToChange === deviceId) || (chosenPlayerToChange === null)}
                    onClick={() => changeDevice()}
                    color="success"
                    round
                    size="sm"
                >
                    {(change)
                        ? intl.formatMessage({ id: "deviceDetail.player.assignPlayer.change" })
                        : intl.formatMessage({ id: "deviceDetail.player.assignPlayer.assign" })
                    }
                </Button>
                <Button
                    onClick={() => setChangeModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "deviceDetail.status.back" })}
                </Button>
            </>
        )
    }

    (multipleUpdate) && callbackHaveDevice(haveDevice)

    const renderNoDevice = () => {
        if (multipleUpdate)
            return <>
                <br />
                {intl.formatMessage({ id: 'storeList.multiCreate.statusCard.problem' })}
            </>

        return <>
            <br />
            {intl.formatMessage({ id: "deviceDetail.player.withoutPlayer.title" })}
            <Grid container>
                {(deviceAssign) &&
                    <Grid item sm={12}>
                        <hr />
                        <Button
                            className={classes.floatRight}
                            color="info"
                            round
                            size="sm"
                            onClick={() => setChangeModalOpen(true)}
                        >
                            {intl.formatMessage({ id: "deviceDetail.player.assignPlayer.title" })}
                        </Button>
                    </Grid>
                }
            </Grid>

            <Modal
                title={intl.formatMessage({ id: "deviceDetail.player.assignPlayer.title" })}
                open={changeModalOpen}
                onClose={() => setChangeModalOpen(false)}
                actions={renderChangeActions()}
            >
                <AutocompleteSelect
                    onChange={(rowData) => setChosenPlayerToChange(rowData)}
                    className={classes.selectStyle}
                    placeholder={intl.formatMessage({ id: 'deviceList.assignModal.device' })}
                    label={intl.formatMessage({ id: "deviceDetail.player.newPlayer.text" })}
                    options={availableDevices}
                />
            </Modal>
        </>
    }

    const renderDeviceStatus = () => {
        return <>
            {(!multipleUpdate) &&
                <>
                    < br />
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.ID" })}
                            <Box className={classes.floatRight}>
                                <Tooltip title={intl.formatMessage({ id: "tableTexts.copyToClipboard" })}>
                                    <Box className={classes.floatRight}>
                                        <span
                                            className={classes.copyToClipboard}
                                            onClick={() => { navigator.clipboard.writeText(deviceBid); enqueueSnackbar(intl.formatMessage({ id: "tableTexts.copyToClipboard.success" }), { variant: 'success' }); }}
                                        >
                                            {deviceBid}
                                        </span>
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.balenaID" })}
                            <Tooltip title={intl.formatMessage({ id: "tableTexts.copyToClipboard" })}>
                                <Box className={classes.floatRight}>
                                    <span
                                        className={classes.copyToClipboard}
                                        onClick={() => { navigator.clipboard.writeText(balenaId); enqueueSnackbar(intl.formatMessage({ id: "tableTexts.copyToClipboard.success" }), { variant: 'success' }); }}
                                    >
                                        {balenaId}
                                    </span>
                                </Box>
                            </Tooltip>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.deviceType" })}
                            <Box className={classes.floatRight}>
                                {deviceType}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.clientVersion" })}
                            <Box className={classes.floatRight}>
                                {clientVersion}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.status" })}
                            {(status === DeviceErrorCodesEnum.OFFLINE.text) ?
                                <Box color="error.main" className={classes.floatRight}>
                                    {status}
                                </Box>
                                :
                                <Box color="success.main" className={classes.floatRight}>
                                    {status}
                                </Box>
                            }
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.deviceStatusCard.lastHeartbeat" })}
                            <Box className={classes.floatRight}>
                                {lastHeartbeat
                                    ? (
                                        <span className={classes.floatRight}>
                                            {moment(lastHeartbeat).format("DD.MM.YYYY HH:mm:ss")}
                                        </span>
                                    ) : (
                                        <span> - </span>
                                    )

                                }
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "deviceDetail.status.playbackStatus" })}
                            {(player === DevicePlaybackEnum.PLAYING)
                                ? <Box color="success.main" className={classes.floatRight}>
                                    {intl.formatMessage({ id: "deviceDetail.devicePlayback.online" })}
                                </Box>
                                : <Box color="error.main" className={classes.floatRight}>
                                    {intl.formatMessage({ id: "deviceDetail.devicePlayback.noplay" })}
                                </Box>
                            }
                        </Grid>
                    </Grid>
                    {(!multipleUpdate) &&
                        <hr />
                    }
                </>
            }
            {
                (storeUpdate) ?
                    <>
                        <Grid container spacing={1}>

                            <Grid item sm={12}>
                                {!streamVolumeChange ?
                                    <MultipleValuesField
                                        callback={(newValue) => setStreamVolumeChange(newValue)}
                                        labelText={intl.formatMessage({ id: "storeDetail.statusCard.streamVolume" })}
                                        type={'slider'}
                                        icon={<VolumeUp />}
                                    />
                                    :
                                    <FormikField
                                        name="zones.0.preferences.streamPlaybackVolume"
                                        labelText={intl.formatMessage({ id: "storeDetail.statusCard.streamVolume" })}
                                        formikProps={formikProps}
                                        type="slider"
                                        icon={<VolumeUp />}
                                        minValueSlider={0}
                                        maxValueSlider={100}
                                        defaultValueSlider={50}
                                        highlightChange
                                        helperText={(multipleUpdate) &&
                                            <span
                                                className={classes.multiUpdate}
                                                style={{ color: "blue" }}
                                                onClick={() => { formikProps.setFieldValue("zones.0.preferences.streamPlaybackVolume", undefined); setStreamVolumeChange(false) }}
                                            >
                                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                            </span>
                                        }
                                    />
                                }
                            </Grid>

                            <Grid item sm={12}>
                                {!spotVolumeChange ?
                                    <MultipleValuesField
                                        callback={(newValue) => setSpotVolumeChange(newValue)}
                                        labelText={intl.formatMessage({ id: "storeDetail.statusCard.spotVolume" })}
                                        type={'slider'}
                                        icon={<VolumeUp />}
                                    />
                                    :
                                    <FormikField
                                        name="zones.0.preferences.spotsPlaybackVolume"
                                        labelText={intl.formatMessage({ id: "storeDetail.statusCard.spotVolume" })}
                                        formikProps={formikProps}
                                        type="slider"
                                        icon={<VolumeUp />}
                                        minValueSlider={0}
                                        maxValueSlider={100}
                                        defaultValueSlider={50}
                                        highlightChange
                                        helperText={(multipleUpdate) &&
                                            <span
                                                className={classes.multiUpdate}
                                                style={{ color: "blue" }}
                                                onClick={() => { formikProps.setFieldValue("zones.0.preferences.spotsPlaybackVolume", undefined); setSpotVolumeChange(false) }}
                                            >
                                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                            </span>
                                        }
                                    />
                                }
                            </Grid>

                            <Grid item sm={12}>
                                {!jingleVolumeChange ?
                                    <MultipleValuesField
                                        callback={(newValue) => setJingleVolumeChange(newValue)}
                                        labelText={intl.formatMessage({ id: "storeDetail.statusCard.jingleVolume" })}
                                        type={'slider'}
                                        icon={<VolumeUp />}
                                    />
                                    :
                                    <FormikField
                                        name="zones.0.preferences.jinglePlaybackVolume"
                                        labelText={intl.formatMessage({ id: "storeDetail.statusCard.jingleVolume" })}
                                        formikProps={formikProps}
                                        type="slider"
                                        icon={<VolumeUp />}
                                        minValueSlider={0}
                                        maxValueSlider={100}
                                        defaultValueSlider={50}
                                        highlightChange
                                        helperText={(multipleUpdate) &&
                                            <span
                                                className={classes.multiUpdate}
                                                style={{ color: "blue" }}
                                                onClick={() => { formikProps.setFieldValue("zones.0.preferences.jinglePlaybackVolume", undefined); setJingleVolumeChange(false) }}
                                            >
                                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                            </span>
                                        }
                                    />
                                }
                            </Grid>

                            <Grid item sm={12}>
                                {!announcementVolumeChange ?
                                    <MultipleValuesField
                                        callback={(newValue) => setAnnouncementVolumeChange(newValue)}
                                        labelText={intl.formatMessage({ id: "storeDetail.statusCard.announcementVolume" })}
                                        type={'slider'}
                                        icon={<VolumeUp />}
                                    />
                                    :
                                    <FormikField
                                        name="zones.0.preferences.announcementPlaybackVolume"
                                        labelText={intl.formatMessage({ id: "storeDetail.statusCard.announcementVolume" })}
                                        formikProps={formikProps}
                                        type="slider"
                                        icon={<VolumeUp />}
                                        minValueSlider={0}
                                        maxValueSlider={100}
                                        defaultValueSlider={50}
                                        highlightChange
                                        helperText={(multipleUpdate) &&
                                            <span
                                                className={classes.multiUpdate}
                                                style={{ color: "blue" }}
                                                onClick={() => { formikProps.setFieldValue("zones.0.preferences.announcementPlaybackVolume", undefined); setAnnouncementVolumeChange(false) }}
                                            >
                                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                            </span>
                                        }
                                    />
                                }
                            </Grid>

                            <Grid item sm={12}>
                                {!musicVolumeChange ?
                                    <MultipleValuesField
                                        callback={(newValue) => setMusicVolumeChange(newValue)}
                                        labelText={intl.formatMessage({ id: "storeDetail.statusCard.musicVolume" })}
                                        type={'slider'}
                                        icon={<VolumeUp />}
                                    />
                                    :
                                    <FormikField
                                        name="zones.0.preferences.musicPlaybackVolume"
                                        labelText={intl.formatMessage({ id: "storeDetail.statusCard.musicVolume" })}
                                        formikProps={formikProps}
                                        type="slider"
                                        icon={<VolumeUp />}
                                        minValueSlider={0}
                                        maxValueSlider={100}
                                        defaultValueSlider={50}
                                        highlightChange
                                        helperText={(multipleUpdate) &&
                                            <span
                                                className={classes.multiUpdate}
                                                style={{ color: "blue" }}
                                                onClick={() => { formikProps.setFieldValue("zones.0.preferences.musicPlaybackVolume", undefined); setMusicVolumeChange(false) }}
                                            >
                                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                            </span>
                                        }
                                    />
                                }
                            </Grid>
                        </Grid>
                    </> :
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.statusCard.streamVolume" })}
                            <Box className={classes.floatRight}>
                                {props.streamPlaybackVolume}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.statusCard.spotVolume" })}
                            <Box className={classes.floatRight}>
                                {props.spotsPlaybackVolume}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.statusCard.jingleVolume" })}
                            <Box className={classes.floatRight}>
                                {props.jinglePlaybackVolume}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.statusCard.announcementVolume" })}
                            <Box className={classes.floatRight}>
                                {props.announcementPlaybackVolume}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "storeDetail.statusCard.offlineVolume" })}
                            <Box className={classes.floatRight}>
                                {props.musicPlaybackVolume}
                            </Box>
                        </Grid>
                    </Grid>
            }
            {
                (deviceDetail || deviceAssign) &&
                <Grid item sm={12}>
                    <hr />
                </Grid>
            }
            {
                deviceAssign &&
                (!multipleUpdate) &&
                <Button
                    className={classes.floatRight}
                    color="info"
                    round
                    size="sm"
                    onClick={() => setChangeModalOpen(true)}>
                    {intl.formatMessage({ id: 'deviceDetail.player.changePlayer.title' })}
                </Button>
            }
            {
                deviceDetail &&
                (!multipleUpdate) &&
                <Button
                    className={classes.floatRight}
                    color="info"
                    round
                    size="sm"
                    onClick={() => history.push(`/admin${generatePath(paths.devices.detail, { deviceId: deviceId })}`)}>
                    {intl.formatMessage({ id: "storeDetail.deviceStatusCard.deviceDetail" })}
                </Button>
            }
            <Modal
                title={intl.formatMessage({ id: "deviceDetail.player.changePlayer.title" })}
                open={changeModalOpen}
                onClose={() => setChangeModalOpen(false)}
                actions={renderChangeActions(true)}
            >
                <AutocompleteSelect
                    onChange={(rowData) => setChosenPlayerToChange(rowData)}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    className={classes.selectStyle}
                    value={chosenPlayerToChange}
                    placeholder={intl.formatMessage({ id: 'deviceList.assignModal.device' })}
                    label={<span style={{ color: "gray" }}>
                        {intl.formatMessage({ id: "storeDetail.changePlayer.text-1" })}
                        {intl.formatMessage({ id: "storeDetail.changePlayer.text-2" })} {deviceBid}.<br />
                        {intl.formatMessage({ id: "storeDetail.changePlayer.text-3" })}</span>}
                    options={availableDevices} />
            </Modal>
        </>
    }

    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: "storeDetail.deviceStatusCard.title" })}
                className={classes.cardStyle}
                icon={<RadioIcon />}
            >
                {(isAssigned || haveDevice === true) ? renderDeviceStatus() : renderNoDevice()}
            </IconCard>
        </>
    );
};
export default withRouter(injectIntl(StatusCard));
