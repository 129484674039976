import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "components-lib/Modal/Modal";
import PageHeader from "components-lib/PageHeader/PageHeader";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import paths from "paths";
import { pathOr } from "rambda";
import { default as React, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";

import BasicInfoCard from "./StoreCards/BasicInfoCard";

import CompanyCard from "./StoreCards/CompanyCard";
import MaintainerCard from "./StoreCards/MaintainerCard";
import MediaCard from "./StoreCards/MediaCard";
import StatusCard from "./StoreCards/StatusCard";

import TimePlanCard from "./StoreCards/TimePlanCard"
import ButtonsCard from "./StoreCards/ButtonsCard"
import AddressCard from "./StoreCards/AddressCard";

import { getValidationSchemaDetail } from "./validationSchemaDetail";
import { Formik } from "formik";
import DEVICE_REMOVE_ZONE from "queries/DevicesQueries/deviceRemoveZone";
import STORE_DELETE_MUTATION from "queries/StoresQueries/storeDelete";
import STORE_DETAIL from "queries/StoresQueries/storeDetail";
import STORE_UPDATE from 'queries/StoresQueries/storeUpdate';
import moment from 'moment';
import Loading from "components-lib/Loading/Loading";
import { storeUpdate, storeDel, deviceAssign, companyDetail } from 'configFiles/roles'
import DEVICES_QUERY from 'queries/DevicesQueries/getDevices';

const useStyles = makeStyles((theme) => styles(theme));

const StoreDetailPage = (props) => {

    const classes = useStyles();
    const storeId = props.match.params.storeId;
    const { intl, history } = props;
    const { enqueueSnackbar } = useSnackbar();

    //Hooks
    const [removePlayerOpen, setRemovePlayerOpen] = useState(false);
    const [removeStoreOpen, setRemoveStoreOpen] = useState(false);
    const [formikStoreData, setFormikStoreData] = useState(pathOr({}, ['store'], storeData));
    const [deviceId, setDeviceId] = useState(null);
    const [forbiddenTopics, setForbiddenTopics] = useState([]);
    const [city, setCity] = useState(null);
    const [inactiveTimes, setInactiveTimes] = useState([])
    const [buttons, setButtons] = useState([])
    const [availableDevices, setAvailableDevices] = useState({});
    const [times, setTimes] = useState([]);

    const { data: devicesData, refetch: refetchDevice } = useQuery(DEVICES_QUERY,
        {
            variables: {
                filter: [
                    {
                        zone: {
                            isNull: true
                        }
                    }
                ]
            }
        }
    );
    //Queries and Mutations
    const { data: storeData, loading: storeLoading, refetch: refetchData } = useQuery(STORE_DETAIL, {
        variables: {
            id: storeId,
        },
    });
    const [deviceRemoveZone] = useMutation(DEVICE_REMOVE_ZONE);
    const [storeDelete] = useMutation(STORE_DELETE_MUTATION);
    const [updateStore] = useMutation(STORE_UPDATE);

    //Mount component use effect

    useEffect(() => {
        let helpFormikProps = { ...pathOr({}, ['store'], storeData) }
        let musicPOrder = helpFormikProps?.zones?.[0]?.preferences?.musicPlaybackOrder
        let helpArray = {
            ...helpFormikProps,
            zones: {
                0: {
                    area: helpFormikProps?.zones?.[0]?.area,
                    bid: helpFormikProps?.zones?.[0]?.bid,
                    device: helpFormikProps?.zones?.[0]?.device,
                    visitors: helpFormikProps?.zones?.[0]?.visitors,
                    preferences: {
                        ...helpFormikProps.zones?.[0]?.preferences,
                        musicPlaybackOrder: (musicPOrder === "Random") ? true : false
                    }

                }
            }
        }
        setFormikStoreData(helpArray);

        setTimes(pathOr('', ['store', 'zones', [0], 'preferences', 'activeTimes'], storeData))
        setCity(pathOr('', ['store', 'city'], storeData))
        setDeviceId(pathOr('', ['store', 'zones', [0], 'device', 'id'], storeData))
        setInactiveTimes(((Object.values(pathOr('', ['store', 'zones', [0], 'preferences', 'inactiveTimes'], storeData))?.map(item => (
            {
                from: moment(item?.from),
                to: moment(item?.to)
            })))))
        setButtons((Object.values(pathOr([], ['store', 'zones', [0], 'preferences', 'buttons'], storeData)?.map(item => (
            {
                value: item.media.id,
                label: item.media.name,
                button: item.button
            })))))
        setForbiddenTopics(((Object.values(pathOr('', ['store', 'zones', [0], 'preferences', 'forbiddenTopics'], storeData))?.map(item => (
            {
                value: item.id,
                label: item.name
            })))))
    }, [storeData]);

    useEffect(() => {
        let deviceSelectItems = pathOr([], 'devices.items', devicesData).map((device) => {
            return {
                value: pathOr('', 'id', device),
                label: `${pathOr('', 'id', device)} - ${pathOr('', 'bid', device)}`
            }
        })
        setAvailableDevices(deviceSelectItems);
    }, [devicesData]);


    //Detail Page Head functionality
    const getStoreBid = () => {
        return (
            <>
                {intl.formatMessage({ id: "deviceList.table.store" })} &nbsp;
                {pathOr('', ['store', 'bid'], storeData)}
            </>
        );
    };

    const handleUpdateStore = (values) => {
        const result = { ...values };
        delete result.id;
        delete result.bid;
        delete result.segment;
        delete result.zones;
        delete result.company;
        delete result.__typename;
        delete result.area;
        delete result.visitors;
        delete result.latitude;
        delete result.longtitude;

        let setForbiddenTopicsIds = (forbiddenTopics?.length > 0) ? forbiddenTopics?.map(topic => topic.value) : [];
        let setCityLabel = city.label;

        for (let i = 0; i < times.length; i++) {
            if (times[i].__typename)
                delete times[i].__typename;
        }

        let parseButtons = buttons?.map(item => ({
            button: item.button,
            mediaId: item.value
        }))
        let setInTimes = []
        for (let i = 0; i < inactiveTimes.length; i++) {
            if (inactiveTimes[i]?.from !== undefined || inactiveTimes[i]?.to !== undefined)
                setInTimes.push({
                    from: inactiveTimes[i]?.from,
                    to: inactiveTimes[i]?.to
                });
        }

        console.log("FT: ", setForbiddenTopicsIds)

        updateStore({
            variables: {
                id: values.id,
                input: {
                    ...result,
                    area: Number(values.zones[0].area),
                    visitors: Number(values.zones[0].visitors),
                    contactFirstName: values.contactFirstName,
                    contactLastName: values.contactLastName,
                    contactPhone: values.contactPhone,
                    contactEmail: values.contactEmail,
                    latitude: (values.latitude) ? Number(values.latitude) : undefined,
                    longtitude: (values.longtitude) ? Number(values.longtitude) : undefined,
                    city: setCityLabel,

                    preferences: {
                        segmentId: Number(values.zones[0].preferences.segment.id),
                        commercialSpotsCount: Number(values.zones[0].preferences.commercialSpotsCount),
                        internalSpotsCount: Number(values.zones[0].preferences.internalSpotsCount),
                        isBeginJingleEnabled: values.zones[0].preferences.isBeginJingleEnabled,
                        isEndJingleEnabled: values.zones[0].preferences.isEndJingleEnabled,
                        beginJingleMediaId: (values.zones[0].preferences.beginJingleMedia) ? Number(values.zones[0].preferences.beginJingleMedia.id) : null,
                        endJingleMediaId: (values.zones[0].preferences.endJingleMedia) ? Number(values.zones[0].preferences.endJingleMedia.id) : null,
                        musicPlaybackOrder: (values.zones[0].preferences.musicPlaybackOrder === true) ? "Random" : "Normal",
                        musicStreamId: (values.zones[0].preferences.musicStream) ? Number(values.zones[0].preferences.musicStream.id) : null,
                        forbiddenTopicIds: setForbiddenTopicsIds,
                        isOpenedDuringHolidays: (values.zones[0].preferences.isOpenedDuringHolidays),
                        spotsPlaybackStartOffset: Number(values.zones[0].preferences.spotsPlaybackStartOffset),
                        spotsPlaybackEndOffset: Number(values.zones[0].preferences.spotsPlaybackEndOffset),
                        activeTimes: times,
                        spotsPlaybackInterval: Number(values.zones[0].preferences.spotsPlaybackInterval),
                        streamPlaybackVolume: Number(values.zones[0].preferences.streamPlaybackVolume),
                        spotsPlaybackVolume: Number(values.zones[0].preferences.spotsPlaybackVolume),
                        jinglePlaybackVolume: Number(values.zones[0].preferences.jinglePlaybackVolume),
                        musicPlaybackVolume: Number(values.zones[0].preferences.musicPlaybackVolume),
                        announcementPlaybackVolume: Number(values.zones[0].preferences.announcementPlaybackVolume),
                        buttons: parseButtons,
                        isKeypadEnabled: values.zones[0].preferences.isKeypadEnabled,
                        isCommercialSlotEnabled: values.zones[0].preferences.isCommercialSlotEnabled,
                        isInternalSlotEnabled: values.zones[0].preferences.isInternalSlotEnabled,
                        inactiveTimes: setInTimes
                    }
                }
            }
        }).then((response) => {
            const companyName = pathOr('', ['data', 'storeCreate', 'company.name'], response);
            enqueueSnackbar(intl.formatMessage({ id: "storeDetail.update.successful" }), {
                variant: 'success'
            });
            refetchData();
        }).catch((err) => {
            console.log(err);
        });
    };

    //Remove device from store mutation
    const removeDevice = () => {
        deviceRemoveZone({
            variables: {
                id: deviceId,
            },
        }).then((response) => {
            enqueueSnackbar(
                intl.formatMessage({ id: "storeDetail.removePlayer.successful" }),
                {
                    variant: "success",
                }
            );
            setRemovePlayerOpen(false)
            refetchDevice()
            refetchData()
        }).catch((err) => {
            console.log(err)
        });
    };

    //Delete store mutation
    const deleteStore = () => {
        storeDelete({
            variables: {
                id: storeId,
            },
        })
            .then((response) => {
                history.push(`/admin${generatePath(paths.stores.list)}`)
                enqueueSnackbar(intl.formatMessage({ id: "storeDetail.removeStore.successful", }), { variant: "success" });
            })
            .catch((err) => {
                console.log(err)
            });
    };

    // Headers Buttons
    const getActions = () => {
        let action = []
        if (pathOr('', ['store', 'zones', [0], 'device'], storeData)) {
            if (deviceAssign)
                action.push({
                    title: intl.formatMessage({ id: "storeDetail.removePlayer.title" }),
                    onClick: () => setRemovePlayerOpen(true),
                })
            if (storeDel)
                action.push({
                    title: intl.formatMessage({ id: "storeDetail.removeStore.title", }),
                    onClick: () => setRemoveStoreOpen(true),
                    color: "danger"
                })
        } else {
            action.push({
                title: intl.formatMessage({ id: "storeDetail.removeStore.title", }),
                onClick: () => setRemoveStoreOpen(true),
                color: "danger"
            })
        }
        return action
    }


    //Remove Player Buttons
    const renderRemovePlayerActions = () => {
        return (
            <>

                <Button
                    onClick={() => removeDevice()}
                    color="warning"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "storeDetail.device.remove" })}
                </Button>

                <Button
                    onClick={() => setRemovePlayerOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "companyList.addModal.cancel" })}
                </Button>
            </>
        );
    };

    //Remove Store Buttons
    const renderRemoveStoreActions = () => {
        return (
            <>
                <Button
                    onClick={() => deleteStore()}
                    color="danger"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "companyDetail.btn.remove" })}
                </Button>
                <Button
                    onClick={() => setRemoveStoreOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "companyList.addModal.cancel" })}
                </Button>
            </>
        );
    };


    //Renders
    //Render Media Widgets
    const renderDeviceCards = (formikProps) => {
        return (
            <>
                <StatusCard
                    deviceId={pathOr('', ['store', 'zones', [0], 'device', 'id'], storeData)}
                    deviceBid={pathOr('', ['store', 'zones', [0], 'device', 'bid'], storeData)}
                    zoneId={pathOr('', ['store', 'zones', [0], 'id'], storeData)}
                    status={pathOr('', ['store', 'zones', [0], 'device', 'status'], storeData)}
                    lastHeartbeat={pathOr('', ['store', 'zones', [0], 'device', 'onlineAt'], storeData)}
                    player={pathOr('', ['store', 'zones', [0], 'device', 'player', 'status'], storeData)}
                    refetchData={refetchData}
                    refetchDevice={refetchDevice}
                    data={formikProps}
                    isAssigned={(pathOr('', ['store', 'zones', [0], 'device'], storeData))}
                    storeUpdate={storeUpdate}
                    availableDevices={availableDevices}
                    formikProps={formikProps}
                    balenaId={pathOr('', ['store', 'zones', [0], 'device', 'balenaUuid'], storeData)}
                    deviceType={pathOr('', ['store', 'zones', [0], 'device', 'deviceType'], storeData)}
                    clientVersion={pathOr('', ['store', 'zones', [0], 'device', 'clientVersion'], storeData)}
                />

                {(pathOr('', ['store', 'zones', [0], 'device'], storeData)) &&
                    <MediaCard
                        data={formikProps.values}
                        formikProps={formikProps}
                        storeData={storeData}
                        forbidden={forbiddenTopics}
                        callbackForbidden={(newValue) => { setForbiddenTopics(newValue) }}
                        companyId={pathOr('', ['store', 'company', 'id'], storeData)}
                        storeUpdate={storeUpdate}
                    />
                }
            </>
        );
    };

    if (storeLoading) return <Loading />
    return (
        <>
            <Formik
                onSubmit={(values) => { handleUpdateStore(values) }}
                initialValues={formikStoreData}
                validationSchema={getValidationSchemaDetail(intl)}
                enableReinitialize
            >
                {(formikProps) => (
                    <>
                        <PageHeader
                            title={pathOr('', ['store', 'name'], storeData)}
                            subTitle={getStoreBid()}
                            actions={getActions()}
                            handleBackAction={(e) => history.goBack()}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={4}>

                                <BasicInfoCard
                                    formikProps={formikProps}
                                    storeData={storeData}
                                    storeUpdate={storeUpdate}
                                />

                                <TimePlanCard
                                    times={times}
                                    callbackTimes={(newValue) => setTimes(newValue)}
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    storeData={storeData}
                                    inactiveTimes={inactiveTimes}
                                    callbackInactiveTimes={(newValue) => setInactiveTimes(newValue)}
                                    storeId={storeId}
                                    storeUpdate={true}
                                />

                                <ButtonsCard
                                    companyId={pathOr('', ['store', 'company', 'id'], storeData)}
                                    storeUpdate={storeUpdate}
                                    numberOfButtons={10}
                                    callbackButtons={(newValue) => setButtons(newValue)}
                                    buttons={buttons}
                                    data={formikStoreData}
                                    formikProps={formikProps}
                                    data={formikProps.values}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                {renderDeviceCards(formikProps)}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <CompanyCard
                                    className={classes.floatRight}
                                    history={history}
                                    companyId={pathOr('', ['store', 'company', 'id'], storeData)}
                                    companyBid={pathOr('', ['store', 'company', 'bid'], storeData)}
                                    companyName={pathOr('', ['store', 'company', 'name'], storeData)}
                                    companyAddress={pathOr('', ['store', 'company', 'address'], storeData)}
                                    companyPostal={pathOr('', ['store', 'company', 'postal'], storeData)}
                                    companyCity={pathOr('', ['store', 'company', 'city'], storeData)}
                                    companyCountry={pathOr('', ['store', 'company', 'country'], storeData)}
                                    companyContact={`
                                            ${pathOr('', ['store', 'company', 'contactFirstName'], storeData)} 
                                            ${pathOr('', ['store', 'company', 'contactLastName'], storeData)} 
                                        `}
                                    companyMail={pathOr('', ['store', 'company', 'contactEmail'], storeData)}
                                    companyPhone={pathOr('', ['store', 'company', 'contactPhone'], storeData)}
                                    companyDetail={companyDetail}
                                />

                                <MaintainerCard
                                    formikProps={formikProps}
                                    storeData={storeData}
                                    storeUpdate={storeUpdate}
                                />
                                <AddressCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    storeData={storeData}
                                    city={city}
                                    callbackCity={(newValue) => setCity(newValue)}
                                    storeUpdate={storeUpdate}
                                />

                                {/*<MapCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    storeId={storeId}
                                    city={city}
                                    address={pathOr('', ['store', 'address'], storeData)}
                                    postal={pathOr('', ['store', 'postal'], storeData)}
                                    city={pathOr('', ['store','city'], storeData)}
                                    country={pathOr('', ['store', 'country'], storeData)}
                                    lat={pathOr('', ['store', 'latitude'], storeData)}
                                    lon={pathOr('', ['store', 'longtitude'], storeData)}
                                    refetch={refetchData}
                                />
                                */}
                                {storeUpdate &&
                                    <div style={{ position: "fixed", bottom: "20px", right: "20px", }} >
                                        <Button
                                            round
                                            color="primary"
                                            onClick={(e) => {
                                                formikProps.handleSubmit()
                                            }}
                                        >
                                            {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
                                        </Button>
                                        <Button
                                            round
                                            onClick={() => {formikProps.resetForm();
                                            }}
                                        >
                                            {intl.formatMessage({ id: 'settings.cancel' })}
                                        </Button>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        <Modal
                            title={intl.formatMessage({ id: "storeDetail.removePlayer.title" })}
                            open={removePlayerOpen}
                            onClose={() => setRemovePlayerOpen(false)}
                            actions={renderRemovePlayerActions()}
                        >
                            {`${intl.formatMessage({ id: "deviceDetail.status.removeModal.text-1" })} 
                            ${deviceId} ${intl.formatMessage({ id: "deviceDetail.status.removeModal.text-2" })}
                            ${pathOr("", "store.name", storeData)} ${pathOr("", "store.bid", storeData)}?`}
                        </Modal>
                        <Modal
                            title={intl.formatMessage({ id: "storeDetail.removeStore.title" })}
                            open={removeStoreOpen}
                            onClose={() => setRemoveStoreOpen(false)}
                            actions={renderRemoveStoreActions()}
                        >
                            {`${intl.formatMessage({ id: "storeDetail.removeStore.text", })} 
                            ${pathOr("", "store.name", storeData)} ${pathOr("", "store.bid", storeData)}?`}
                        </Modal>
                    </>
                )}
            </Formik>
        </>
    );
};

export default injectIntl(withRouter(StoreDetailPage));
