import React from 'react';
import { getAxiosClient } from "configFiles/axios";
import CampaignNewBidPage from './CampaignNewBidPage';

const CampaignAxiosPage = (props) => {
    const { history } = props
    return (
        <CampaignNewBidPage axios={getAxiosClient()} history={history} />
    );
}

export default CampaignAxiosPage;