import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import { Grid } from "@material-ui/core";
import IconCard from "components-lib/IconCard/IconCard";
import KeyboardIcon from '@material-ui/icons/Keyboard';
import MEDIAS_BY_COMPANY from "queries/StoresQueries/mediasByCompany";
import { pathOr } from 'rambda';
import { useQuery } from "@apollo/react-hooks";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import Input from "@material-ui/core/Input";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import { withStyles } from '@material-ui/core/styles';
import { colors } from "common/styles/configLayout.js";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { mediaList } from 'configFiles/roles'
import FormHelperText from "@material-ui/core/FormHelperText";
import MultipleValuesField from "components-lib/MultipleValuesField/MultipleValuesField";
import FormikField from "components-lib/FormikField/FormikField";

const useStyles = makeStyles((theme) => styles(theme));

const ButtonsCard = (props) => {
    const {
        intl,
        companyId,
        storeUpdate,
        numberOfButtons,
        callbackButtons,
        buttons,
        formikProps,
        multipleUpdate,
        data
    } = props;
    const classes = useStyles();
    let originalValue = null
    let currentValue = null
    const buttonsArray = []
    for (var i = 1; i <= numberOfButtons; i++) {
        buttonsArray.push(i);
    }
    const [helpstate, setHelpstate] = useState(false)
    const [haveCompany, setHaveCompany] = useState(0)

    const [isKeypadEnabledChange, setIsKeypadEnabledChange] = useState(multipleUpdate ? false : true);
    const [buttonsChange, setButtonsChange] = useState(multipleUpdate ? false : true);

    const { data: mediasData } = useQuery(MEDIAS_BY_COMPANY((!multipleUpdate) ? companyId : haveCompany),
        {
            variables: {
                filter: [
                    {
                        category: {
                            eq: "ButtonAnnouncement"
                        },
                        acceptanceStatus: {
                            eq: "Approved"
                        }
                    }

                ]
            },
            skip: companyId === 0 || !storeUpdate || !mediaList || haveCompany === 0
        },
    );

    useEffect(() => {
        const comp = (Object.values(data))?.map(item => {
            return item?.company?.id
        })

        if (comp.length > 1)
            ((comp.every((val, i, arr) => val === arr[0])) === true)
                ? setHaveCompany(comp[0])
                : setHaveCompany(0)
        else setHaveCompany(0)
    }, [data])


    const handleCheckIsChanged = (day) => {
        originalValue = { weekDay: day, from: null, to: null }
        currentValue = { weekDay: day, from: null, to: null }
        if ((formikProps?.values?.zones?.[0]?.preferences?.activeTimes)?.length > 0)
            (formikProps?.values?.zones?.[0]?.preferences?.activeTimes).filter(filt => filt.weekDay.includes(day)).map(item => (
                originalValue = item
            ))



        let inputStyles = ((originalValue?.from?.toString().substring(0, 5) !== currentValue?.from?.toString().substring(0, 5))
            || originalValue?.to?.toString().substring(0, 5) !== currentValue?.to?.toString().substring(0, 5))
            ? { backgroundColor: '#FFF8CC' }
            : {};
        return (inputStyles)
    }


    const getMediasOptions = () => {
        const mediasOptions = [];
        pathOr([], ['mediasByCompany', 'items'], mediasData).map(media => {
            mediasOptions.push({
                label: media.name,
                value: media.id
            })
            return ""
        })
        return mediasOptions
    }

    const handleSetButtons = (helpButtons) => {
        let j = 0
        let arr = []
        for (let i = 0; i < helpButtons.length; i++) {
            if (helpButtons[i]) {
                arr[j] = helpButtons[i]
                j++
            }
        }
        callbackButtons(arr)
        setHelpstate((helpstate) ? false : true)
    }

    const handleChangeButtons = (button, e) => {
        let helpButtons = buttons
        if (helpButtons.length !== 0) {
            helpButtons.map((item, index) => {
                if (item.button === button) delete helpButtons[index];
            })
        }

        helpButtons.push({
            value: e.value,
            label: e.label,
            button: button
        })

        handleSetButtons(helpButtons)
    }

    const handleDeleteButtons = (button) => {
        let helpButtons = buttons
        if (helpButtons.length !== 0) {
            helpButtons.map((item, index) => {
                if (item.button === button) delete helpButtons[index];
            })
        }

        handleSetButtons(helpButtons)
    }

    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: 'storeDetail.buttonsCard.setButtons' })}
                className={classes.cardStyle}
                icon={<KeyboardIcon />}
            >
                {(storeUpdate) ?
                    <>
                        {(multipleUpdate && haveCompany === 0) ?
                            <span>{intl.formatMessage({ id: 'storeList.multiCreate.buttonCard.problem' })}</span>
                            :
                            <Grid item sm={12} >
                                <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                                {(!isKeypadEnabledChange) ?
                                    <MultipleValuesField
                                        callback={(newValue) => setIsKeypadEnabledChange(newValue)}
                                        labelText={intl.formatMessage({ id: 'storeDetail.buttonsCard.activeButtons' })}
                                        type={'toggle'}
                                    />
                                    :
                                    <FormikField
                                        name="zones.0.preferences.isKeypadEnabled"
                                        labelText={intl.formatMessage({ id: 'storeDetail.buttonsCard.activeButtons' })}
                                        checkedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom.on' })}
                                        uncheckedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom.off' })}
                                        formikProps={formikProps}
                                        type="switch"
                                        highlightChange
                                        helperText={(multipleUpdate) &&
                                            <span
                                                className={classes.multiUpdate}
                                                style={{ color: "blue" }}
                                                onClick={() => { formikProps.setFieldValue("zones.0.preferences.isKeypadEnabled", undefined); setIsKeypadEnabledChange(false) }}
                                            >
                                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                            </span>
                                        }
                                    />

                                }

                                {(!buttonsChange) ?
                                    <MultipleValuesField
                                        style={{ paddingTop: "10px" }}
                                        callback={(newValue) => setButtonsChange(newValue)}
                                        labelText={intl.formatMessage({ id: 'storeDetail.buttonsCard.button' })}
                                        type={'toggle'}
                                    />
                                    :
                                    <List style={{ paddingTop: "10px" }}>
                                        {buttonsArray.map(item =>
                                            <ListItem
                                                style={{ paddingTop: "1px", paddingBottom: "1px", paddingLeft: 0 }}
                                            //style={handleCheckIsChanged(item)}
                                            >
                                                <ListItemText style={{ marginRight: "-200px" }} >
                                                    {(item !== 10) ? <>{intl.formatMessage({ id: 'storeDetail.buttonsCard.button' })} <b>{item}</b>: &nbsp;&nbsp;&nbsp;</> : <>{intl.formatMessage({ id: 'storeDetail.buttonsCard.button' })}: <b>0</b></>}
                                                </ListItemText>
                                                <ListItemText>
                                                    <AutocompleteSelect
                                                        disabled={(formikProps?.values?.zones?.[0]?.preferences?.isKeypadEnabled) ? false : true}
                                                        style={{ marginBottom: "15px" }}
                                                        placeholder="Vyberte tlačidlo..."
                                                        options={getMediasOptions()}
                                                        value={buttons.map((but) => {
                                                            if (item === but.button) return but
                                                            else return null
                                                        })}
                                                        onChange={(e) => { handleChangeButtons(item, e) }}
                                                        input={<Input />}
                                                    />
                                                </ ListItemText>
                                                {(formikProps?.values?.zones?.[0]?.preferences?.isKeypadEnabled) &&
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="delete">
                                                            <DeleteIcon onClick={(e) => { handleDeleteButtons(item) }} />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        )}
                                        <Grid item sm={12}>
                                            {multipleUpdate && <FormHelperText>
                                                <span
                                                    className={classes.multiUpdate}
                                                    style={{ color: "blue" }}
                                                    onClick={() => { callbackButtons([]); setButtonsChange(false) }}
                                                >
                                                    {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                                </span>
                                            </FormHelperText>}
                                            <hr style={{ marginTop: "1px", marginBottom: "0px" }} />
                                        </Grid>
                                    </List>
                                }
                            </Grid>
                        }

                    </>
                    : ""
                }

            </IconCard>

        </>
    );
};

export default injectIntl(ButtonsCard);
