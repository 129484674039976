import React from "react";
import StreamTable from "./StreamTable";
import PageHeader from "components-lib/PageHeader/PageHeader";
import IconCard from "components-lib/IconCard/IconCard";
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import ALL_STREAMS from "queries/StreamsQueries/allStreams";
import STREAM_DISCOVER from "queries/StreamsQueries/streamDiscover";
import { injectIntl } from "react-intl";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { useLazyQuery } from "@apollo/react-hooks";
import { streamDisc } from 'configFiles/roles'
const StreamListPage = (props) => {
    const { intl, history } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [streamDiscover] = useMutation(STREAM_DISCOVER)
    const [loadStreams, { data: dataStreams, refetch: refetchStreams }] = useLazyQuery(ALL_STREAMS);

    const refreshStreams = () => {
        streamDiscover()
            .then(response => {
                enqueueSnackbar(intl.formatMessage({ id: "streams.page.successRefresh" }), { variant: 'success', })
                refetchStreams()
            }).catch(err => {
                console.log('[error]', err)
            });
    }

    const getActions = () => {
        if (streamDisc)
            return ([
                {
                    title: intl.formatMessage({ id: "streams.page.refreshButton" }),
                    onClick: () => refreshStreams(),
                }
            ])
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "streams.page.title" })}
                actions={getActions()}
                withBackButton={false}
            />
            <IconCard
                icon={<QueueMusicIcon />}
                title={intl.formatMessage({ id: "streams.table.title" })}
            >
                <StreamTable
                    data={dataStreams}
                    loadData={loadStreams}
                    defaultFilter={null}
                    history={history}
                    queryDataPath={['streamsTable', 'items']}
                />
            </IconCard>
        </>
    )
}

export default injectIntl(StreamListPage)
