import gql from 'graphql-tag';

export const CAMPAIGN_CREATE = gql`
mutation campaignCreate($input: CampaignCreateInput!){
    campaignCreate(input: $input){
        name
        id
        bid
    }
}
`;