/*eslint-disable*/
import React from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import stylesCheckbox from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import TablePagination from "@material-ui/core/TablePagination";
import { map, find, pathOr } from "rambda";
import { injectIntl } from 'react-intl';

//For Modal
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important"
  },
  gridContainer: {
    justifyContent: "center"
  }
};

const useStyles = makeStyles(newStyles);
const useStylesCheckbox = makeStyles(stylesCheckbox);

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        value: filterValue || "",
        onChange: e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
        placeholder: `Search ${count} records...`
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

// Our table component
function Table({ columns, data, onPageChange, onRowsCountChange, currentPage, rowsPerPage, totalCount, filters, handleFilterChange, intl, sorts, handleSortChange, openCollapse, idCollapse, selectedIndex, index }) {
  const [numberOfRows, setNumberOfRows] = React.useState(rowsPerPage);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const classes = useStyles();
  const classesCheckbox = useStylesCheckbox();

  //MILAN START
  const [startValue, setStartValue] = React.useState(" ");
  const [endValue, setEndValue] = React.useState(" ");
  const [columnFilter, setColumnFilter] = React.useState(" ");
  const [columnOperator, setColumnOperator] = React.useState(" ");
  //For modal
  const [assignModalOpen, setAssignModalOpen] = React.useState(false);


  const handleModalOpen = (filter, operator) => {
    setColumnFilter(filter);
    setColumnOperator(operator);
    setAssignModalOpen(true)
  };

  //MILAN END

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );




  const handlePageChange = (count) => {
    setPageNumber(pageNumber + count);
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 25, pageIndex: 0 }
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );
  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
    function () { }
  );

  let numberOfRowsData = (rowsPerPage === 25) ? [25, 50, 100] : [5, 10, 25]

  return (
    <>
      <div className="ReactTable -striped -highlight">
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup, key) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr" key={key}>
                {/* <th
                  key={key}
                  className={classnames(`rt-th rt-resizable-header`)}
                >
                </th>*/}
                {headerGroup.headers.map((column, key) => {
                  column.filterComponent = pathOr('input', ['filterComponent'], column);
                  column.canFilter = !!pathOr(false, ['filterKey'], column);
                  column.canSort = !!pathOr(false, ['sortKey'], column);
                  const activeSortDirection = pathOr('', ['order'], find((sort) => sort.field === column.sortKey, sorts))
                  column.activeSortDirection = activeSortDirection;
                  column.canSort = !!pathOr(false, ['sortKey'], column);
                  const cursorPointer = column.canSort ? '-cursor-pointer' : '';
                  return (
                    <th
                      key={key}
                      className={classnames(`rt-th rt-resizable-header ${cursorPointer}`, {
                        "-cursor-pointer": column.canSort && !column.activeSortDirection,
                        "-sort-asc": activeSortDirection === 'ASC',
                        "-sort-desc": column.canSort && activeSortDirection === 'DESC'
                      })}
                     
                    >
                      <div
                        className="rt-resizable-header-content"
                        onClick={(e) => {
                          handleSortChange(column.sortKey, column.canSort);
                          onPageChange(0);
                          handlePageChange(0);
                        }}
                        style={{
                          marginBottom: '10px' //MILAN START/END
                        }}
                      >
                        {column.render("Header")}

                      </div>

                      {column.canFilter && column.filterComponent === 'input' && (
                        <FormControl>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              placeholder: intl.formatMessage({ id: 'queryTable.filter.placeholder' }),
                              value: pathOr(
                                '',
                                [column.filterKey, column.filterOperator],
                                filters
                              ),

                              onChange: e => {
                                handleFilterChange(column.filterKey, e.target.value, null, column.filterDataType, column.filterOperator);
                                onPageChange(0);
                                handlePageChange(0);
                                // setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                              },
                              // placeholder: `Search ${count} records...`
                            }}
                          />
                        </FormControl>

                      )}


                      {column.canFilter && column.filterComponent === 'select' && (
                        <FormControl fullWidth className={classes.selectFormControl} style={{ paddingTop: "7px" }}>

                          <InputLabel
                            className={classes.selectLabel}
                          >
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            inputProps={{
                              placeholder: intl.formatMessage({ id: 'queryTable.filter.placeholder' })
                            }}
                            value={pathOr(0, [column.filterKey, column.filterOperator], filters)}
                            onChange={(e) => handleFilterChange(column.filterKey, e.target.value, null, column.filterDataType, column.filterOperator)}
                          >
                            <MenuItem
                              value={0}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                            >
                              {intl.formatMessage({ id: 'queryTable.select.all' })}
                            </MenuItem>
                            {map((option, index) =>
                              <MenuItem
                                key={`filter.${column.filterKey}.${index}`}
                                value={option.value}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {option.label}
                              </MenuItem>
                              , column.filterOptions || [])}
                          </Select>
                        </FormControl>
                      )}

                      {/* MILAN START */}
                      {column.canFilter && column.filterComponent === 'date' && (
                        <>
                          <FormControl disabled style={{ paddingTop: "7px" }}>
                            <InputLabel>
                              {intl.formatMessage({ id: "queryTable.filter.dateBetween" })}
                              <IconButton style={{ marginLeft: ".5rem" }}
                                size="small"
                                color="secondary"
                                onClick={(e) => {
                                  setEndValue(" "),
                                    setStartValue(" "),
                                    handleFilterChange(column.filterKey, null, null, column.filterDataType, column.filterOperator)
                                }}
                              >
                                <CloseIcon />
                              </IconButton >
                            </InputLabel>

                            <Input
                              startAdornment={
                                <InputAdornment position="start" style={{ fontSize: "0.8rem" }}>
                                  <IconButton style={{ marginLeft: ".5rem" }}
                                    size="small"
                                    onClick={(e) => { handleModalOpen(column.filterKey, column.filterOperator) }}
                                  >
                                    <EventIcon />
                                  </IconButton >
                                  {(pathOr('', [column.filterKey, column.filterOperator, "start"], filters)
                                    && (pathOr('', [column.filterKey, column.filterOperator, "start"], filters) !== "2018-01-01"))
                                    ? pathOr('', [column.filterKey, column.filterOperator, "start"], filters).substring(8, 10)
                                    + "/" + pathOr('', [column.filterKey, column.filterOperator, "start"], filters).substring(5, 7)
                                    + "/" + pathOr('', [column.filterKey, column.filterOperator, "start"], filters).substring(2, 4)
                                    : " . . . "
                                  } -
                                    {(pathOr('', [column.filterKey, column.filterOperator, "end"], filters)
                                    && (pathOr('', [column.filterKey, column.filterOperator, "end"], filters) !== "2100-01-01"))
                                    ? pathOr('', [column.filterKey, column.filterOperator, "end"], filters).substring(8, 10)
                                    + "/" + pathOr('', [column.filterKey, column.filterOperator, "end"], filters).substring(5, 7)
                                    + "/" + pathOr('', [column.filterKey, column.filterOperator, "end"], filters).substring(2, 4)
                                    : " . . . "
                                  }
                                </InputAdornment>
                              }
                            />
                          </FormControl>

                          <Modal
                            title={intl.formatMessage({ id: "queryTable.filter.setDateRange" })}
                            open={assignModalOpen}
                            onClose={() => setAssignModalOpen(false)}
                          >
                            <div style={{ marginBottom: "1.5rem" }}>
                              <label htmlFor="start" style={{ display: "block", margin: ".4rem 0" }}
                                name={name}
                              >{intl.formatMessage({ id: "queryTable.filter.pickerStart" })}</label>
                              <input type="date" style={{ margin: ".4rem 0" }}
                                min="2018-03"
                                value={(pathOr('', [columnFilter, columnOperator, "start"], filters)
                                  && (pathOr('', [columnFilter, columnOperator, "start"], filters) !== "2018-01-01"))
                                  ? (pathOr('', [columnFilter, columnOperator, "start"], filters)).substring(0, 10) : ""}
                                onChange={(e) => {
                                  setStartValue(e.target.value),
                                    handleFilterChange(columnFilter, e.target.value, endValue, column.filterDataType, columnOperator)
                                }}
                              />
                              <IconButton style={{ marginLeft: ".5rem" }}
                                size="small"
                                color="secondary"
                                onClick={(e) => {
                                  setStartValue(" "),
                                    handleFilterChange(columnFilter, null, endValue, column.filterDataType, columnOperator)
                                }}
                              >
                                <CloseIcon />
                              </IconButton >
                            </div>
                            <div style={{ marginBottom: "1.5rem" }}>
                              <label htmlFor="end" style={{ display: "block", margin: ".4rem 0" }}
                                name={name}
                              >{intl.formatMessage({ id: "queryTable.filter.pickerEnd" })}</label>
                              <input type="date" style={{ margin: ".4rem 0" }}
                                min="2018-03"
                                value={(pathOr('', [columnFilter, columnOperator, "end"], filters)
                                  && (pathOr('', [columnFilter, columnOperator, "end"], filters) !== "2100-01-01"))
                                  ? (pathOr('', [columnFilter, columnOperator, "end"], filters)).substring(0, 10) : ""}
                                onChange={(e) => {
                                  setEndValue(e.target.value),
                                    handleFilterChange(columnFilter, startValue, e.target.value, column.filterDataType, columnOperator)
                                }}
                              />
                              <IconButton style={{ marginLeft: ".5rem" }}
                                size="small"
                                color="secondary"
                                onClick={(e) => {
                                  setEndValue(" "),
                                    handleFilterChange(columnFilter, startValue, null, column.filterDataType, columnOperator)
                                }}
                              >
                                <CloseIcon />
                              </IconButton >
                            </div>


                          </Modal>
                        </>
                      )}
                      {/* MILAN END */}

                    </th>
                  )
                })}
              </tr>
            ))}

          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  style={{ marginLeft: "1px" }}
                  key={i}
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 }
                  )}
                >
                  {/*<td className="rt-td">
                    <Checkbox
                      key="key"
                      //className={classes.positionAbsolute}
                      tabIndex={-1}
                      //onClick={() => callbackButton(true)}
                      checkedIcon={<Check className={classesCheckbox.checkedIcon} />}
                      icon={<Check className={classesCheckbox.uncheckedIcon} />}
                      classes={{
                        checked: classesCheckbox.checked,
                        root: classesCheckbox.checkRoot
                      }}
                    />
                    </td>*/}
                  {row.cells.map((cell, key) => {
                    // console.log('[cell]', cell);
                    return (
                      <td {...cell.getCellProps()} className="rt-td" key={key}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>


        </table>
        <TablePagination
          // labelRowsPerPage={intl.formatMessage({ id: 'tableTexts.rowsPerPage' })}
          labelRowsPerPage=""
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'tableTexts.of' })} ${count !== -1 ? count : `${intl.formatMessage({ id: 'tableTexts.moreThan' })} ${to}`}`}
          rowsPerPageOptions={numberOfRowsData}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          // ActionsComponent={CustomPaginationActions}
          onChangePage={(e, newPageNumber) => {
            onPageChange(newPageNumber);
            handlePageChange(newPageNumber);
          }}
          onChangeRowsPerPage={(e) => {
            onRowsCountChange(e.target.value);
            setNumberOfRows(e.target.value);
            setPageSize(e.target.value);
            onPageChange(0);
            handlePageChange(0);
          }}
        />
      </div>
    </>
  );
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== "number";

export default injectIntl(Table);
