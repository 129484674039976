import gql from 'graphql-tag';

export const COMMERCIAL_CAMPAIGN_CREATE = gql`
  mutation commercialCampaignCreate($input: CommercialCampaignCreateInput!){
    commercialCampaignCreate(input: $input){
        name
        id
        bid
    }
  }
`;

export const INTERNAL_CAMPAIGN_CREATE = gql`
  mutation internalCampaignCreate($input: InternalCampaignCreateInput!){
    internalCampaignCreate(input: $input){
        name
        id
        bid
    }
  }
`;

