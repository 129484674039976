import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { pathOr } from 'rambda';
import FormikField from 'components-lib/FormikField/FormikField';
import { validationSchemaStep3, validationSchemaStep3Internal } from "./validationSchema";
import { injectIntl } from 'react-intl';
import { makeStyles } from "@material-ui/core/styles";
import styles from "common/styles/widgets.js";
import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
import Form from "components-lib/CampaignWidget/component/form/Form";
import HoursCard from "components-lib/HoursCard/HoursCard";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormHelperText from "@material-ui/core/FormHelperText";
import colors from "common/styles/colors";
import { commercialCampaignCreate, internalCampaignCreate } from 'configFiles/roles';

const useStyles = makeStyles((theme) => styles(theme));

const Step3 = ({ intl, callback, callbackCriteria, callbackValid, initialData, companyId, times, callbackTimes, criteria, axios, campaignType }) => {

  const { data: companyData } = useQuery(COMPANY_DETAIL, { variables: { id: companyId } });
  const classes = useStyles();
  const [budgetCondition, setBudgetCondition] = useState(false)

  let balance = pathOr('', ['company', 'account', 'balance'], companyData)
  const getRemains = (cred) => {
    let final = (cred)
      ? Number(balance) - Number(cred)
      : balance
    return <>
      {(final >= 0 && cred) && <FormHelperText style={{ color: colors.success }} className={classes.labelRootSuccess}>
        {intl.formatMessage({ id: 'campaigns.infoCard.creditStatus' })} {final} C
      </FormHelperText>
      }

    </>
  }

  let campaignCreate = (campaignType === "Internal") ? internalCampaignCreate : commercialCampaignCreate;

  const getBudget = (budget, maxBid) => {
    if (Number(budget) >= Number(maxBid)) setBudgetCondition(true)
    else setBudgetCondition(false)
    return <>
      {(Number(budget) < Number(maxBid) && maxBid) && <FormHelperText style={{ color: colors.danger }} className={classes.labelRootSuccess}>
        {(budget, (intl.formatMessage({ id: 'validation.number.max' }, [budget])))}
      </FormHelperText>}
    </>
  }

  const [value, setValue] = useState(criteria);
  const [campaign, setCampaign] = useState({ companyId: companyId, type: campaignType })

  const onChange = useCallback((value) => {
    setValue(value);
    callbackCriteria(value)
  }, [])

  useEffect(() => {
    callbackValid(true)
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialData}
        validationSchema={(campaignType === "Commercial") ? validationSchemaStep3(intl, balance, null) : validationSchemaStep3Internal(intl)}
      >

        {formikProps => (
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  {(campaignType === "Commercial")
                    ? callbackValid(formikProps.isValid && formikProps.values.budget && formikProps.values.maxBid && (budgetCondition === true)
                      && (formikProps.values.budget <= balance))
                    : callbackValid(true)
                  }
                  {callback(formikProps.values)}

                  {(campaignType === "Commercial") &&
                    <Paper style={{ padding: "10px" }}>
                      <Typography variant="h5" component="h2">
                        {intl.formatMessage({ id: 'campaigns.wizard.step3.budget' })}
                      </Typography>

                      <FormikField
                        name="budget"
                        labelText={
                          <p>
                            {intl.formatMessage({ id: 'campaigns.wizard.step3.maxBudget' })}
                            {" (Max. " + balance + " C" + ")"}
                          </p>
                        }
                        formikProps={formikProps}
                        data={formikProps.values}
                        highlightChange
                      />
                      {getRemains(formikProps.values?.budget)}

                      <FormikField
                        name="maxBid"
                        labelText={intl.formatMessage({ id: 'campaigns.wizard.step3.maxBid' })}
                        formikProps={formikProps}
                        data={formikProps.values}
                        highlightChange
                      />
                      {getBudget(formikProps.values?.budget, formikProps?.values?.maxBid)}
                    </Paper>
                  }
                </Grid>
                <Grid item sm={12}>
                  <Paper style={{ padding: "10px" }}>
                    <Form
                      axios={axios}
                      campaign={campaign}
                      value={value}
                      onChange={onChange}
                    />
                  </Paper>
                </Grid>
              </Grid>


            </Grid>
            <Grid item sm={6}>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <Paper style={{ padding: "10px" }}>
                    <Typography variant="h5" component="h2">
                      {intl.formatMessage({ id: 'campaigns.wizard.step3.timePlan' })}
                    </Typography>
                    <FormikField
                      name="minSpotInterval"
                      labelText={intl.formatMessage({ id: "campaigns.scheduleCard.repeat" })}
                      formikProps={formikProps}
                      data={formikProps.values}
                      highlightChange
                    />
                    <br />
                    <HoursCard
                      update={campaignCreate}
                      tms={times}
                      callbackTimes={(newValue) => callbackTimes(newValue)}
                      formikProps={formikProps}
                      withoutOpenInfo={true}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        )}
      </Formik>

    </>
  );
}

export default injectIntl(Step3);