import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import RadioIcon from '@material-ui/icons/Radio';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgetStyle.js";
import Button from "components/CustomButtons/Button";
import { generatePath } from "react-router-dom";
import paths from "paths";
import WarningIcon from '@material-ui/icons/Warning';
import { deviceList } from 'configFiles/roles'
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => styles(theme));

const DevicesCard = (props) => {
    const {
        history,
        intl,
        allDevices,
        devicesOnStores,
        devicesOnRepository,
        offlineDevices
    } = props;

    const classes = useStyles();

    const renderDetailButtons = () => {
        if (deviceList)
            return (
                <Box >
                    <Button
                        className={classes.floatRight}
                        color="info"
                        size="sm"
                        round
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.devices.list)}`
                        })}
                    >
                        {intl.formatMessage({ id: "dashboardCard.devices.title" })}
                    </Button>
                </Box>
            )
    }

    const renderofflineDevices = () => {
        if (offlineDevices > 0) {
            return (
                <div style={{ color: '#E30404' }}>
                    {intl.formatMessage({ id: "dashboardCard.devices.offlineDevices" })}
                    <Box className={classes.floatRight} >
                        <span className={classes.warningIcon} ><ErrorIcon style={{ fontSize: 18 }} /></span>{offlineDevices}
                    </Box>
                </div>
            )
        }

        return <>
            {intl.formatMessage({ id: "dashboardCard.devices.offlineDevices" })}
            <Box className={classes.floatRight}>
                {offlineDevices}
            </Box>
        </>
    }

    return <>
        <IconCard
            title={intl.formatMessage({ id: "dashboardCard.devices.title" })}
            icon={<RadioIcon />}
            height="80%"
            actions={renderDetailButtons()}
        >
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.devices.allDevices" })}
                    <Box className={classes.floatRight}>
                        {allDevices}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.devices.devicesOnStores" })}
                    <Box className={classes.floatRight}>
                        {devicesOnStores}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.devices.devicesOnRepository" })}
                    <Box className={classes.floatRight}>
                        {devicesOnRepository}
                    </Box>
                </Grid>

                <Grid item sm={12}>
                    {renderofflineDevices()}
                </Grid>
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(DevicesCard);
