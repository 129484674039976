import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { pathOr } from "rambda";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PageHeader from "components-lib/PageHeader/PageHeader";
import CompaniesAndStoresCard from "./DashboardCards/CompaniesAndStoresCard"
import DevicesCard from "./DashboardCards/DevicesCard"
import PartnersCard from "./DashboardCards/PartnersCard"
import StreamsAndMediasCard from "./DashboardCards/StreamsAndMediasCard"
import InvoicesAndPayoutsCard from "./DashboardCards/InvoicesAndPayoutsCard"
import CommissionsCard from "./DashboardCards/CommissionsCard"
import CommissionsChart from "./DashboardCards/CommissionsChart"
import CampaignsCard from "./DashboardCards/CampaignsCard"
import { OVERVIEW } from "queries/DashboardQueries/overview"
import {
    dashboardList, deviceList, companyList, storeList, userList, invoiceList, payoutList,
    streamList, mediaList, commercialCampaignList, internalCampaignList, commissionList
} from 'configFiles/roles'
import Paper from '@material-ui/core/Paper';

const DashboardPage = (props) => {
    const { intl, history } = props;
    const { data: overwiev } = useQuery(OVERVIEW, { skip: !dashboardList });

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "dashboardPage.title" })}
                withBackButton={false}
            />
            <Grid container>
                <Grid container spacing={2} style={{ marginBottom: "-30px" }}>
                    {deviceList &&
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <DevicesCard
                                allDevices={pathOr(null, ['overview', 'devices', 'totalCount'], overwiev)}
                                devicesOnStores={pathOr(null, ['overview', 'devices', 'assignedCount'], overwiev)}
                                devicesOnRepository={pathOr(null, ['overview', 'devices', 'unassignedCount'], overwiev)}
                                offlineDevices={pathOr(null, ['overview', 'devices', 'offlineCount'], overwiev)}
                                history={history}
                            />
                        </Grid>
                    }
                    {(companyList || storeList) &&
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <CompaniesAndStoresCard
                                allCompanies={pathOr(null, ['overview', 'companies', 'totalCount'], overwiev)}
                                allStores={pathOr(null, ['overview', 'stores', 'totalCount'], overwiev)}
                                storesWithoutDevice={pathOr(null, ['overview', 'stores', 'noPlayerCount'], overwiev)}
                                history={history}
                            />
                        </Grid>
                    }
                    {(userList) &&
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <PartnersCard
                                allUsers={pathOr(null, ['overview', 'users', 'totalCount'], overwiev)}
                                activatedUsers={pathOr(null, ['overview', 'users', 'activatedCount'], overwiev)}
                                unactivatedUsers={pathOr(null, ['overview', 'users', 'unactivatedCount'], overwiev)}
                                blockedUsers={pathOr(null, ['overview', 'users', 'blockedCount'], overwiev)}
                                history={history}
                            />
                        </Grid>
                    }
                    {(invoiceList || payoutList) &&
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <InvoicesAndPayoutsCard
                                allInvoices={pathOr(null, ['overview', 'invoices', 'totalCount'], overwiev)}
                                invoicesVolume={pathOr(null, ['overview', 'invoices', 'totalPrice'], overwiev)}
                                allPayouts={pathOr(null, ['overview', 'payouts', 'totalCount'], overwiev)}
                                payoutsVolume={pathOr(null, ['overview', 'payouts', 'totalPrice'], overwiev)}
                                history={history}
                            />
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={2}>
                    {(streamList || mediaList) &&
                        <Grid item xs={12} sm={12} md={6} lg={4} >
                            <StreamsAndMediasCard
                                allStreams={pathOr(null, ['overview', 'streams', 'totalCount'], overwiev)}
                                mostListenedStream={pathOr(null, ['overview', 'streams', 'mostListened', 'name'], overwiev)}
                                allMedia={pathOr(null, ['overview', 'media', 'totalCount'], overwiev)}
                                awaitingMedia={pathOr(null, ['overview', 'media', 'awaitingApprovalCount'], overwiev)}
                                history={history}
                            />
                        </Grid>
                    }
                    {(commercialCampaignList || internalCampaignList) &&
                        <Grid item xs={12} sm={12} md={6} lg={4} >
                            <CampaignsCard
                                allCommercialCampaigns={pathOr(null, ['overview', 'commercialCampaigns', 'runningCount'], overwiev)}
                                awaitingCommercialCampaigns={pathOr(null, ['overview', 'commercialCampaigns', 'awaitingApprovalCount'], overwiev)}
                                allInternalCampaigns={pathOr(null, ['overview', 'internalCampaigns', 'runningCount'], overwiev)}
                                awaitingInternalCampaigns={pathOr(null, ['overview', 'internalCampaigns', 'awaitingApprovalCount'], overwiev)}
                                history={history}
                            />
                        </Grid>
                    }
                    {(commissionList) &&
                        <Grid item xs={12} sm={12} md={6} lg={4} >
                            <CommissionsChart
                                commissionsData={pathOr(null, ['overview', 'commissions'], overwiev)}
                                actualMonth={new Date().getMonth() + 1}
                                history={history}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(injectIntl(DashboardPage));