import gql from 'graphql-tag';

const ALL_STREAM_NAMES = gql`
    query streams{
        streams{
            items{
                id
                name
            }
        }
    }
`;

export default ALL_STREAM_NAMES;