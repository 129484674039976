import * as Yup from 'yup';

export const getValidationSchema = (intl) =>
    Yup.object().shape({
        companyId: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        price: Yup.number()
            .min(1, (intl.formatMessage({ id: 'validation.number.positive' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        credit: Yup.number()
            .min(1, (intl.formatMessage({ id: 'validation.number.positive' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        identifier: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        description: Yup.string()
            .max(100, intl.formatMessage({ id: 'validation.string.max' }, [100]))
            .nullable()
    });

export const getValidationSchemaPayout = (intl, maxCredits) =>
    Yup.object().shape({
        companyId: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        price: Yup.number()
            .min(1, (intl.formatMessage({ id: 'validation.number.positive' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        credit: Yup.number()
            .min(1, (intl.formatMessage({ id: 'validation.number.positive' })))
            .max(maxCredits, (intl.formatMessage({ id: 'validation.number.max' }, [maxCredits])))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        identifier: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        description: Yup.string()
            .max(100, intl.formatMessage({ id: 'validation.string.max' }, [100]))
            .nullable()
    });
