import StoreIcon from '@material-ui/icons/Store';
import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import paths from "paths";
import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from 'react-router-dom';
import { injectIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import StoreTable from "./StoreTable";
import ALL_STORES from 'queries/StoresQueries/allStores';
import { storeCreate, storeList, storeDetail } from 'configFiles/roles'
import Button from "components/CustomButtons/Button.js";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import PropTypes from 'prop-types';
import StoreMultipleUpdate from './StoreMultipleUpdate'
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import styles from "common/styles/widgets.js";
import STORES from "queries/StoresQueries/stores";
import { pathOr } from "rambda";
import STORE_DETAIL from "queries/StoresQueries/storeDetail";
import Modal from "components-lib/Modal/Modal";
import Input from "@material-ui/core/Input";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";

const drawerWidth = "50%";
const useStylesModal = makeStyles((theme) => styles(theme));
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#F5F7F7',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const StoreListPage = (props) => {
  const { intl, history } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null
  const [button, setButton] = useState([])
  const classes = useStyles();
  const classesModal = useStylesModal();
  const [open, setOpen] = useState(false);
  const [tableOpen, setTableOpen] = useState(true);
  const [value, setValue] = useState(0);
  const [allStoreIds, setAllStoreIds] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [stores, setStores] = useState([]);
  const [loadStores, { data: dataStores, refetch: refetchStores }] = useLazyQuery(ALL_STORES);
  const { data: storesData } = useQuery(ALL_STORES, { skip: !storeList });

  const { data: storeData, loading: storeLoading, refetch: refetchData } = useQuery(STORES, {
    variables: {
      filter: {
        id: {
          in: (button) ? (button) : [15]
        }
      },
    }, skip: !storeDetail
  });

  const { data: storeDetailData } = useQuery(STORE_DETAIL, {
    variables: {
      id: storeId,
    },
    skip: !storeId
  });

  useEffect(() => {
    let storesSelectItems = pathOr([], "storesTable.items", storesData).map(
      (store) => {
        return {
          value: pathOr("", "id", store),
          label: `${pathOr("", "id", store)} - ${pathOr(
            "",
            "name",
            store
          )}`,
        };
      }
    );
    setStores(storesSelectItems);

    let storeIds = pathOr([], "storesTable.items", storesData).map(
      (store) => {
        return Number(pathOr("", "id", store))
      }

    );
    setAllStoreIds(storeIds)
  }, [storesData]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getActions = () => {
    if (!storeCreate) {
      return;
    }
    return [
      {
        title: intl.formatMessage({ id: "storeList.addModal.title" }),
        onClick: () =>
          history.push(`/admin${generatePath(paths.stores.new)}`),
      },
      {
        title: intl.formatMessage({ id: "storeList.addSimilar.title" }),
        onClick: () => setModalOpen(true)
      },
    ];
  };

  const renderTables = () => {
    return (
      <StoreTable
        data={dataStores}
        loadData={loadStores}
        refetch={refetchStores}
        defaultFilter={defaultFilter}
        history={history}
        queryDataPath={['storesTable', 'items']}
        callbackButton={(newValue) => setButton(newValue)}
        handleDrawerOpen={handleDrawerOpen}
        allStoreIds={allStoreIds}
        button={button}
        open={open}
        checkboxActive={true}
      />
    );
  };

  const renderNavigation = () => {
    return (<div style={{ margin: "30px" }}>
      <StoreMultipleUpdate
        button={button}
        storeData={storeData}
        storeLoading={storeLoading}
        refetchData={refetchData}
        refetchStores={refetchStores}
        callbackOpen={(newValue) => setOpen(newValue)}
        callbackTableOpen={(newValue) => setTableOpen(newValue)}
        handleDrawerOpen={handleDrawerOpen}
      />

    </div>)
  }

  const renderDrawer = () => {
    return (
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <Drawer
          color="primary"
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Grid container style={{ paddingTop: "40px" }}>
              <Grid item sm={12} >
                <span style={{ fontSize: "1.8em", paddingLeft: "40px" }}>{intl.formatMessage({ id: "storeList.multiUpdate.title" })}</span>
                <Box className={classesModal.floatRight}>
                  {/*<IconButton onClick={handleDrawerClose}>
                    <RefreshIcon />
                    </IconButton> */}
                  <IconButton onClick={handleDrawerClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </div>
          {renderNavigation()}
        </Drawer>
      </Dialog>
    )
  }


  const renderModalButtons = () => {
    return (
      <>
        <Button
          disabled={(storeId === null)}
          onClick={() =>
            history.push({
              pathname: `/admin${generatePath(paths.stores.new)}`,
              state: storeDetailData
            })}
          color="success"
          round
          size="sm">
          {intl.formatMessage({ id: "storeList.multiCreate.button.continue" })}
        </Button>
        <Button
          onClick={() => setModalOpen(false)}
          color="primary"
          round
          size="sm"
        >
          {intl.formatMessage({ id: "deviceList.assignModal.cancel" })}
        </Button>
      </>
    );
  };


  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: "storeList.title" })}
        actions={getActions()}
        withBackButton={location.state ? true : false}
      />
      <IconCard
        icon={<StoreIcon />}
        title={intl.formatMessage({ id: "storeList.table.title" })}
      >
        {renderTables()}
        {renderDrawer()}
      </IconCard>
      {tableOpen &&
        <>
          {open &&
            <Box
              //bgcolor='#e0e0e0'
              style={{ padding: "20px", maxWidth: "40%", left: "5%", position: "fixed", top: "5%" }}
              display="flex"
              alignItems="center"
              zIndex="tooltip"
            >
              <IconCard
                icon={<StoreIcon />}
                title={intl.formatMessage({ id: "storeList.multiUpdate.table.title" })}
              >
                <StoreTable
                  query={ALL_STORES}
                  defaultFilter={{
                    "id": {
                      "in": button
                    }
                  }
                  }
                  numberOfRows={5}
                  multiUpdate={true}
                  history={history}
                  queryDataPath={['storesTable', 'items']}
                  callbackButton={(newValue) => setButton(newValue)}
                  button={button}
                />
              </IconCard>
            </Box>
          }
        </>
      }


      <Modal
        title={intl.formatMessage({ id: "storeList.multiCreate.modal.title" })}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth={true}
        actions={renderModalButtons()}
      >
        <Grid container className={classes.selectStyle} spacing={2}>
          <Grid item sm={12}>
            <AutocompleteSelect
              label={intl.formatMessage({ id: "deviceList.table.store" })}
              placeholder={intl.formatMessage({ id: "deviceList.assignModal.store", })}
              options={stores}
              onChange={(item) => setStoreId(item.value)}
              input={<Input />}
            />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
export default withRouter(injectIntl(StoreListPage));