import gql from 'graphql-tag';

export const COMMERCIAL_CAMPAIGN_UPDATE = gql`
    mutation commercialCampaignUpdate($id: ID!, $input: CommercialCampaignUpdateInput!){
        commercialCampaignUpdate(id: $id, input: $input){
            id
            bid
            name
        }
    }
`;

export const INTERNAL_CAMPAIGN_UPDATE = gql`
    mutation internalCampaignUpdate($id: ID!, $input: InternalCampaignUpdateInput!){
        internalCampaignUpdate(id: $id, input: $input){
            id
            bid
            name
        }
    }
`;

