import { Grid } from "@material-ui/core";
import Favorite from "@material-ui/icons/Favorite";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import FormikField from "../../../components-lib/FormikField/FormikField";
import FormControl from "@material-ui/core/FormControl";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import { useQuery } from "@apollo/react-hooks";
import { ALL_TOPICS } from "../queries/allTopics";
import ALL_COMPANIES from "queries/CompaniesQueries/selectCompanies"
import { pathOr } from "rambda";

const InfoCard = (props) => {
    const {
        intl,
        formikProps,
        callbackSpotType,
        callback,
        internal,
        callbackCompanyId
    } = props;

    let copmanyId = (formikProps.values.campaign?.companyId) ? (formikProps.values.campaign.companyId) : null
    if (copmanyId) {
        callbackCompanyId(copmanyId)
    }
    const { data: topicsData } = useQuery(ALL_TOPICS)
    const getTopicOptions = () => {
        const topicOptions = [];
        pathOr([], ['topics', 'items'], topicsData).map(topic => {
            topicOptions.push({
                label: topic.name,
                value: topic.id
            })
            return ""
        })
        return topicOptions
    }

    const { data: companiesData } = useQuery(ALL_COMPANIES)

    console.log(companiesData)

    const getCompaniesOptions = () => {
        const comapniesOptions = [];
        pathOr([], ['companies', 'items'], companiesData).map(company => {
            comapniesOptions.push({
                value: pathOr("", "id", company),
                label: `${pathOr("", "name", company)}`,
            })
            return ""
        })
        return comapniesOptions
    }

    const spotTypeOptions = [
        {
            value: "Announcement",
            label: intl.formatMessage({ id: 'campaigns.infoCard.Announcement' })
        },
        {
            value: "Advertisement",
            label: intl.formatMessage({ id: 'campaigns.infoCard.Advertisement' })
        },
    ]

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.infoCard.title' })}
            icon={<Favorite />}
        >
            <>
                <Grid container>

                    <FormikField
                        name="campaign.name"
                        labelText={intl.formatMessage({ id: 'campaigns.infoCard.name' })}
                        formikProps={formikProps}
                        data={formikProps.values}
                        highlightChange
                    />

                    <FormikField
                        type="select"
                        name="campaign.companyId"
                        labelText={intl.formatMessage({ id: 'campaigns.infoCard.company' })}
                        options={getCompaniesOptions()}
                        formikProps={formikProps}
                        highlightChange
                    />

                    <FormControl fullWidth>
                        <AutocompleteSelect
                            placeholder={intl.formatMessage({ id: 'campaigns.infoCard.topics' })}
                            isMulti
                            onChange={(e) => callback(e)}
                            options={getTopicOptions()}
                        />
                    </FormControl>
                    {internal &&
                        <FormControl
                            fullWidth
                            style={{ marginTop: "15px" }}>
                            {intl.formatMessage({ id: 'campaigns.infoCard.type' })}
                                <AutocompleteSelect

                                defaultValue={spotTypeOptions[1]}
                                placeholder={intl.formatMessage({ id: 'campaigns.infoCard.chooseType' })}
                                onChange={(e) => callbackSpotType(e)}
                                options={spotTypeOptions}
                            />
                        </FormControl>
                    }

                    <FormikField
                        name="campaign.description"
                        labelText={intl.formatMessage({ id: 'campaigns.infoCard.description' })}
                        helperText={100 - pathOr('', ['values', 'campaign', 'description'], formikProps).length}
                        formikProps={formikProps}
                        data={formikProps.values}
                        highlightChange
                    />
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <FormikField
                                //disabled={true}
                                name="campaign.validFrom"
                                labelText={intl.formatMessage({ id: 'campaigns.infoCard.startDate' })}
                                formikProps={formikProps}
                                data={formikProps.values}
                                highlightChange
                                type='dateTime'
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <FormikField
                                name="campaign.validTo"
                                labelText={intl.formatMessage({ id: 'campaigns.infoCard.endDate' })}
                                formikProps={formikProps}
                                data={formikProps.values}
                                highlightChange
                                type='dateTime'
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </>
        </IconCard>
    </>
};

export default injectIntl(InfoCard);
