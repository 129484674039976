import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import IconCard from "components-lib/IconCard/IconCard";
import Button from "components/CustomButtons/Button";
import { useMutation, useQuery } from '@apollo/react-hooks';
import DETAIL_GROUP from 'queries/GroupsQueries/detailGroup';
import Checkbox from "@material-ui/core/Checkbox";
import UPDATE_GROUP from 'queries/GroupsQueries/updateGroup';
import styles from "common/styles/widgets.js";
import stylesCheckbox from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { pathOr } from 'rambda';
import { useSnackbar } from "notistack";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { colors } from "common/styles/configLayout.js";
import Loading from "components-lib/Loading/Loading";
import FormikField from 'components-lib/FormikField/FormikField';
import { Formik } from 'formik';
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import Input from "@material-ui/core/Input";
import PermissionEnum from "common/enums/PermissionEnum"
import validationSchema from '../validationSchema';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import { scope } from 'common/enums/GroupsPermissionEnum'
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => styles(theme));
const useStylesCheckbox = makeStyles(stylesCheckbox);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: colors.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const PrivilegeCard = (props) => {
  const { intl, groups, callback, groupUpdate } = props;
  const classes = useStyles();
  const classesCheckbox = useStylesCheckbox();
  const { enqueueSnackbar } = useSnackbar();
  const [perm, setPerm] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const [checked, setChecked] = useState([]);
  const [groupId, setGroupId] = useState(null)
  const { data: groupData, loading: groupLoading, refetch: groupRefetch } = useQuery(DETAIL_GROUP, { variables: { id: groupId }, skip: !groupId });
  const [updateGroup] = useMutation(UPDATE_GROUP);

  useEffect(() => {
    setPerm(pathOr([], ['userGroup', 'permissions'], groupData));
    setDataGroup(pathOr({}, ['userGroup'], groupData));
  }, [groupData]);

  const handleUpdateGroup = (values) => {
    updateGroup({
      variables: {
        id: groupId,
        input: {
          name: values.name,
          description: values.description,
          permissions: perm
        }
      },
    }).then((response) => {
      enqueueSnackbar(intl.formatMessage({ id: 'groups.success.update' }), { variant: "success" });
      groupRefetch();
      callback(1)
    })
      .catch((err) => {
        console.log('[error]', err)
      });
  }

  const handleToggle = (value, param, idx) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    let help = perm
    let helpNum = 0

    if (help.length !== 0) {
      help.map((item, index) => {
        if (param !== null) {
          if (item.includes(value)) {
            delete help[index];
            helpNum = 1

          } else {
            helpNum = 1
          }
        } else {
          if (item.includes(value)) {
            delete help[index];
          }
        }
        return ""
      });
    }
    else helpNum = 1

    if (helpNum === 1) help.push(value + ":" + param)
    else if (helpNum === 2) {
      help.push(value + ":" + param)
    }
    let j = 0
    let arr = []
    for (let i = 0; i < help.length; i++) {
      if (help[i]) {
        arr[j] = help[i]
        j++
      }
    }
    setPerm(arr)
  };

  const getCheckbox = (actionParam, param) => {
    let help = null
    perm.filter(action => action.includes(actionParam)).map(item => (
      help = item.substr(item.length - 3)
    ))

    if (help === param) return true
    else return false
  }

  if (groupLoading) return <Loading />
  return <>
    <Formik
      enableReinitialize
      initialValues={dataGroup}
      onSubmit={(values) => { handleUpdateGroup(values) }}
      validationSchema={validationSchema(intl)}
    >
      {(formikProps) => (
        <>
          <IconCard
            title={intl.formatMessage({ id: 'groups.update.title' })}
            icon={<VpnKeyIcon />}
          >
            <>
              <Grid item sm={12}>
                <hr style={{ margin: "10px" }} />
              </Grid>
              {groupId ?
                <Grid container spacing={1}>
                  <Grid item sm={6}>
                    <h4>{intl.formatMessage({ id: 'groups.update.setGroup' })}: {pathOr([], ['userGroup', 'name'], groupData)}</h4>
                  </Grid>
                  <Grid item sm={6}>
                    <AutocompleteSelect
                      style={{ marginBottom: "15px" }}
                      placeholder={intl.formatMessage({ id: 'groups.choosenGroup' })}
                      //label="Skupina"
                      options={groups}
                      value={groupId}
                      onChange={(e) => setGroupId(e.value)}
                      input={<Input />}
                    />
                  </Grid>
                </Grid>
                :
                <Grid container spacing={1}>
                  <Grid item sm={12}>
                    <AutocompleteSelect
                      style={{ marginBottom: "15px" }}
                      placeholder={intl.formatMessage({ id: 'groups.choosenGroup' })}
                      options={groups}
                      onChange={(e) => setGroupId(e.value)}
                      input={<Input />}
                    />
                  </Grid>
                </Grid>
              }

              <Grid item sm={12}>
                <hr style={{ margin: "10px" }} />
              </Grid>
            </>
            {groupId &&
              <>
                {Object.values(scope).map((item) => (
                  <>
                    <TableContainer component={Paper}>
                      <Table className={classesCheckbox.table} size="small" aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell ><b>{intl.formatMessage({ id: `groups.scope.${item}` })}</b></StyledTableCell>
                            <StyledTableCell ></StyledTableCell>
                            <StyledTableCell ></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell ></StyledTableCell>
                            <StyledTableCell ></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell ><b>{intl.formatMessage({ id: 'groups.table.name' })}</b></TableCell>
                            <TableCell ><b>{intl.formatMessage({ id: 'groups.table.action' })}</b></TableCell>
                            <TableCell ><b>{intl.formatMessage({ id: 'groups.table.disabled' })}</b></TableCell>
                            <TableCell><b>{intl.formatMessage({ id: 'groups.table.own' })}</b></TableCell>
                            <TableCell ><b>{intl.formatMessage({ id: 'groups.table.all' })}</b></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {PermissionEnum?.filter(action => action.scope.includes(item)).map((row, index) => (
                            <TableRow key={row.id}>
                              <StyledTableCell component="th" scope="row">
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell >{intl.formatMessage({ id: `groups.scope.${row.scope}` })}</StyledTableCell>
                              <StyledTableCell >{intl.formatMessage({ id: `groups.action.${row.action}` })}</StyledTableCell>
                              <StyledTableCell >
                                {(groupUpdate) ?
                                  <Checkbox
                                    key="key"
                                    tabIndex={-1}
                                    checked={getCheckbox(row.scope + ":" + row.action, null)}
                                    onClick={() => handleToggle(row.scope + ":" + row.action, null, index)}
                                    checkedIcon={<Check className={classesCheckbox.checkedIcon} />}
                                    icon={<Check className={classesCheckbox.uncheckedIcon} />}
                                    classes={{
                                      checked: classesCheckbox.checked,
                                      root: classesCheckbox.checkRoot
                                    }}
                                  />
                                  :
                                  (getCheckbox(row.scope + ":" + row.action, null)) && <CheckIcon />
                                }
                              </StyledTableCell>
                              <StyledTableCell >
                                {(row.parameters[1])
                                  ? (groupUpdate)
                                    ? <Checkbox
                                      key="key"
                                      tabIndex={-1}
                                      checkedIcon={<Check className={classesCheckbox.checkedIcon} />}
                                      icon={<Check className={classesCheckbox.uncheckedIcon} />}
                                      classes={{
                                        checked: classesCheckbox.checked,
                                        root: classesCheckbox.checkRoot
                                      }}
                                      checked={getCheckbox(row.scope + ":" + row.action, "own")}
                                      onClick={() => handleToggle(row.scope + ":" + row.action, "own")}
                                    />
                                    : (getCheckbox(row.scope + ":" + row.action, "own")) && <CheckIcon />
                                  : <> &nbsp;&nbsp;&nbsp;<RemoveIcon /></>
                                }
                              </StyledTableCell>
                              <StyledTableCell >
                                {(groupUpdate) ?
                                  <Checkbox
                                    key="key"
                                    tabIndex={-1}
                                    checkedIcon={<Check className={classesCheckbox.checkedIcon} />}
                                    icon={<Check className={classesCheckbox.uncheckedIcon} />}
                                    classes={{
                                      checked: classesCheckbox.checked,
                                      root: classesCheckbox.checkRoot
                                    }}
                                    checked={getCheckbox(row.scope + ":" + row.action, "all")}
                                    onClick={() => handleToggle(row.scope + ":" + row.action, "all")}
                                  />
                                  :
                                  (getCheckbox(row.scope + ":" + row.action, "all")) && <CheckIcon />
                                }
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                  </>
                ))}
                < br />
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <FormikField
                      name="name"
                      formikProps={formikProps}
                      labelText={intl.formatMessage({ id: 'groups.form.name' })}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <FormikField
                      name="description"
                      formikProps={formikProps}
                      helperText={100 - pathOr('', ['values', 'description'], formikProps).length}
                      labelText={intl.formatMessage({ id: 'groups.form.description' })}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    < br />
                  </Grid>
                </Grid>
              </>
            }

          </IconCard>
          {(groupUpdate && groupId) &&
            < div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 1 }} >
              <Button
                color="primary"
                round
                onClick={(e) => { formikProps.handleSubmit() }}
              >
                {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
              </Button>
              <Button
                round
                onClick={() => formikProps.resetForm()}
              >
                {intl.formatMessage({ id: 'settings.cancel' })}
              </Button>
            </div>
          }
        </>
      )}
    </Formik>
  </>

};

export default injectIntl(PrivilegeCard);
