import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js"; 
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

const Modal = (props) => {
    const {children, title, open ,onClose, actions, maxWidth = 'sm', fullWidth = false, backDrop} = props;
    const classes = useStyles();
    return(
        <Dialog
            open={open}
            transition={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            BackdropProps= {(backDrop) && {
                classes: {
                    root: classes.backDrop
                }
            }}
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => onClose()}
                >
                    <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>{title}</h4>
            </DialogTitle>
            <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}>
                {children}
            </DialogContent>
            <DialogActions>
                {actions}
            </DialogActions>
      </Dialog>
    )
}

export default Modal;
