import React from 'react';
import Select from 'react-select';
//import Select from '@material-ui/core/Select';
import { colors } from "common/styles/configLayout.js";
import FormControl from "@material-ui/core/FormControl";
import { grayColor, } from "assets/jss/material-dashboard-pro-react.js";
const AutocompleteSelect = (props) => {

    const {
        isMulti = false,
        onChange,
        label,
        className,
        placeholder,
        options,
        value,
        disabled,
        defaultValue = []
    } = props

    /*const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
            padding: 20,
        }),
        borderRadius: 0,
        colors: {

            primary25: 'hotpink',
            primary: 'black',
        },
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }
    //console.log("className", className)
    */
    const colourStyles = {
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                //backgroundColor: isDisabled ? 'red' : 'blue',
                color: 'black',
                //cursor: isDisabled ? 'not-allowed' : 'default',   
            };
        },
    };

    return (
        <FormControl fullWidth>
            {label && <p style={{ color: grayColor[3], marginBottom: "5px" }}>{label}:</p>}
            <Select
                isDisabled={disabled}
                defaultValue={defaultValue}
                isMulti={isMulti}
                styles={colourStyles}
                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: '#838E8D',
                        primary: '#838E8D',
                    },
                })}
                //menuColor='red'
                menuPortalTarget={document.body}
                onChange={onChange}
                className={className}
                placeholder={placeholder}
                options={options}
                value={value}
            />
        </FormControl>
    )
}

export default AutocompleteSelect;