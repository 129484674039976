import React from 'react';
import { Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import ALL_COMMISSION_STORES from 'queries/CommissionsQueries/allCommissionsStores';
import ALL_COMMISSIONS from 'queries/CommissionsQueries/allCommissions';
import { useQuery } from '@apollo/react-hooks';
import { pathOr } from 'rambda';
//import Box from "@material-ui/core/Box";
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import IconCard from "components-lib/IconCard/IconCard";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
  Legend, ResponsiveContainer
} from 'recharts';
import { defaultColors } from "common/styles/configLayout.js";

const CommissionCard = (props) => {
  const { companyId, intl } = props;

  const getNumberMonth = (month) => {
    let helpMonth = month;
    if (month === 0) helpMonth = 12
    if (month === -1) helpMonth = 11
    if (month === -2) helpMonth = 10
    if (month === -3) helpMonth = 9
    if (month === -4) helpMonth = 8
    return helpMonth
  }

  let actualMonth = new Date().getMonth() + 1;
  let actualYear = new Date().getFullYear();
  let filterActual = (actualMonth < 10) ? (actualYear + "-0" + actualMonth) : (actualYear + "-" + actualMonth)
  let filterMOne = ((getNumberMonth(actualMonth - 1)) < 10)
    ? (((getNumberMonth(actualMonth - 1) > actualMonth) ? (actualYear - 1) : actualYear) + "-0" + (getNumberMonth(actualMonth - 1)))
    : (((getNumberMonth(actualMonth - 1) > actualMonth) ? (actualYear - 1) : actualYear) + "-" + (getNumberMonth(actualMonth - 1)))
  let filterMTwo = ((getNumberMonth(actualMonth - 2)) < 10)
    ? (((getNumberMonth(actualMonth - 2) > actualMonth) ? (actualYear - 1) : actualYear) + "-0" + (getNumberMonth(actualMonth - 2)))
    : (((getNumberMonth(actualMonth - 2) > actualMonth) ? (actualYear - 1) : actualYear) + "-" + (getNumberMonth(actualMonth - 2)))
  let filterMThree = ((getNumberMonth(actualMonth - 3)) < 10)
    ? (((getNumberMonth(actualMonth - 3) > actualMonth) ? (actualYear - 1) : actualYear) + "-0" + (getNumberMonth(actualMonth - 3)))
    : (((getNumberMonth(actualMonth - 3) > actualMonth) ? (actualYear - 1) : actualYear) + "-" + (getNumberMonth(actualMonth - 3)))
  let filterMFour = ((getNumberMonth(actualMonth - 4)) < 10)
    ? (((getNumberMonth(actualMonth - 4) > actualMonth) ? (actualYear - 1) : actualYear) + "-0" + (getNumberMonth(actualMonth - 4)))
    : (((getNumberMonth(actualMonth - 4) > actualMonth) ? (actualYear - 1) : actualYear) + "-" + (getNumberMonth(actualMonth - 4)))

  const { data: commissionsData } = useQuery(ALL_COMMISSION_STORES,
    {
      variables: {
        filter: {
          companyId: {
            eq: Number(companyId)
          },
          period: {
            between: {
              start: filterActual + "-01",
              end: filterActual + "-31"
            }
          },
          interval: {
            eq: "Daily"
          }
        }
      }
    }
  )
  //const { data: allCommissionsData } = useQuery(ALL_COMMISSIONS, { variables: { filter: { companyId: { eq: Number(companyId) } } } })
  const { data: dataMinusOne } = useQuery(ALL_COMMISSIONS, { variables: { filter: { companyId: { eq: Number(companyId) }, period: { between: { start: filterMOne + "-01", end: filterMOne + "-31" } } } } })
  const { data: dataMinusTwo } = useQuery(ALL_COMMISSIONS, { variables: { filter: { companyId: { eq: Number(companyId) }, period: { between: { start: filterMTwo + "-01", end: filterMTwo + "-31" } } } } })
  const { data: dataMinusThree } = useQuery(ALL_COMMISSIONS, { variables: { filter: { companyId: { eq: Number(companyId) }, period: { between: { start: filterMThree + "-01", end: filterMThree + "-31" } } } } })
  const { data: dataMinusFour } = useQuery(ALL_COMMISSIONS, { variables: { filter: { companyId: { eq: Number(companyId) }, period: { between: { start: filterMFour + "-01", end: filterMFour + "-31" } } } } })

  //let allCommissions = pathOr(null, ['companyCommissionsTable', 'totalCount'], allCommissionsData)
  let actual = Object.values(pathOr([], ['zoneCommissionsTable', 'items'], commissionsData)).map(item => { return item.value }).reduce(function (sum, item) { return sum = sum + item; }, 0);
  let minusOne = pathOr(null, ['companyCommissionsTable', 'items', [0], "value"], dataMinusOne)
  let minusTwo = pathOr(null, ['companyCommissionsTable', 'items', [0], "value"], dataMinusTwo)
  let minusThree = pathOr(null, ['companyCommissionsTable', 'items', [0], "value"], dataMinusThree)
  let minusFour = pathOr(null, ['companyCommissionsTable', 'items', [0], "value"], dataMinusFour)

  const getMonth = (month) => {
    let helpMonth = month;
    if (month === 0) helpMonth = 12
    if (month === -1) helpMonth = 11
    if (month === -2) helpMonth = 10
    if (month === -3) helpMonth = 9
    if (month === -4) helpMonth = 8

    switch (helpMonth) {
      case 1: return intl.formatMessage({ id: 'commissionsList.month.january' });
      case 2: return intl.formatMessage({ id: 'commissionsList.month.february' });
      case 3: return intl.formatMessage({ id: 'commissionsList.month.march' });
      case 4: return intl.formatMessage({ id: 'commissionsList.month.april' });
      case 5: return intl.formatMessage({ id: 'commissionsList.month.may' });
      case 6: return intl.formatMessage({ id: 'commissionsList.month.june' });
      case 7: return intl.formatMessage({ id: 'commissionsList.month.july' });
      case 8: return intl.formatMessage({ id: 'commissionsList.month.august' });
      case 9: return intl.formatMessage({ id: 'commissionsList.month.september' });
      case 10: return intl.formatMessage({ id: 'commissionsList.month.october' });
      case 11: return intl.formatMessage({ id: 'commissionsList.month.november' });
      case 12: return intl.formatMessage({ id: 'commissionsList.month.december' });
      default: return helpMonth
    }
  }

  const data = [
    { name: getMonth(actualMonth - 4), com: (minusFour) ? parseFloat(minusFour).toFixed(2) : "0" },
    { name: getMonth(actualMonth - 3), com: (minusThree) ? parseFloat(minusThree).toFixed(2) : "0" },
    { name: getMonth(actualMonth - 2), com: (minusTwo) ? parseFloat(minusTwo).toFixed(2) : "0" },
    { name: getMonth(actualMonth - 1), com: (minusOne) ? parseFloat(minusOne).toFixed(2) : "0" },
    { name: getMonth(actualMonth), com: (actual) ? parseFloat(actual).toFixed(2) : "0" }
  ]

  return (
    <IconCard
      title={intl.formatMessage({ id: "storeDetail.commissionCard.title" })}
      icon={<EuroSymbolIcon />}
    >
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={data}
          margin={{ top: 5, left: -25, right: 10, bottom: 15 }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip formatter={(label) => label + " C"} />
          <Bar name={intl.formatMessage({ id: "storeDetail.commissionCard.com" })} dataKey="com" barSize={30} fill={defaultColors.one} />
          <Legend wrapperStyle={{ bottom: -20 }} />
        </BarChart>
      </ResponsiveContainer>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          { /*getProvisionsTable() */}
        </Grid>
      </Grid>
      <br />
      <hr />
      <Grid container >
        <Grid item sm={12}>
          <b>{intl.formatMessage({ id: "storeDetail.commissionCard.allCommissions" })}: {parseFloat(actual + minusOne + minusTwo + minusThree + minusFour).toFixed(2)} &euro;</b>
        </Grid>
      </Grid>
    </IconCard>
  )
}

export default injectIntl(CommissionCard);
