import gql from 'graphql-tag';

const PAYOUT_CREATE = gql`
    mutation payoutCreate($input: PayoutCreateInput!){
        payoutCreate(input: $input){
        	credit,
        	price,
        	description
        }
    }
`;

export default PAYOUT_CREATE;