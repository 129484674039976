import gql from 'graphql-tag';

const STORE_DETAIL = gql`
    query store($id: ID!){
        store(id:$id){
            id,
            bid,
            name,
            contactFirstName,
            contactLastName,
            contactPhone,
            contactEmail,
            address,
            postal,
            latitude,
            longtitude,
            city,
            country,
            zones{
              id,
              bid,
              name,
              area,
              visitors,
              rank,
              createdAt,
              updatedAt,
              device{
                id
                bid
                isOnline
                onlineAt
                clientVersion
                balenaUuid
                deviceType
                status
                player{
                    status
                }
                zone{
                  preferences{
                    streamPlaybackVolume
                  }
                }
              }
              preferences{
                forbiddenTopics{
                  name
                  id
                }
                buttons{
                  button
                  media{
                    name
                    bid
                    id
                  }
                }
                  internalSpotsCount,

                    streamPlaybackVolume,
                    announcementPlaybackVolume,
                                jinglePlaybackVolume,
                    musicPlaybackVolume,
                
                  commercialSpotsCount,
                  isOpenedDuringHolidays,
                  isKeypadEnabled,
            
                  musicPlaybackOrder,
                  
                  isBeginJingleEnabled,
                  isEndJingleEnabled,
                  beginJingleMedia{
                    id
                    name
                  }
                  endJingleMedia{
                    id
                    name
                  }
                
                  segment {
                      id
                      name
                  }
                  spotsPlaybackVolume,
                  spotsPlaybackInterval,
                  spotsPlaybackStartOffset,
                  spotsPlaybackEndOffset,
                  isCommercialSlotEnabled,
                  isInternalSlotEnabled,
                  musicPlaylist{
                      name
                  }
                  musicStream{
                      name
                      id
                  }
                  beginJingleMedia{
                      id
                  }
                  endJingleMedia{
                      id
                  }
                  activeTimes{
                    weekDay
                    from
                    to
                  }
                  inactiveTimes{
                    from
                    to
                  }
                }
            }
              company{
                id,
                bid,
                name,
                ico,
                dic,
                icDPH,
                registrationCourt,
                address,
                postal,
                city,
                country,
                latitude,
                longtitude,
                bankName,
                bankIBAN,
                bankBIC,
                contactFirstName,
                contactLastName,
                contactPhone,
                contactEmail,
                commissionRate,
                createdAt,
                updatedAt,
                users{
                  id
                  firstName
                  lastName
                  phone
                  email
                }
                }
    }
}
`;

export default STORE_DETAIL;