import gql from 'graphql-tag';

export const COMMERCIAL_CAMPAIGN_CANCEL = gql`
    mutation commercialCampaignCancel($id: ID!){
        commercialCampaignCancel(id:$id){
            id
            name
        }
    }
`;

export const INTERNAL_CAMPAIGN_CANCEL = gql`
    mutation internalCampaignCancel($id: ID!){
        internalCampaignCancel(id:$id){
            id
            name
        }
    }
`;
