import { scope, action } from "common/enums/GroupsPermissionEnum"

const PermissionsEnum = [
    { scope: scope.COMMERCIAL_CAMPAIGN, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.COMMERCIAL_CAMPAIGN, action: action.DETAIL, parameters: ["own", "all"] },
    { scope: scope.COMMERCIAL_CAMPAIGN, action: action.CREATE, parameters: ["own", "all"] },
    { scope: scope.COMMERCIAL_CAMPAIGN, action: action.UPDATE, parameters: ["own", "all"] },
    { scope: scope.COMMERCIAL_CAMPAIGN, action: action.DELETE, parameters: ["own", "all"] },
    { scope: scope.COMMERCIAL_CAMPAIGN, action: action.APPROVE, parameters: ["own", "all"] },
    { scope: scope.COMMERCIAL_CAMPAIGN, action: action.REJECT, parameters: ["own", "all"] },
    { scope: scope.COMMERCIAL_CAMPAIGN, action: action.CANCEL, parameters: ["own", "all"] },

    { scope: scope.INTERNAL_CAMPAIGN, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.INTERNAL_CAMPAIGN, action: action.DETAIL, parameters: ["own", "all"] },
    { scope: scope.INTERNAL_CAMPAIGN, action: action.CREATE, parameters: ["own", "all"] },
    { scope: scope.INTERNAL_CAMPAIGN, action: action.UPDATE, parameters: ["own", "all"] },
    { scope: scope.INTERNAL_CAMPAIGN, action: action.DELETE, parameters: ["own", "all"] },
    { scope: scope.INTERNAL_CAMPAIGN, action: action.APPROVE, parameters: ["own", "all"] },
    { scope: scope.INTERNAL_CAMPAIGN, action: action.REJECT, parameters: ["own", "all"] },
    { scope: scope.INTERNAL_CAMPAIGN, action: action.CANCEL, parameters: ["own", "all"] },

    { scope: scope.COMPANY, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.COMPANY, action: action.DETAIL, parameters: ["own", "all"] },
    { scope: scope.COMPANY, action: action.CREATE, parameters: ["all"] },
    { scope: scope.COMPANY, action: action.UPDATE, parameters: ["own", "all"] },
    //{ scope: scope.COMPANY, action: action.DELETE, parameters: ["own", "all"] },
    { scope: scope.COMPANY, action: action.MEMBERS, parameters: ["own", "all"] },

    { scope: scope.COMMISSION, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.COMMISSION, action: action.DETAIL, parameters: ["own", "all"] },
    { scope: scope.COMMISSION, action: action.EXPORT, parameters: ["own", "all"] },

    { scope: scope.DEVICE, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.DEVICE, action: action.DETAIL, parameters: ["own", "all"] },
    { scope: scope.DEVICE, action: action.DISCOVER, parameters: ["all"] },
    { scope: scope.DEVICE, action: action.RESTART, parameters: ["own", "all"] },
    { scope: scope.DEVICE, action: action.ENABLE, parameters: ["own", "all"] },
    { scope: scope.DEVICE, action: action.DISABLE, parameters: ["own", "all"] },
    { scope: scope.DEVICE, action: action.ASSIGNMENT, parameters: ["own", "all"] },
    { scope: scope.DEVICE, action: action.IDENTIFY, parameters: ["all"] },
    { scope: scope.DEVICE, action: action.TERMINAL, parameters: ["all"] },

    { scope: scope.INVOICE, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.INVOICE, action: action.DETAIL, parameters: ["own", "all"] },
    { scope: scope.INVOICE, action: action.CREATE, parameters: ["all"] },

    { scope: scope.MEDIA, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.MEDIA, action: action.DETAIL, parameters: ["own", "all"] },
    { scope: scope.MEDIA, action: action.UPLOAD, parameters: ["own", "all"] },
    { scope: scope.MEDIA, action: action.UPDATE, parameters: ["own", "all"] },
    { scope: scope.MEDIA, action: action.APPROVE, parameters: ["own", "all"] },
    { scope: scope.MEDIA, action: action.REJECT, parameters: ["own", "all"] },
    { scope: scope.MEDIA, action: action.DOWNLOAD, parameters: ["own", "all"] },
    { scope: scope.MEDIA, action: action.DELETE, parameters: ["own", "all"] },

    { scope: scope.PAYOUT, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.PAYOUT, action: action.DETAIL, parameters: ["own", "all"] },
    { scope: scope.PAYOUT, action: action.CREATE, parameters: ["all"] },

    { scope: scope.OPERATION, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.OPERATION, action: action.DETAIL, parameters: ["own", "all"] },

    { scope: scope.OVERVIEW, action: action.DETAIL, parameters: ["own", "all"] },

    { scope: scope.SETTINGS, action: action.DETAIL, parameters: ["all"] },
    { scope: scope.SETTINGS, action: action.UPDATE, parameters: ["all"] },

    { scope: scope.STORE, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.STORE, action: action.DETAIL, parameters: ["own", "all"] },
    { scope: scope.STORE, action: action.DELETE, parameters: ["own", "all"] },
    { scope: scope.STORE, action: action.CREATE, parameters: ["own", "all"] },
    { scope: scope.STORE, action: action.UPDATE, parameters: ["own", "all"], subparameters: ["campaigns", "jingles", "stream"] },

    { scope: scope.STORE, action: action.UPDATE_MEDIA_CAMPAIGNS, parameters: ["own", "all"] },
    { scope: scope.STORE, action: action.UPDATE_MEDIA_STREAMS, parameters: ["own", "all"] },
    { scope: scope.STORE, action: action.UPDATE_MEDIA_JINGLE, parameters: ["own", "all"] },

    { scope: scope.STREAM, action: action.LIST, parameters: ["all"] },
    { scope: scope.STREAM, action: action.DISCOVER, parameters: ["all"] },

    { scope: scope.USER, action: action.LIST, parameters: ["own", "all"] },
    { scope: scope.USER, action: action.DETAIL, parameters: ["own", "all"] },
    { scope: scope.USER, action: action.CREATE, parameters: ["all"] },
    { scope: scope.USER, action: action.UPDATE, parameters: ["all"] },
    { scope: scope.USER, action: action.DELETE, parameters: ["all"] },
    { scope: scope.USER, action: action.BLOCK, parameters: ["all"] },
    { scope: scope.USER, action: action.UNBLOCK, parameters: ["all"] },
    { scope: scope.USER, action: action.PASSWORDRESET, parameters: ["all"] },

    { scope: scope.GROUP, action: action.LIST, parameters: ["all"] },
    { scope: scope.GROUP, action: action.DETAIL, parameters: ["all"] },
    { scope: scope.GROUP, action: action.CREATE, parameters: ["all"] },
    { scope: scope.GROUP, action: action.UPDATE, parameters: ["all"] },
    { scope: scope.GROUP, action: action.DELETE, parameters: ["all"] },
]

export default PermissionsEnum;










    //{ scope: "zone", action: action.DETAIL, parameters: ["own", "all"] },
    //{ scope: "zone", action: "search", parameters: ["own", "all"] },
    //{ scope: "zone", action: action.CREATE, parameters: ["own", "all"] },
    //{ scope: "zone", action: action.UPDATE, parameters: ["own", "all"] },
    //{ scope: "zone", action: action.DELETE, parameters: ["own", "all"] },
    //{ scope: "zone", action: "manage-device", parameters: ["own", "all"] },

    //{ scope: "segment", action: action.DETAIL, parameters: ["all"] },
    //{ scope: "segment", action: action.CREATE, parameters: ["all"] },
    //{ scope: "segment", action: action.UPDATE, parameters: ["all"] },
    //{ scope: "segment", action: action.DELETE, parameters: ["all"] },

    //{ scope: scope.STREAM, action: action.DETAIL, parameters: ["all"]},
    //{ scope: scope.STREAM, action: action.CREATE, parameters: ["all"]},
    //{ scope: scope.STREAM, action: action.UPDATE, parameters: ["all"]},
    //{ scope: scope.STREAM, action: action.DELETE, parameters: ["all"]},

    //{ scope: "topic", action: action.DETAIL, parameters: ["all"]},
    //{ scope: "topic", action: action.CREATE, parameters: ["all"]},
    //{ scope: "topic", action: action.UPDATE, parameters: ["all"]},
    //{ scope: "topic", action: action.DELETE, parameters: ["all"]},

