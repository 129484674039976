import QueryTable from 'components-lib/QueryTable/QueryTable';
import React from 'react';
import { injectIntl } from 'react-intl';

export const OrderTable = (props) => {
    const { query, queryVariables, intl, defaultFilter, queryDataPath } = props

    const getColumns = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.commissionID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.storeID' }),
                    accessor: 'storeBid',
                    sortKey: 'storeBid',
                    filterKey: 'storeBid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.storeName' }),
                    accessor: "storeName",
                    sortKey: "storeName",
                    filterKey: 'storeName',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.address' }),
                    accessor: "address",
                    sortKey: "address",
                    filterKey: 'address',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.spots' }),
                    accessor: "spotsCount",
                    sortKey: "spotsCount",
                    filterKey: "spotsCount",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: intl.formatMessage({ id: 'commissionsList.table.price' }),
                    accessor: (rowData) => rowData.value + " €",
                    sortKey: "value",
                    filterKey: "value",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: " "
                }
            ]
        )
    }

    return (
        <QueryTable
            query={query}
            queryVariables={queryVariables}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            columns={getColumns()}
        />
    )
}

export default injectIntl(OrderTable);