import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ListAltIcon from '@material-ui/icons/ListAlt';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgetStyle.js";
import Button from "components/CustomButtons/Button";
import { generatePath } from "react-router-dom";
import paths from "paths";
import { invoiceList } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme));

const InvoicesAndPayoutsCard = (props) => {
    const {
        history,
        intl,
        allInvoices,
        invoicesVolume,
        allPayouts,
        payoutsVolume
    } = props;

    const classes = useStyles();

    const renderDetailButtons = () => {
        if (invoiceList)
            return (
                <Box >
                    <Button
                        color="info"
                        size="sm"
                        round
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.invoices.list)}`,
                            state: [Number(0)]
                        })}
                    >
                        {intl.formatMessage({ id: "dashboardCard.invoices.button" })}
                    </Button>
                    <Button
                        color="info"
                        size="sm"
                        round
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.invoices.list)}`,
                            state: [Number(1)]
                        })}
                    >
                        {intl.formatMessage({ id: "dashboardCard.payouts.button" })}
                    </Button>
                </Box>
            )
    }
    return <>
        <IconCard
            title={intl.formatMessage({ id: "dashboardCard.invoices.title" })}
            icon={<ListAltIcon />}
            height="80%"
            actions={renderDetailButtons()}
        >
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.invoices.allInvoices" })}
                    <Box className={classes.floatRight}>
                        {allInvoices}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.invoices.invoicesVolume" })}
                    <Box className={classes.floatRight}>
                        {invoicesVolume} €
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.payouts.allPayouts" })}
                    <Box className={classes.floatRight}>
                        {allPayouts}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.payouts.payoutsVolume" })}
                    <Box className={classes.floatRight}>
                        {payoutsVolume} €
                    </Box>
                </Grid>
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(InvoicesAndPayoutsCard);
