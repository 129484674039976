import gql from 'graphql-tag';

export const ALL_COMMERCIAL_CAMPAIGNS = gql`
    query commercialCampaignsTable(
        $offset: Int, 
        $limit:Int, 
        $sort:[CommercialCampaignsTableSortInput!],
        $filter:[CommercialCampaignsTableFilterInput]
    ) {
    commercialCampaignsTable(
        offset: $offset, limit:$limit, sort: $sort, filter: $filter
        ) {
            totalCount,
            items {
                id
                bid
                name
                acceptanceStatus
                owner
                status
                validFrom
                validTo
                maxBid
                budget
            }
        }
    }
`;

export const ALL_INTERNAL_CAMPAIGNS = gql`
    query internalCampaignsTable(
        $offset: Int, 
        $limit:Int, 
        $sort:[InternalCampaignsTableSortInput!],
        $filter:[InternalCampaignsTableFilterInput]
    ) {
    internalCampaignsTable(
        offset: $offset, limit:$limit, sort: $sort, filter: $filter
        ) {
            totalCount,
            items {
                id
                bid
                name
                acceptanceStatus
                owner
                spotType
                status
                validFrom
                validTo
            }
        }
    }
`;
