import gql from 'graphql-tag';

const MEDIAS_BY_COMPANY = (companyId) => gql`
query mediasByCompany(
  $offset: Int, 
  $limit: Int, 
  $sort: [MediaSortInput!], 
  $filter: [MediaFilterInput]){
    mediasByCompany(
      companyId: ${companyId}
      offset:$offset, 
      limit: $limit, 
      sort: $sort, 
      filter: $filter){
      totalCount,
      items{
        id
      	name
        category    
      }
    }
  }
`;

export default MEDIAS_BY_COMPANY;