import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { pathOr } from 'rambda';
import DeviceErrorCodesEnum from 'common/enums/DeviceErrorCodesEnum';
import Box from '@material-ui/core/Box';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { storeDetail, storeUpdate, storeDel } from 'configFiles/roles'
import Checkbox from "@material-ui/core/Checkbox";
import { colors } from "common/styles/configLayout.js";
import Check from "@material-ui/icons/Check";
import { Grid } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(styles);

export const StoreTable = (props) => {
    const classes = useStyles();

    const {
        history,
        intl,
        defaultFilter,
        queryDataPath,
        detail,
        data,
        loadData,
        refetch,
        query,
        queryVariables,
        numberOfRows,
        callbackButton,
        handleDrawerOpen,
        button,
        allStoreIds,
        multiUpdate,
        checkboxActive = false,
        open
    } = props



    const getDeviceStatus = (deviceStatus) => {
        switch (deviceStatus) {
            case "Online":
                return (
                    <Tooltip title={<span style={{ textAlign: "center", color: "#dcedc8" }}>{intl.formatMessage({ id: 'storeList.deviceStatus.tooltip.online' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ textAlign: "center", color: "green", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            case "Offline":
                return (
                    <Tooltip title={<span style={{ color: "#ffcdd2" }}>{intl.formatMessage({ id: 'storeList.deviceStatus.tooltip.offline' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ color: "red", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            case "Unassigned":
                return (
                    <Tooltip title={<span style={{ color: "#fafafa" }}>{intl.formatMessage({ id: 'storeList.playerStatus.tooltip.without' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ color: "gray", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            default:
                return ("-");
        }
    }


    const getPlayerStatus = (playerStatus) => {
        switch (playerStatus) {
            case "Playing":
                return (
                    <Tooltip title={<span style={{ textAlign: "center", color: "#dcedc8" }}>{intl.formatMessage({ id: 'storeList.playerStatus.tooltip.online' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ textAlign: "center", color: "green", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            case "Stopped":
                return (
                    <Tooltip title={<span style={{ color: "#ffcdd2" }}>{intl.formatMessage({ id: 'storeList.playerStatus.tooltip.offline' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ color: "red", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            case "Inactive":
                return (
                    <Tooltip title={<span style={{ color: "#fafafa" }}>{intl.formatMessage({ id: 'storeList.playerStatus.tooltip.without' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ color: "gray", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            default:
                return ("-");
        }
    }


    const onStoreDetail = (id) => {
        history.push(`/admin${generatePath(paths.stores.detail, { storeId: id })}`);
    }

    const setChecked = (id) => {
        let currentIndex = button.indexOf(Number(id));
        let newChecked = [...button]

        if (currentIndex === -1) {
            newChecked.push(Number(id));
        } else {
            newChecked.splice(currentIndex, 1);
        }
        callbackButton(newChecked);
    }

    const setUnchecked = () => {
        callbackButton([]);
    }

    const setAllChecked = () => {
        callbackButton([]);
    }
    const isChecked = (id) => {
        let help = null
        button.filter(buttonId => Number(buttonId) === Number(id)).map(item => (
            help = 1
        ))
        if (help === 1) return true
        else return false
    }

    const getColumns = () => {
        return (
            (!detail) ?
                [
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.ID' }),
                        accessor: (rowData) => <>
                            {checkboxActive &&
                                <>
                                    {(storeUpdate) &&
                                        <Checkbox
                                            key="key"
                                            //className={classes.positionAbsolute}
                                            checked={isChecked(rowData.id)}
                                            tabIndex={-1}
                                            onClick={() => setChecked(rowData.id)}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                        />
                                    }
                                </>
                            }

                            {rowData.bid}</>,
                        sortKey: 'bid',
                        filterKey: 'bid',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.name' }),
                        accessor: "name",
                        sortKey: "name",
                        filterKey: 'name',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.company' }),
                        accessor: "company",
                        sortKey: "company",
                        filterKey: 'company',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.address' }),
                        accessor: 'address',
                        sortKey: 'address',
                        filterKey: 'address',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.deviceStatus' }),
                        accessor: (rowData) => getDeviceStatus(rowData.deviceStatus),
                        show: false,
                        sortKey: 'deviceStatus',
                        filterKey: 'deviceStatus',
                        filterComponent: 'select',
                        filterOperator: 'eq',
                        filterOptions: [
                            {
                                value: 'Online',
                                label: intl.formatMessage({ id: 'storeList.online' })
                            },
                            {
                                value: 'Offline',
                                label: intl.formatMessage({ id: 'storeList.offline' })
                            },
                            {
                                value: 'Unassigned',
                                label: intl.formatMessage({ id: 'storeList.unassigned' })
                            },
                        ]
                    },
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.playerStatus' }),
                        accessor: (rowData) => getPlayerStatus(rowData.playerStatus),
                        show: false,
                        sortKey: 'playerStatus',
                        filterKey: 'playerStatus',
                        filterComponent: 'select',
                        filterOperator: 'eq',
                        filterOptions: [
                            {
                                value: 'Playing',
                                label: intl.formatMessage({ id: 'storeList.playing' })
                            },
                            {
                                value: 'Stopped',
                                label: intl.formatMessage({ id: 'storeList.stopped' })
                            },
                            {
                                value: 'Inactive',
                                label: intl.formatMessage({ id: 'storeList.unassigned' })
                            },
                        ]
                    },


                    (storeDetail) ?
                        {
                            Header: intl.formatMessage({ id: 'storeList.table.actions' }),
                            accessor: (rowData) =>
                                <Button
                                    round
                                    className={classes.tenantButtons}
                                    size="sm"
                                    color="info"
                                    onClick={() => onStoreDetail(rowData.id)}
                                >
                                    {intl.formatMessage({ id: 'storeList.table.actions.detail' })}
                                </Button>
                        } : { Header: " " }
                ] : [
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.ID' }),
                        accessor: 'bid',
                        sortKey: 'bid',
                        filterKey: 'bid',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.name' }),
                        accessor: "name",
                        sortKey: "name",
                        filterKey: 'name',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.address' }),
                        accessor: 'address',
                        sortKey: 'address',
                        filterKey: 'address',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.status' }),
                        accessor: (rowData) => getDeviceStatus(rowData.deviceStatus),
                        show: false,
                        sortKey: 'deviceStatus',
                        filterKey: 'deviceStatus',
                        filterComponent: 'select',
                        filterOperator: 'eq',
                        filterOptions: [
                            {
                                value: 'Online',
                                label: intl.formatMessage({ id: 'storeList.online' })
                            },
                            {
                                value: 'Offline',
                                label: intl.formatMessage({ id: 'storeList.offline' })
                            },
                            {
                                value: 'Unassigned',
                                label: intl.formatMessage({ id: 'storeList.unassigned' })
                            },
                        ]
                    },
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.playerStatus' }),
                        accessor: (rowData) => getPlayerStatus(rowData.playerStatus),
                        show: false,
                        sortKey: 'playerStatus',
                        filterKey: 'playerStatus',
                        filterComponent: 'select',
                        filterOperator: 'eq',
                        filterOptions: [
                            {
                                value: 'Playing',
                                label: intl.formatMessage({ id: 'storeList.playing' })
                            },
                            {
                                value: 'Stopped',
                                label: intl.formatMessage({ id: 'storeList.stopped' })
                            },
                            {
                                value: 'Inactive',
                                label: intl.formatMessage({ id: 'storeList.unassigned' })
                            },
                        ]
                    },


                    (storeDetail) ?
                        {
                            Header: intl.formatMessage({ id: 'storeList.table.actions' }),
                            accessor: (rowData) =>
                                <Button
                                    round
                                    className={classes.tenantButtons}
                                    size="sm"
                                    color="info"
                                    onClick={() => onStoreDetail(rowData.id)}
                                >
                                    {intl.formatMessage({ id: 'storeList.table.actions.detail' })}
                                </Button>
                        } : { Header: " " }
                ]
        )
    }

    const getColumnsMulti = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'storeList.table.ID' }),
                    accessor: 'bid',
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.name' }),
                    accessor: "name",
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.company' }),
                    accessor: "company",
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.state' }),
                    accessor: (rowData) => getDeviceStatus(rowData.deviceStatus),
                    show: false
                },
            ]
        )
    }

    return (
        (detail) ?
            <QueryTableRefresh
                data={data}
                loadData={loadData}
                queryVariables={queryVariables}
                permanentFilter={defaultFilter}
                queryDataPath={queryDataPath}
                numberOfRows={numberOfRows}
                columns={getColumns()
                }
            />
            :
            <>
                {(multiUpdate) ?
                    <QueryTable
                        query={query}
                        queryVariables={queryVariables}
                        permanentFilter={defaultFilter}
                        queryDataPath={queryDataPath}
                        numberOfRows={numberOfRows}
                        columns={(!multiUpdate) ? getColumns() : getColumnsMulti()}
                    />
                    :
                    <>
                        <QueryTableRefresh
                            data={data}
                            loadData={loadData}
                            queryVariables={queryVariables}
                            permanentFilter={defaultFilter}
                            queryDataPath={queryDataPath}
                            numberOfRows={numberOfRows}
                            columns={getColumns()}
                        />
                        {checkboxActive && <>
                            {((button.length !== 0) && !open) &&
                                <Box
                                    bgcolor="white"
                                    style={{ zIndex: 999, padding: "5px", position: "fixed", bottom: "5px" }}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Button
                                        color="success"
                                        onClick={() => { (button.length > 1) ? handleDrawerOpen() : onStoreDetail(button[0]) }}
                                        size="sm"
                                    >
                                        {intl.formatMessage({ id: 'storeList.multiUpdate.button.update' })} ({button.length} {
                                            (button.length > 4)
                                                ? intl.formatMessage({ id: "storeList.multiUpdate.store3" })
                                                : (button.length === 1)
                                                    ? intl.formatMessage({ id: "storeList.multiUpdate.store1" })
                                                    : intl.formatMessage({ id: "storeList.multiUpdate.store2" })

                                        })
                                    </Button>

                                    <Button
                                        disabled={allStoreIds?.length === button?.length}
                                        color="primary"
                                        size="sm"
                                        onClick={() => callbackButton(allStoreIds)}
                                    >
                                        {intl.formatMessage({ id: "storeList.multiUpdate.button.checkAll" })}
                                    </Button>

                                    <Button
                                        color="primary"
                                        size="sm"
                                        onClick={() => callbackButton([])}
                                    >
                                        {intl.formatMessage({ id: "storeList.multiUpdate.button.uncheckAll" })} ({button.length})
                                    </Button>
                                    {/*<Button
                                color="danger"
                                size="sm"
                                disabled={true}
                                //onClick={(button.length > 1) ? handleDrawerOpen : onStoreDetail}
                            >
                                {intl.formatMessage({ id: "storeList.multiUpdate.button.delete" })}
                            </Button>*/}
                                    <Grid item><hr /></Grid>

                                </Box>
                            }
                        </>
                        }
                    </>
                }

            </>
    )
}

export default injectIntl(StoreTable);