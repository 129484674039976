import gql from 'graphql-tag';

const INVOICE_CREATE = gql`
mutation invoiceCreate($input: InvoiceCreateInput!){
    invoiceCreate(input: $input){
        credit,
        price,
        description
    }
}
`;

export default INVOICE_CREATE;