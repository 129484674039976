import gql from 'graphql-tag';

export const COMMERCIAL_CAMPAIGN_DETAIL = gql`
query commercialCampaign($id:ID!){
    commercialCampaign(id: $id){
        id
        bid
        name
        description
        acceptanceStatus
        description
        validFrom
        validTo
        status
        createdAt
        createdBy{
            id
            fullName
        }

        criteria{
            locations{
              id
              name
            }
            segments{
                id
                name
          }
            companies{
                id
                name
          }
            zones{
                id
                name
            }
            match{
                locationsCount
                segmentsCount
                companiesCount
                zonesCount
            }
          }
        topics{
            id
            name
        }

        spots{
            playCount
            media{
                id
                bid
                name
            }
        }

        budget{
            total
            spent
            remaining
        }
        avgSpotPrice
        maxBid

        company{
            id
            bid
            name
            address
            postal
            city
            contactEmail
            contactPhone
            contactFirstName
            contactLastName
            
            stores{
                address
                postal
                city
                country
                latitude
                longtitude
                fullAddress
           }
        }
        
        minSpotInterval
        activeTimes{
            weekDay
            from
            to
        }

    }
}
`;

export const INTERNAL_CAMPAIGN_DETAIL = gql`
query internalCampaign($id:ID!){
    internalCampaign(id: $id){
        id
        bid
        name
        description
        acceptanceStatus
        description
        validFrom
        validTo
        status
        createdAt
        createdBy{
            id
            fullName
        }

        criteria{
            locations{
              id
              name
            }
            segments{
                id
                name
          }
            companies{
                id
                name
          }
            zones{
                id
                name
            }
            match{
                locationsCount
                segmentsCount
                companiesCount
                zonesCount
            }
          }
        topics{
            id
            name
        }

        spots{
            playCount
            media{
                id
                bid
                name
            }
        }
        company{
            id
            bid
            name
            address
            postal
            city
            contactEmail
            contactPhone
            contactFirstName
            contactLastName
            
            stores{
                address
                postal
                city
                country
                latitude
                longtitude
                fullAddress
           }
        }
        
        minSpotInterval
        activeTimes{
            weekDay
            from
            to
        }

    }
}`;