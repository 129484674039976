import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AutocompleteSelect from 'components-lib/AutocompleteSelect/AutocompleteSelect';
import IconCard from 'components-lib/IconCard/IconCard';
import Modal from 'components-lib/Modal/Modal';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import Button from "components/CustomButtons/Button.js";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import paths from 'paths';
import { isEmpty, pathOr } from 'rambda';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath, withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import getValidationSchema from "./validationSchema";
import StoreTable from '../Stores/StoreTable';
import PartnerTable from './CompanyCards/PartnerTable';
import AddressCard from './CompanyCards/AddressCard';
import BillingInfoCard from './CompanyCards/BillingInfoCard';
import CommissionCard from './CompanyCards/CommissionCard';
import ContactPersonCard from './CompanyCards/ContactPersonCard';
import AccountStatusCard from './CompanyCards/AccountStatusCard';
//import MapCard from './CompanyCards/MapCard';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import StoreIcon from '@material-ui/icons/Store';
import Loading from "components-lib/Loading/Loading";

import {
    storeCreate, storeList, companyManageMembers,
    userDetail, userList, userCreate, companyDetail,
    companyUpdate, operationList, commissionList, commissionDetail
} from 'configFiles/roles'

import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
import ALL_STORES from 'queries/StoresQueries/allStores';
import DELETE_COMPANY_MUTATION from 'queries/CompaniesQueries/companyDelete';
import UPDATE_COMPANY_MUTATION from 'queries/CompaniesQueries/companyUpdate';
import COMPANY_ADD_USER from "queries/CompaniesQueries/companyAddUser";
import STORE_ASSIGN_COMPANY from "queries/CompaniesQueries/storeAssignCompany";
import STORES_BY_COMPANY from "queries/CompaniesQueries/storesByCompany";
import PARTNERS_BY_COMPANY from 'queries/CompaniesQueries/partnersByCompany';
import ALL_PARTNERS from 'queries/CompaniesQueries/allPartners';

const useStyles = makeStyles((theme) => styles(theme))

const CompanyDetailPage = (props) => {
    const { intl, history } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    let backButton = (history.length > 1) ? true : false
    const companyId = props.match.params.companyId;
    const queryParams = { id: companyId }
    const [filterStores, setFilterStores] = useState([]);
    const [filterPartners, setFilterPartners] = useState([]);
    const [assignPartnerOpen, setAssignPartnerOpen] = useState(false);
    const [assignStoreOpen, setAssignStoreOpen] = useState(false);
    const [removeCompanyOpen, setRemoveCompanyOpen] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const [availableStores, setAvailableStores] = useState({});
    const [availablePartners, setAvailablePartners] = useState({});
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [storeId, setStoreId] = useState(-1);

    const { loading, data, refetch: refetchCompanyData } = useQuery(COMPANY_DETAIL, { variables: queryParams, skip: !companyDetail });
    const [partnersLoadData, { data: partnersTable, refetch: refetchPartnersTable }] = useLazyQuery(PARTNERS_BY_COMPANY(queryParams.id), { skip: !userList });
    const [storesLoadData, { data: storesTable, refetch: refetchStoresTable }] = useLazyQuery(STORES_BY_COMPANY(queryParams.id), { skip: !storeList });

    useEffect(() => {
        let storesSelectItems = pathOr([], 'storesTableByCompany.items', storesTable).map((store) => {
            return Number(store.id)
        })
        setFilterStores(storesSelectItems);
    }, [storesTable]);

    useEffect(() => {
        let partnersSelectItems = pathOr([], 'usersTableByCompany.items', partnersTable).map((partner) => {
            return Number(partner.id)
        })
        setFilterPartners(partnersSelectItems);
    }, [partnersTable]);

    let helpStore = (filterStores.length !== 0) ? filterStores : [-1]
    const { data: storesData } = useQuery((ALL_STORES),
        {
            variables: {
                filter: [
                    {
                        id: {
                            notIn: helpStore
                        }
                    }
                ]
            }
        }
    );
    const { data: partnersData } = useQuery((ALL_PARTNERS), { skip: !userList });
    const [companyAddUser] = useMutation(COMPANY_ADD_USER);
    const [storeAssignCompany] = useMutation(STORE_ASSIGN_COMPANY);
    const [updateCompany] = useMutation(UPDATE_COMPANY_MUTATION);
    const [deleteCompany] = useMutation(DELETE_COMPANY_MUTATION);

    useEffect(() => {
        let storesSelectItems = pathOr([], 'storesTable.items', storesData).map((store) => {
            return {
                value: pathOr('', 'id', store),
                label: `${pathOr('', 'id', store)} - ${pathOr('', 'name', store)} (${pathOr('', 'company', store)})`
            }
        })
        setAvailableStores(storesSelectItems);
    }, [storesData]);

    useEffect(() => {
        let partnersSelectItems = pathOr([], 'users.items', partnersData).map((partner) => {
            return {
                value: pathOr('', 'id', partner),
                label: `${pathOr('', 'id', partner)} - ${pathOr('', 'fullName', partner)}`
            }
        })
        setAvailablePartners(partnersSelectItems);
    }, [partnersData]);

    useEffect(() => {
        setCompanyData(pathOr({}, ['company'], data));
    }, [data]);

    const renderStoreButtons = () => {
        return (
            <>
                <hr />
                {(storeList) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        disabled={filterStores.length <= 0}
                        className={classes.floatRight}
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.stores.list)}`,
                            state: { id: { in: filterStores } }
                        })}
                    >
                        {intl.formatMessage({ id: 'storeList.table.allStore' })}
                    </Button>
                }
                {(storeCreate) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        className={classes.floatRight}
                        onClick={() => history.push(`/admin${generatePath(paths.stores.new)}`)}
                    >
                        {intl.formatMessage({ id: 'storeList.table.addStore' })}
                    </Button>
                }

                {(companyUpdate) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        className={classes.floatRight}
                        onClick={() => setAssignStoreOpen(true)}
                    >
                        {intl.formatMessage({ id: 'companyDetail.btn.assignStore' })}
                    </Button>
                }
            </>
        )
    }

    const renderPartnerButtons = () => {
        return (
            <>
                <hr />
                {(userList) &&
                    <Button
                        disabled={filterPartners.length <= 0}
                        color="info"
                        size="sm"
                        round
                        className={classes.floatRight}
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.users.list)}`,
                            state: { id: { in: filterPartners } }
                        })}
                    >
                        {intl.formatMessage({ id: 'storeDetail.partnerCard.allPartners' })}
                    </Button>
                }
                {(userCreate) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        className={classes.floatRight}
                        onClick={() => history.push(`/admin${generatePath(paths.users.create.partner)}`)}
                    >
                        {intl.formatMessage({ id: 'storeDetail.partnerCard.addPartner' })}
                    </Button>
                }
                {(companyManageMembers) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        className={classes.floatRight}
                        onClick={() => setAssignPartnerOpen(true)}
                    >
                        {intl.formatMessage({ id: 'companyDetail.btn.assignPartner' })}
                    </Button>
                }

            </>
        )
    }

    const handleCompanyUpdate = (values) => {
        const result = { ...values };
        delete result.id;
        delete result.bid;
        delete result.createdAt;
        delete result.updatedAt;
        delete result.__typename;
        delete result.account;
        updateCompany({
            variables: {
                id: companyId,
                input: { ...result }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'companyDetail.company.updateCompanySuccessful' }), { variant: 'success' });
            refetchCompanyData();
        }).catch((err) => {
            console.log('[error]', err);
        })
    };

    const companyDelete = () => {
        deleteCompany({
            variables: {
                id: companyId,
            }
        }).then(response => {
            history.push(`/admin${generatePath(paths.companies.list)}`);
            enqueueSnackbar(intl.formatMessage({ id: 'companyDetail.company.removeCompanySuccessful' }), { variant: 'success', })
        }).catch(err => {
            console.log('[error]', err);
        });
        setRemoveCompanyOpen(false)
    }

    const assignPartner = () => {
        companyAddUser({
            variables: {
                id: props.match.params.companyId,
                userId: selectedPartner.value
            }
        }).then(response => {
            enqueueSnackbar(intl.formatMessage({ id: 'company.partner.assign.success' }), { variant: 'success', })
            refetchPartnersTable()
        }).catch(err => {
            console.log('[error]', err);
        });
        setAssignPartnerOpen(false);
        setSelectedPartner('')
    }

    const assignStore = () => {
        storeAssignCompany({
            variables: {
                id: storeId.value,
                companyId: props.match.params.companyId
            }
        }).then(response => {
            enqueueSnackbar(intl.formatMessage({ id: 'company.store.assign.success' }), { variant: 'success', })
            refetchStoresTable();
        }).catch(err => {
            console.log('[error]', err);
        });
        setAssignStoreOpen(false);
        setStoreId(-1)
    }

    const handlePartnerModal = () => {
        setAssignPartnerOpen(false);
        setSelectedPartner('');
    }

    const handleStoreModal = () => {
        setAssignStoreOpen(false);
        setStoreId(-1);
    }

    const renderAssignPartnerActions = () => {
        return (
            <>
                <Button
                    disabled={!selectedPartner}
                    onClick={() => assignPartner()}
                    color="success" round size="sm">{intl.formatMessage({ id: 'companyDetail.btn.assign' })}
                </Button>
                <Button
                    onClick={() => handlePartnerModal()}
                    color="primary" round size="sm">{intl.formatMessage({ id: 'companyList.addModal.cancel' })}
                </Button>
            </>
        )
    }

    const renderAssignOperActions = () => {
        return (
            <>
                <Button
                    disabled={storeId === -1}
                    onClick={() => assignStore()} color="success" round size="sm">
                    {intl.formatMessage({ id: 'companyDetail.btn.assign' })}
                </Button>
                <Button onClick={() => handleStoreModal()} color="primary" round size="sm">
                    {intl.formatMessage({ id: 'companyList.addModal.cancel' })}
                </Button>
            </>
        )
    }

    const renderRemoveCompanyActions = () => {
        return (
            <>
                <Button onClick={() => companyDelete()} color="success" round size="sm">
                    {intl.formatMessage({ id: 'companyDetail.btn.remove' })}
                </Button>
                <Button onClick={() => setRemoveCompanyOpen(false)} color="primary" round size="sm">
                    {intl.formatMessage({ id: 'companyList.addModal.cancel' })}
                </Button>
            </>
        )
    }

    const renderStoreTable = () => {
        return <>
            <StoreTable
                history={history}
                data={storesTable}
                loadData={storesLoadData}
                numberOfRows={5}
                queryDataPath={['storesTableByCompany', 'items']}
                detail={true}
            />
            {renderStoreButtons()}
        </>
    }


    if (loading) return <Loading />
    return (
        <>
            <PageHeader
                title={pathOr('', ['company', 'name'], data)}
                subTitle={pathOr('', ['company', 'bid'], data)}
                handleBackAction={(e) => history.goBack()}
                withBackButton={backButton}
            />
            <Formik
                enableReinitialize
                initialValues={companyData}
                validationSchema={getValidationSchema(intl)}
            >
                {(formikProps) => (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={8}>
                                {(storeList) &&
                                    <Grid>
                                        <IconCard
                                            icon={<StoreIcon />}
                                            title={intl.formatMessage({ id: 'storeList.table.title' })}
                                        >
                                            {renderStoreTable()}
                                        </IconCard>
                                    </Grid>
                                }
                                {(userList) &&
                                    <Grid>
                                        <IconCard
                                            icon={<AssignmentIndIcon />}
                                            title={intl.formatMessage({ id: 'companyDetail.partnersTable.title' })}
                                        >

                                            <PartnerTable
                                                companyId={queryParams.id}
                                                history={history}
                                                data={partnersTable}
                                                loadData={partnersLoadData}
                                                refetch={refetchPartnersTable}
                                                detail={true}
                                                numberOfRows={5}
                                                queryDataPath={['usersTableByCompany', 'items']}
                                                companyManageMembers={companyManageMembers}
                                                userDetail={userDetail}
                                            />

                                            {renderPartnerButtons()}
                                        </IconCard>
                                    </Grid>
                                }
                                {(commissionList && commissionDetail) &&
                                    <Grid>
                                        <CommissionCard
                                            companyId={companyId}
                                            history={history}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <AccountStatusCard
                                    history={history}
                                    allocated={pathOr('', ['company', 'account', 'allocated'], data)}
                                    balance={pathOr('', ['company', 'account', 'balance'], data)}
                                    id={pathOr('', ['company', 'account', 'id'], data)}
                                    companyId={queryParams.id}
                                    operationList={operationList}
                                />
                                <AddressCard
                                    readData={data.company}
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    companyUpdate={companyUpdate}
                                />
                                <ContactPersonCard
                                    readData={data.company}
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    companyUpdate={companyUpdate}
                                />
                                <BillingInfoCard
                                    readData={data.company}
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    companyUpdate={companyUpdate}
                                />
                                {/* <MapCard
                                   refetch={refetch}
                                    deviceId={pathOr(0, "device.id", data)}
                                    lat={pathOr(undefined, 'device.latitude', data)}
                                    lon={pathOr(undefined, 'device.longtitude', data)}
                                    address={pathOr("", 'device.address', data)}
                                    country={pathOr("", 'device.country', data)}
                                    postal={pathOr("", 'device.postal', data)}
                                    city={pathOr("", 'device.city', data)}
                                /> */}
                                {(!companyUpdate) ? "" :
                                    <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 1 }} >
                                        <Button
                                            round
                                            color="primary"
                                            onClick={(e) => isEmpty(formikProps.errors) && handleCompanyUpdate(formikProps.values)}
                                        >
                                            {intl.formatMessage({ id: 'userDetail.buttons.confirm' })}
                                        </Button>
                                        <Button round onClick={() => formikProps.resetForm()}>
                                            {intl.formatMessage({ id: 'settings.cancel' })}
                                        </Button>
                                    </div>
                                }
                            </Grid>
                        </Grid>

                        <Modal
                            title={intl.formatMessage({ id: 'companyDetail.btn.assignPartner' })}
                            open={assignPartnerOpen}
                            onClose={() => setAssignPartnerOpen(false)}
                            actions={renderAssignPartnerActions()}
                        >
                            <AutocompleteSelect
                                className={classes.selectStyle}
                                placeholder={intl.formatMessage({ id: 'deviceList.assignModal.partner' })}
                                label={intl.formatMessage({ id: 'deviceDetail.partner.title' })}
                                options={availablePartners}
                                onChange={(item) => setSelectedPartner(item)}
                                value={selectedPartner}
                            />
                        </Modal>
                        <Modal
                            title={intl.formatMessage({ id: 'companyDetail.btn.assignStore' })}
                            open={assignStoreOpen}
                            onClose={() => setAssignStoreOpen(false)}
                            actions={renderAssignOperActions()}
                        >
                            <AutocompleteSelect
                                onChange={(item) => setStoreId(item)}
                                className={classes.selectStyle}
                                placeholder={intl.formatMessage({ id: "deviceList.assignModal.store" })}
                                label={intl.formatMessage({ id: 'deviceList.table.store' })}
                                options={availableStores}
                                value={storeId}
                            />
                        </Modal>
                        <Modal
                            title={intl.formatMessage({ id: 'companyDetail.btn.removeCompany' })}
                            open={removeCompanyOpen}
                            onClose={() => setRemoveCompanyOpen(false)}
                            actions={renderRemoveCompanyActions()}
                        />
                    </>
                )}
            </Formik>
        </>
    )
}

export default injectIntl(withRouter(CompanyDetailPage));