import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import PageHeader from "../../components-lib/PageHeader/PageHeader";
import { Formik } from "formik";
import { Grid } from "@material-ui/core";
import BudgetCard from "./CampaignCards/BudgetCard";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { COMMERCIAL_CAMPAIGN_DETAIL, INTERNAL_CAMPAIGN_DETAIL } from "queries/CampaignsQueries/campaignDetail";
import { COMMERCIAL_CAMPAIGN_UPDATE, INTERNAL_CAMPAIGN_UPDATE } from "queries/CampaignsQueries/campaignUpdate";
import { COMMERCIAL_CAMPAIGN_APPROVE, INTERNAL_CAMPAIGN_APPROVE } from "queries/CampaignsQueries/campaignApprove";
import { COMMERCIAL_CAMPAIGN_REJECT, INTERNAL_CAMPAIGN_REJECT } from "queries/CampaignsQueries/campaignReject";
import { COMMERCIAL_CAMPAIGN_CANCEL, INTERNAL_CAMPAIGN_CANCEL } from "queries/CampaignsQueries/campaignCancel";
import { pathOr } from "rambda";
import SpotsCard from "./CampaignCards/SpotsCard";
import CompanyCard from "./CampaignCards/CompanyCard";
import InfoCard from "./CampaignCards/InfoCard";
import { makeStyles } from "@material-ui/core/styles";
import styles from "common/styles/widgets.js";
import Modal from "../../components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import paths from "../../paths";
import { getValidationSchemaDetailInternal, getValidationSchemaDetailCommercial } from "./validationSchema";
import LocationsCard from './CampaignCards/LocationsCard'
import SETTINGS_QUERY from 'queries/SettingsQueries/settingsQuery'
import {
    commercialCampaignUpdate, commercialCampaignCreate, commercialCampaignApp, commercialCampaignRej, commercialCampaignCan, companyDetail,
    internalCampaignUpdate, internalCampaignCreate, internalCampaignApp, internalCampaignRej, internalCampaignCan
} from 'configFiles/roles'
import DetailHoursCard from './CampaignCards/DetailHoursCard'
import Loading from "components-lib/Loading/Loading";

const useStyles = makeStyles((theme) => styles(theme));

const CampaignDetailPage = (props) => {

    const { intl, history } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const campaignId = props.match.params.campaignId
    const isInternal = props.match.path.indexOf('internal') > -1;

    let campaignUpdate = (isInternal) ? internalCampaignUpdate : commercialCampaignUpdate
    let campaignCreate = (isInternal) ? internalCampaignCreate : commercialCampaignCreate
    let campaignApp = (isInternal) ? internalCampaignApp : commercialCampaignApp
    let campaignRej = (isInternal) ? internalCampaignRej : commercialCampaignRej
    let campaignCan = (isInternal) ? internalCampaignCan : commercialCampaignCan
    let query = (isInternal) ? INTERNAL_CAMPAIGN_DETAIL : COMMERCIAL_CAMPAIGN_DETAIL
    let pathType = (isInternal) ? 'internalCampaign' : 'commercialCampaign'

    const { data: campaignData, loading: campaignLoading, refetch: refetchData } = useQuery(query, {
        variables: {
            id: campaignId
        }
    });


    const [removeCampaignModalOpen, setRemoveCampaignModalOpen] = useState(false)
    const [approveCampaignModalOpen, setApproveCampaignModalOpen] = useState(false)
    const [rejectCampaignModalOpen, setRejectCampaignModalOpen] = useState(false)
    const [resetState, setResetState] = useState(true)
    const [times, setTimes] = useState(pathOr([], [pathType, 'activeTimes'], campaignData));
    const [topics, setTopics] = useState([])

    const { data } = useQuery(SETTINGS_QUERY, { skip: !campaignUpdate });

    const [updateCommercialCampaign] = useMutation(COMMERCIAL_CAMPAIGN_UPDATE);
    const [updateInternalCampaign] = useMutation(INTERNAL_CAMPAIGN_UPDATE);
    const [cancelCommercialCampaign] = useMutation(COMMERCIAL_CAMPAIGN_CANCEL)
    const [cancelInternalCampaign] = useMutation(INTERNAL_CAMPAIGN_CANCEL)
    const [approveCommercialCampaign] = useMutation(COMMERCIAL_CAMPAIGN_APPROVE)
    const [approveInternalCampaign] = useMutation(INTERNAL_CAMPAIGN_APPROVE)
    const [rejectCommercialCampaign] = useMutation(COMMERCIAL_CAMPAIGN_REJECT)
    const [rejectInternalCampaign] = useMutation(INTERNAL_CAMPAIGN_REJECT)

    let set = (pathOr([], ['settings', 'defaultSpotPlaybackPrice'], data));
    let parsed = parseFloat(set);
    let status = pathOr('', [pathType, 'status'], campaignData)

    useEffect(() => {
        setTopics(pathOr([], [pathType, 'topics'], campaignData));
        //setStatus(pathOr('', [pathType, 'status'], campaignData));
        setResetState(true)
    }, campaignData)

    const getActions = () => {
        let actions = []

        if (status !== "Awaiting") {
            if (status === "Inactive" || status === "Scheduled" || status === "Running") {
                /*actions.push({
                    title: intl.formatMessage({ id: 'campaigns.duplicate.button' }),
                    onClick: () => console.log('route na new campaign with props')
                })*/
                if (campaignCan)
                    actions.push({
                        title: intl.formatMessage({ id: 'campaigns.end.button' }),
                        color: "danger",
                        onClick: () => setRemoveCampaignModalOpen(true)
                    })
            } if (status === "Finished")
                if (campaignCreate)
                    console.log("")
        } else if (status === "Awaiting") {
            if (campaignRej)
                actions.push({
                    title: intl.formatMessage({ id: 'campaigns.reject.button' }),
                    color: "danger",
                    onClick: () => setRejectCampaignModalOpen(true)
                })
            if (campaignApp)
                actions.push({
                    title: intl.formatMessage({ id: 'campaigns.approve.button' }),
                    color: "success",
                    onClick: () => setApproveCampaignModalOpen(true)
                })
        }

        return actions
    }

    const handleUpdateCampaign = (values) => {
        let tms
        if (times === true || times.length === 0) {
            tms = (pathOr([], [pathType, 'activeTimes'], campaignData))
            for (let i = 0; i < tms.length; i++) {
                setTimes(delete tms[i].__typename);

            }
        }

        for (let i = 0; i < times.length; i++) {
            setTimes(delete times[i].__typename);
        }
        if (isInternal) {
            updateInternalCampaign({
                variables: {
                    id: campaignId,
                    input: {
                        description: values.internalCampaign.description,
                        validTo: values.internalCampaign.validTo,
                        minSpotInterval: values.internalCampaign.minSpotInterval,
                        activeTimes: (tms) ? tms : times
                    }
                }
            }).then((response) => {
                const campaignName = pathOr('', ['data', 'campaignUpdate', 'name'], response);
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.update.success' }), { variant: 'success' });
                refetchData();
            }).catch((err) => {
                console.log(err);
            });
        } else {
            updateCommercialCampaign({
                variables: {
                    id: campaignId,
                    input: {
                        description: values.commercialCampaign.description,
                        validTo: values.commercialCampaign.validTo,
                        minSpotInterval: values.commercialCampaign.minSpotInterval,
                        activeTimes: (tms) ? tms : times,
                        maxBid: values.commercialCampaign.maxBid,

                    }
                }
            }).then((response) => {
                const campaignName = pathOr('', ['data', 'campaignUpdate', 'name'], response);
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.update.success' }), { variant: 'success' });
                refetchData();
            }).catch((err) => {
                console.log(err);
            });
        }

    };

    const removeCampaign = () => {
        if (isInternal) {
            cancelInternalCampaign({
                variables: {
                    id: campaignId,
                }
            }).then(response => {
                (isInternal)
                    ? history.push(`/admin${paths.campaigns.internal}`)
                    : history.push(`/admin${paths.campaigns.commercial}`)
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.cancel.success' }), {
                    variant: 'success',
                })
            }).catch(err => {
                console.log(err)
            });
        } else {
            cancelCommercialCampaign({
                variables: {
                    id: campaignId,
                }
            }).then(response => {
                (isInternal)
                    ? history.push(`/admin${paths.campaigns.internal}`)
                    : history.push(`/admin${paths.campaigns.commercial}`)
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.cancel.success' }), {
                    variant: 'success',
                })
            }).catch(err => {
                console.log(err)
            });
        }
        setRemoveCampaignModalOpen(false)
    }


    const approveCampaign = () => {
        if (isInternal) {
            approveInternalCampaign({
                variables: {
                    id: campaignId,
                }
            }).then(response => {
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.approve.success' }), {
                    variant: 'success',
                })
                refetchData()
            }).catch(err => {
                console.log(err)
            });
        } else {
            approveCommercialCampaign({
                variables: {
                    id: campaignId,
                }
            }).then(response => {
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.approve.success' }), {
                    variant: 'success',
                })
                refetchData()
            }).catch(err => {
                console.log(err)
            });
        }
        setApproveCampaignModalOpen(false)
    }

    const rejectCampaign = () => {
        if (isInternal) {
            rejectInternalCampaign({
                variables: {
                    id: campaignId,
                }
            }).then(response => {
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.reject.success' }), {
                    variant: 'success',
                })
                refetchData()
            }).catch(err => {
                console.log(err)
            });
        } else {
            rejectCommercialCampaign({
                variables: {
                    id: campaignId,
                }
            }).then(response => {
                enqueueSnackbar(intl.formatMessage({ id: 'campaigns.reject.success' }), {
                    variant: 'success',
                })
                refetchData()
            }).catch(err => {
                console.log(err)
            });
        }
        setRejectCampaignModalOpen(false)
    }



    const getRemoveCampaignModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => removeCampaign()}
                    color="danger"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.cancel.button' })}
                </Button>
                <Button
                    onClick={() => setRemoveCampaignModalOpen(false)}
                    color="primary" outlined round size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.removeModal.no' })}
                </Button>
            </>
        )
    }

    const getApproveCampaignModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => approveCampaign()}
                    color="success"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.approve.bigButton' })}
                </Button>
                <Button
                    onClick={() => setApproveCampaignModalOpen(false)}
                    color="primary" outlined round size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.removeModal.no' })}
                </Button>
            </>
        )
    }

    const getRejectCampaignModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => rejectCampaign()}
                    color="danger"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.reject.bigButton' })}
                </Button>
                <Button
                    onClick={() => setRejectCampaignModalOpen(false)}
                    color="primary" outlined round size="sm"
                >
                    {intl.formatMessage({ id: 'campaigns.removeModal.no' })}
                </Button>
            </>
        )
    }

    const renderRemoveCampaignModal = () => {
        return (
            <Modal
                title={intl.formatMessage({ id: 'campaigns.removeModal.title' })}
                open={removeCampaignModalOpen}
                onClose={() => setRemoveCampaignModalOpen(false)}
                actions={getRemoveCampaignModalActions()}
            >
                {intl.formatMessage({ id: 'campaigns.removeModal.text' })}
                <br />
                {pathOr('', [pathType, 'name'], campaignData)}?
            </Modal>
        )
    }

    const renderApproveCampaignModal = () => {
        return (
            <Modal
                title={intl.formatMessage({ id: 'campaigns.approve.bigButton' })}
                open={approveCampaignModalOpen}
                onClose={() => setApproveCampaignModalOpen(false)}
                actions={getApproveCampaignModalActions()}
            >
                {intl.formatMessage({ id: 'campaigns.approve.modalBody' })}
                <br />
                {pathOr('', [pathType, 'name'], campaignData)}?
            </Modal>
        )
    }

    const renderRejectCampaignModal = () => {
        return (
            <Modal
                title={intl.formatMessage({ id: 'campaigns.reject.bigButton' })}
                open={rejectCampaignModalOpen}
                onClose={() => setRejectCampaignModalOpen(false)}
                actions={getRejectCampaignModalActions()}
            >
                {intl.formatMessage({ id: 'campaigns.reject.modalBody' })}
                <br />
                {pathOr('', [pathType, 'name'], campaignData)}?
            </Modal>
        )
    }

    const getTitile = () => {
        return (
            (status === "Finished") ?
                pathOr('', [pathType, 'name'], campaignData) + ` (${intl.formatMessage({ id: 'campaigns.title.canceled' })})`
                : (status === "Rejected")
                    ? pathOr('', [pathType, 'name'], campaignData) + ` (${intl.formatMessage({ id: 'campaigns.title.rejected' })})`
                    : pathOr('', [pathType, 'name'], campaignData)
        )
    }
    if (campaignLoading) return <Loading />
    return (
        <>
            <>
                <PageHeader
                    title={getTitile()}
                    subTitle={pathOr('', [pathType, 'bid'], campaignData)}
                    actions={getActions()}
                    handleBackAction={(e) => history.goBack()}
                    withBackButton={true}
                />

                <Formik
                    onSubmit={(values) => { handleUpdateCampaign(values) }}
                    enableReinitialize
                    initialValues={campaignData}
                    validationSchema={(isInternal)
                        ? getValidationSchemaDetailInternal(intl, pathOr('', [pathType, 'validFrom'], campaignData))
                        : getValidationSchemaDetailCommercial(intl, parsed)
                    }
                >
                    {(formikProps) => (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <InfoCard
                                        formikProps={formikProps}
                                        name={pathOr('', [pathType, 'name',], campaignData)}
                                        type={(isInternal) ? "Internal" : "Commercial"}
                                        spotType={pathOr('', [pathType, 'spotType',], campaignData)}
                                        company={pathOr('', [pathType, 'company', 'name'], campaignData)}
                                        topics={pathOr('', [pathType, 'topics'], campaignData)}
                                        description={pathOr('', [pathType, 'description'], campaignData)}
                                        createdAt={pathOr(0, [pathType, 'createdAt',], campaignData)}
                                        createdBy={pathOr(0, [pathType, 'createdBy', 'fullName'], campaignData)}
                                        total={pathOr(0, [pathType, 'budget', 'total'], campaignData)}
                                        isCanceled={((status === "Rejected") || (status === "Finished"))}
                                        campaignUpdate={campaignUpdate}
                                        pathType={pathType}
                                    />
                                    <DetailHoursCard
                                        type={(isInternal) ? "Internal" : "Commercial"}
                                        times={(pathOr([], [pathType, 'activeTimes'], campaignData))}
                                        campaignId={(pathOr('', [pathType, 'id'], campaignData))}
                                        callbackTimes={(newValue) => setTimes(newValue)}
                                        formikProps={formikProps}
                                        withoutOpenInfo={true}
                                        isCanceled={((status === "Rejected") || (status === "Finished"))}
                                        update={campaignUpdate}
                                        pathType={pathType}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={8}>

                                    {!isInternal && <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} >
                                            <BudgetCard
                                                budgetSpent={pathOr('', [pathType, 'budget', 'spent'], campaignData)}
                                                budgetRemaining={pathOr('', [pathType, 'budget', 'remaining'], campaignData)}
                                                budgetTotal={pathOr('', [pathType, 'budget', 'total'], campaignData)}
                                                averageBid={pathOr('', [pathType, 'avgSpotPrice'], campaignData)}
                                                data={formikProps.values}
                                                formikProps={formikProps}
                                                campaignUpdate={campaignUpdate}
                                                isCanceled={((status === "Rejected") || (status === "Finished"))}
                                                pathType={pathType}
                                            //maxBid={pathOr('', [pathType, 'maxBid'], campaignData)}
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <SpotsCard
                                                spots={pathOr([], [pathType, 'spots'], campaignData)}
                                            />
                                        </Grid>
                                    </Grid>
                                    }
                                    <Grid item>
                                        {isInternal && <SpotsCard spots={pathOr([], [pathType, 'spots'], campaignData)} />}
                                        <LocationsCard
                                            data={formikProps.values}
                                            internal={isInternal}
                                            pathType={pathType}
                                        />

                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <CompanyCard
                                                className={classes.floatRight}
                                                history={history}
                                                companyId={pathOr('', [pathType, 'company', 'id'], campaignData)}
                                                companyBid={pathOr('', [pathType, 'company', 'bid'], campaignData)}
                                                companyName={pathOr('', [pathType, 'company', 'name'], campaignData)}
                                                companyAddress={pathOr('', [pathType, 'company', 'address'], campaignData)}
                                                companyPostal={pathOr('', [pathType, 'company', 'postal'], campaignData)}
                                                companyCity={pathOr('', [pathType, 'company', 'city'], campaignData)}
                                                companyContact={`
                                            ${pathOr('', [pathType, 'company', 'contactFirstName'], campaignData)} 
                                            ${pathOr('', [pathType, 'company', 'contactLastName'], campaignData)} 
                                        `}
                                                companyMail={pathOr('', [pathType, 'company', 'contactEmail'], campaignData)}
                                                companyPhone={pathOr('', [pathType, 'company', 'contactPhone'], campaignData)}
                                                companyDetail={companyDetail}
                                            />
                                        </Grid>

                                        {/*} <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <MapCard
                                                mapData={pathOr([], [pathType, 'company', 'stores'], campaignData)}
                                            />
                                        </Grid>*/}
                                    </Grid>


                                </Grid>

                            </Grid>
                            {((status === "Rejected") || (status === "Finished")) ?
                                <></>
                                : <>
                                    {campaignUpdate &&
                                        <div style={{ position: "fixed", bottom: "20px", right: "20px", }} >
                                            <Button
                                                round
                                                color="primary"
                                                onClick={(e) => {
                                                    formikProps.handleSubmit();
                                                }}
                                            >
                                                {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
                                            </Button>
                                            <Button
                                                round
                                                onClick={(e) => {
                                                    formikProps.resetForm();
                                                }}
                                            >
                                                {intl.formatMessage({ id: 'settings.cancel' })}
                                            </Button>
                                        </div>
                                    }
                                </>

                            }
                        </>
                    )}
                </Formik>
                {renderRemoveCampaignModal()}
                {renderApproveCampaignModal()}
                {renderRejectCampaignModal()}
            </>
        </>
    )
}

export default injectIntl(withRouter(CampaignDetailPage))