import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/Info';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import FormikField from "../../../components-lib/FormikField/FormikField";
import { pathOr } from "rambda";
import moment from "moment";

const useStyles = makeStyles((theme) => styles(theme));

const InfoCard = (props) => {
    const {
        formikProps,
        intl,
        type,
        spotType,
        name,
        company,
        topics,
        description,
        createdAt,
        createdBy,
        total,
        isFreePhoto,
        isCanceled,
        campaignUpdate,
        pathType
    } = props;

    const classes = useStyles();


    let date = String(createdAt).substr(0, 10)
    let createdTime = String(createdAt).substr(11, 8)
    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.infoCard.title' })}
            icon={<InfoIcon />}
        >
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.type' })}
                    <Box className={classes.floatRight}>
                        {type === "Commercial" ? intl.formatMessage({ id: 'campaigns.basicInfo.type.commercial' }) : intl.formatMessage({ id: 'campaigns.basicInfo.type.internal' })}
                    </Box>
                </Grid>
                {type === "Internal" &&
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.infoCard.spotType' })}
                        <Box className={classes.floatRight}>
                            {spotType === "Announcement" ? intl.formatMessage({ id: 'campaigns.infoCard.Announcement' }) : intl.formatMessage({ id: 'campaigns.infoCard.Advertisement' })}
                        </Box>
                    </Grid>
                }
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.name' })}
                    <Box className={classes.floatRight}>
                        {name}
                    </Box>
                </Grid>

                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.company' })}
                    <Box className={classes.floatRight}>
                        {company}
                    </Box>
                </Grid>

                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.topics' })}
                    <Box className={classes.floatRight}>
                        <Box className={classes.floatRight}>
                            {topics?.map((item, idx) => (
                                <>{item.name}, </>
                            ))}
                        </Box>
                    </Box>
                </Grid>



                <Grid container>
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <br />
                            <FormikField
                                disabled={true}
                                name={`${pathType}.validFrom`}
                                labelText={intl.formatMessage({ id: 'campaigns.infoCard.startDate' })}
                                formikProps={formikProps}
                                data={formikProps.values}
                                highlightChange
                                type="picker2"
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <br />
                            <FormikField
                                disabled={isCanceled}
                                name={`${pathType}.validTo`}
                                labelText={intl.formatMessage({ id: 'campaigns.infoCard.endDate' })}
                                formikProps={formikProps}
                                data={formikProps.values}
                                highlightChange
                                type="picker2"
                            />
                        </Grid>
                    </Grid>
                    {isCanceled
                        ?
                        <Grid item sm={12}>
                            <br />
                            {intl.formatMessage({ id: 'campaigns.infoCard.description' })}
                            <Box className={classes.floatRight}>
                                <p>{description.substring(0, 40)}</p>
                                <p>{description.substring(41, 80)}</p>
                                <p>{description.substring(81, 120)}</p>
                            </Box>
                        </Grid>
                        :
                        <FormikField
                            name={`${pathType}.description`}
                            type="multiline"
                            disabled={isCanceled || !campaignUpdate}
                            inputProps={{ rows: 2 }}
                            labelText={intl.formatMessage({ id: 'campaigns.infoCard.description' })}
                            helperText={170 - pathOr('', ['values', 'campaign', 'description'], formikProps).length}
                            formikProps={formikProps}
                            data={formikProps.values}
                            highlightChange
                        />

                    }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <hr style={{ marginTop: "15px" }} />
                    {intl.formatMessage({ id: 'campaigns.infoCard.createdBy' })}
                    <Box className={classes.floatRight}>
                        {createdBy}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.createdAt' })}
                    <Box className={classes.floatRight}>
                        {moment(createdAt).format("DD.MM.YYYY HH:mm:ss")}
                    </Box>
                </Grid>
            </Grid>
        </IconCard>

    </>
};

export default injectIntl(InfoCard);
