import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import EuroIcon from '@material-ui/icons/Euro';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import FormikField from "../../../components-lib/FormikField/FormikField";

const useStyles = makeStyles((theme) => styles(theme));

const BudgetCard = (props) => {

    const {
        intl,
        budgetTotal,
        budgetSpent,
        budgetRemaining,
        averageBid,
        formikProps,
        campaignUpdate,
        isCanceled,
        pathType
    } = props;

    const classes = useStyles();

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.budgetCard.title' })}
            icon={<EuroIcon />}
        >
            <>
                <Grid container spacing={1}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.budgetCard.budgetSpent' })}
                        <Box className={classes.floatRight}>
                            {(budgetSpent) ? budgetSpent : "0"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.budgetCard.budgetRemaining' })}
                        <Box className={classes.floatRight}>
                            {(budgetRemaining) ? budgetRemaining : "0"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.budgetCard.budgetTotal' })}
                        <Box className={classes.floatRight}>
                            {(budgetTotal) ? budgetTotal : "0"}
                        </Box>
                    </Grid>
                </Grid>
                <hr style={{ margin: "10px" }} />
                <Grid container spacing={1}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.budgetCard.AvgBid' })}
                        <Box className={classes.floatRight}>
                            {(averageBid) ? averageBid : "-"}
                        </Box>
                    </Grid>
                    {campaignUpdate ?
                        <Grid item sm={12}>
                            <FormikField
                                name={`${pathType}.maxBid`}
                                disabled={isCanceled}
                                labelText={intl.formatMessage({ id: 'campaigns.budgetCard.MaxBid' })}
                                formikProps={formikProps}
                                data={formikProps.values}
                                highlightChange
                            />
                        </Grid>
                        :
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'campaigns.budgetCard.MaxBid' })}
                            <Box className={classes.floatRight}>
                                {(formikProps.values.campaign.maxBid) ? formikProps.values.campaign.maxBid : "-"}
                            </Box>
                        </Grid>
                    }
                </Grid>
            </>
        </IconCard>
    </>
};

export default injectIntl(BudgetCard);
