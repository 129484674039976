import gql from 'graphql-tag';

const INVOICE_DETAIL = gql`
query invoice($id: ID!){
	invoice(id:$id){
  	id,
    bid,
    createdAt,
   	description
    credit
    price 
    createdAt
    description
    identifier
    createdBy{
    	id
      fullName
    }
    company{
    	id
      bid
      name
      address
      contactPhone
      contactEmail
      postal
      city
      country
      contactFirstName
      contactLastName
    }       
  }
}
`;

export default INVOICE_DETAIL;