import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import { default as React, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import { Grid } from "@material-ui/core";
import { colors } from "common/styles/configLayout.js";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => styles(theme));

const ScheduleCard = (props) => {

    const {
        intl,
        times,
        handleTimeChange,
    } = props;

    const classes = useStyles();


    //TODO: all component - create cycles
    const [mondayTo, setMondayTo] = useState()
    const [mondayFrom, setMondayFrom] = useState()
    const [tuesdayTo, setTuesdayTo] = useState()
    const [tuesdayFrom, setTuesdayFrom] = useState()
    const [wednesdayFrom, setWednesdayFrom] = useState()
    const [wednesdayTo, setWednesdayTo] = useState()
    const [thursdayTo, setThursdayTo] = useState()
    const [thursdayFrom, setThursdayFrom] = useState()
    const [fridayFrom, setFridayFrom] = useState()
    const [fridayTo, setFridayTo] = useState()
    const [saturdayFrom, setSaturdayFrom] = useState()
    const [saturdayTo, setSaturdayTo] = useState()
    const [sundayTo, setSundayTo] = useState()
    const [sundayFrom, setSundayFrom] = useState()
    const [isMondayEnabled, setIsMondayEnabled] = useState(false)
    const [isTuesdayEnabled, setIsTuesdayEnabled] = useState(false)
    const [isWednesdayEnabled, setIsWednesdayEnabled] = useState(false)
    const [isThursdayEnabled, setIsThursdayEnabled] = useState(false)
    const [isFridayEnabled, setIsFridayEnabled] = useState(false)
    const [isSaturdayEnabled, setIsSaturdayEnabled] = useState(false)
    const [isSundayEnabled, setIsSundayEnabled] = useState(false)

    useEffect(() => {
        Object.values(times).filter(word => word.weekDay === "Monday").map(week => {
            week.weekDay === "Monday" ? setIsMondayEnabled(true) : setIsMondayEnabled(false);
            return (
                {
                    from: setMondayFrom(week.from),
                    to: setMondayTo(week.to)
                }
            )
        })

        Object.values(times).filter(word => word.weekDay === "Tuesday").map(week => {
            week.weekDay === "Tuesday" ? setIsTuesdayEnabled(true) : setIsTuesdayEnabled(false)
            return (
                {
                    from: setTuesdayFrom(week.from),
                    to: setTuesdayTo(week.to),
                }
            )
        })
        Object.values(times).filter(word => word.weekDay === "Wednesday").map(week => {
            week.weekDay === "Wednesday" ? setIsWednesdayEnabled(true) : setIsWednesdayEnabled(false)
            return (
                {
                    from: setWednesdayFrom(week.from),
                    to: setWednesdayTo(week.to),
                }
            )
        })
        Object.values(times).filter(word => word.weekDay === "Thursday").map(week => {
            week.weekDay === "Thursday" ? setIsThursdayEnabled(true) : setIsThursdayEnabled(false)
            return (
                {
                    from: setThursdayFrom(week.from),
                    to: setThursdayTo(week.to),
                }
            )
        })
        Object.values(times).filter(word => word.weekDay === "Friday").map(week => {
            week.weekDay === "Friday" ? setIsFridayEnabled(true) : setIsFridayEnabled(false)
            return (
                {
                    from: setFridayFrom(week.from),
                    to: setFridayTo(week.to),
                }
            )
        })
        Object.values(times).filter(word => word.weekDay === "Saturday").map(week => {
            week.weekDay === "Saturday" ? setIsSaturdayEnabled(true) : setIsSaturdayEnabled(false)
            return (
                {
                    from: setSaturdayFrom(week.from),
                    to: setSaturdayTo(week.to),
                }
            )
        })
        Object.values(times).filter(word => word.weekDay === "Sunday").map(week => {
            week.weekDay === "Sunday" ? setIsSundayEnabled(true) : setIsSundayEnabled(false)
            return (
                {
                    from: setSundayFrom(week.from),
                    to: setSundayTo(week.to),
                }
            )
        })
    }, [times]);

    let i =1
    const renderContent = () => {
        if (i !== 1) {
            return (
                <Grid container spacing={2}>
                    {(isMondayEnabled) ?
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Monday" })} 
                            <Box className={classes.floatRight}>
                                {mondayFrom} - {mondayTo}
                            </Box>
                        </Grid>
                        :
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Monday" })} 
                            <Box className={classes.floatRight}>-</Box>
                        </Grid>
                    }
                    {(isTuesdayEnabled) ?
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Tuesday" })} 
                            <Box className={classes.floatRight}>
                                {tuesdayFrom} - {tuesdayTo}
                            </Box>
                        </Grid>
                        :
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Tuesday" })} 
                        </Grid>
                    }
                    {(isWednesdayEnabled) ?
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Wednesday" })} 
                            <Box className={classes.floatRight}>
                                {wednesdayFrom} - {wednesdayTo}
                            </Box>
                        </Grid>
                        :
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Wednesday" })} 
                            <Box className={classes.floatRight}>-</Box>
                        </Grid>

                    }
                    {(isThursdayEnabled) ?
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Thursday" })} 
                            <Box className={classes.floatRight}>
                                {thursdayFrom} - {thursdayTo}
                            </Box>
                        </Grid>
                        :
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Thursday" })} 
                            <Box className={classes.floatRight}>-</Box>
                        </Grid>
                    }
                    {(isFridayEnabled) ?
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Friday" })} 
                            <Box className={classes.floatRight}>
                                {fridayFrom} - {fridayTo}
                            </Box>
                        </Grid>
                        :
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Friday" })} 
                            <Box className={classes.floatRight}>-</Box>
                        </Grid>
                    }
                    {(isSaturdayEnabled) ?
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Saturday" })} 
                            <Box className={classes.floatRight}>
                                {saturdayFrom} - {saturdayTo}
                            </Box>
                        </Grid>
                        :
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Saturday" })} 
                            <Box className={classes.floatRight}>-</Box>
                        </Grid>
                    }
                    {(isSundayEnabled) ?
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Sunday" })} 
                            <Box className={classes.floatRight}>
                                {sundayFrom} - {sundayTo}
                            </Box>
                        </Grid>
                        :
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: "weekDay.Sunday" })} 
                            <Box className={classes.floatRight}>-</Box>
                        </Grid>
                    }
                </Grid>
            )
        }
        return (
            <>
                <Grid item sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ color: colors.main }}
                                checked={isMondayEnabled}
                                onClick={(e) => {
                                    setIsMondayEnabled(e.target.checked);
                                    setMondayFrom("00:00")
                                    setMondayTo("23:59")
                                    handleTimeChange("00:00", "23:59", tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                        thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                        e.target.checked, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                        isSaturdayEnabled, isSundayEnabled)
                                }}
                            />
                        }
                        classes={{ label: classes.label }}
                        label={props.day}
                    />
                    {isMondayEnabled ?
                        <>
                            {intl.formatMessage({ id: "weekDay.Monday" })} 
                            <form noValidate>
                                <label>{intl.formatMessage({ id: "commissionsList.table.pickerStart" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeFrom}
                                    value={mondayFrom}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setMondayFrom(e.target.value);
                                        handleTimeChange(e.target.value, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);
                                    }}
                                />
                                <label>   {intl.formatMessage({ id: "commissionsList.table.pickerEnd" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeTo}
                                    className={classes.textField}
                                    value={mondayTo}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setMondayTo(e.target.value);
                                        handleTimeChange(mondayFrom, e.target.value, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled)
                                    }}
                                />
                            </form>
                        </>
                        :
                        <>
                            {intl.formatMessage({ id: "weekDay.Monday" })} 

                        </>
                    }
                </Grid >

                <Grid item sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ color: colors.main }}
                                checked={isTuesdayEnabled}
                                onClick={(e) => {
                                    setIsTuesdayEnabled(e.target.checked);
                                    setTuesdayFrom("00:00")
                                    setTuesdayTo("23:59")
                                    handleTimeChange(mondayFrom, mondayTo, "00:00", "23:59", wednesdayFrom, wednesdayTo,
                                        thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                        isMondayEnabled, e.target.checked, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                        isSaturdayEnabled, isSundayEnabled)
                                }}
                            />
                        }
                        classes={{ label: classes.label }}
                        label={props.day}
                    />
                    {isTuesdayEnabled ?
                        <>
                            {intl.formatMessage({ id: "weekDay.Tuesday" })} 
                            <form noValidate>
                                <label>{intl.formatMessage({ id: "commissionsList.table.pickerStart" })} </label>
                                <TextField

                                    type="time"
                                    //defaultValue={activeTimeFrom}
                                    value={tuesdayFrom}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setTuesdayFrom(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, e.target.value, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);
                                    }}
                                />
                                <label>   {intl.formatMessage({ id: "commissionsList.table.pickerEnd" })} </label>
                                <TextField
                                    type="time"
                                    //defaultValue={activeTimeTo}
                                    className={classes.textField}
                                    value={tuesdayTo}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setTuesdayTo(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, e.target.value, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled)
                                    }}
                                />
                            </form>
                        </>
                        :
                        <>{intl.formatMessage({ id: "weekDay.Tuesday" })} </>
                    }
                </Grid >

                <Grid item sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ color: colors.main }}
                                checked={isWednesdayEnabled}
                                onClick={(e) => {
                                    setIsWednesdayEnabled(e.target.checked);
                                    setWednesdayFrom("00:00")
                                    setWednesdayTo("23:59")
                                    handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, "00:00", "23:59",
                                        thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                        isMondayEnabled, isTuesdayEnabled, e.target.checked, isThursdayEnabled, isFridayEnabled,
                                        isSaturdayEnabled, isSundayEnabled)
                                }}
                            />
                        }
                        classes={{ label: classes.label }}
                        label={props.day}
                    />
                    {isWednesdayEnabled ?
                        <>
                            {intl.formatMessage({ id: "weekDay.Wednesday" })} 
                            <form noValidate>
                                <label>{intl.formatMessage({ id: "commissionsList.table.pickerStart" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeFrom}
                                    value={wednesdayFrom}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setWednesdayFrom(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, e.target.value, wednesdayTo,
                                            thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);
                                    }}
                                />
                                <label>   {intl.formatMessage({ id: "commissionsList.table.pickerEnd" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeTo}
                                    className={classes.textField}
                                    value={wednesdayTo}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setWednesdayTo(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, e.target.value,
                                            thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);

                                    }}
                                />
                            </form>
                        </>
                        :
                        <>
                            {intl.formatMessage({ id: "weekDay.Wednesday" })} 
                        </>
                    }
                </Grid >

                <Grid item sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ color: colors.main }}
                                checked={isThursdayEnabled}
                                onClick={(e) => {
                                    setIsThursdayEnabled(e.target.checked)
                                    setThursdayFrom("00:00")
                                    setThursdayTo("23:59")
                                    handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                        "00:00", "23:59", fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                        isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, e.target.checked, isFridayEnabled,
                                        isSaturdayEnabled, isSundayEnabled)
                                }}
                            />
                        }
                        classes={{ label: classes.label }}
                        label={props.day}
                    />
                    {isThursdayEnabled ?
                        <>
                            {intl.formatMessage({ id: "weekDay.Thursday" })} 
                            <form noValidate>
                                <label>{intl.formatMessage({ id: "commissionsList.table.pickerStart" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeFrom}
                                    value={thursdayFrom}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setThursdayFrom(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            e.target.value, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);
                                    }}
                                />
                                <label>   {intl.formatMessage({ id: "commissionsList.table.pickerEnd" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeTo}
                                    className={classes.textField}
                                    value={thursdayTo}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setThursdayTo(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, e.target.value, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);
                                    }}
                                />
                            </form>
                        </>
                        :
                        <>
                            {intl.formatMessage({ id: "weekDay.Thursday" })} 
                        </>
                    }
                </Grid >

                <Grid item sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ color: colors.main }}
                                checked={isFridayEnabled}
                                onClick={(e) => {
                                    setIsFridayEnabled(e.target.checked);
                                    setFridayFrom("00:00")
                                    setFridayTo("23:59")
                                    handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                        thursdayFrom, thursdayTo, "00:00", "23:59", saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                        isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, e.target.checked,
                                        isSaturdayEnabled, isSundayEnabled)
                                }

                                }
                            />
                        }
                        classes={{ label: classes.label }}
                        label={props.day}
                    />
                    {isFridayEnabled ?
                        <>
                            {intl.formatMessage({ id: "weekDay.Friday" })} 
                            <form noValidate>
                                <label>{intl.formatMessage({ id: "commissionsList.table.pickerStart" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeFrom}
                                    value={fridayFrom}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setFridayFrom(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, thursdayTo, e.target.value, fridayTo, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);
                                    }}
                                />
                                <label>   {intl.formatMessage({ id: "commissionsList.table.pickerEnd" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeTo}
                                    className={classes.textField}
                                    value={fridayTo}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setFridayTo(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, thursdayTo, fridayFrom, e.target.value, saturdayFrom, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);
                                    }}
                                />
                            </form>
                        </>
                        :
                        <>
                            {intl.formatMessage({ id: "weekDay.Friday" })} 
                        </>
                    }
                </Grid >

                <Grid item sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ color: colors.main }}
                                checked={isSaturdayEnabled}
                                onClick={(e) => {
                                    setIsSaturdayEnabled(e.target.checked);
                                    setSaturdayFrom("00:00")
                                    setSaturdayTo("23:59")
                                    handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                        thursdayFrom, thursdayTo, fridayFrom, fridayTo, "00:00", "23:59", sundayFrom, sundayTo,
                                        isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                        e.target.checked, isSundayEnabled)
                                }

                                }
                            />
                        }
                        classes={{ label: classes.label }}
                        label={props.day}
                    />
                    {isSaturdayEnabled ?
                        <>
                            {intl.formatMessage({ id: "weekDay.Saturday" })} 
                            <form noValidate>
                                <label>{intl.formatMessage({ id: "commissionsList.table.pickerStart" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeFrom}
                                    value={saturdayFrom}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setSaturdayFrom(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, thursdayTo, fridayFrom, fridayTo, e.target.value, saturdayTo, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);
                                    }}
                                />
                                <label>   {intl.formatMessage({ id: "commissionsList.table.pickerEnd" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeTo}
                                    className={classes.textField}
                                    value={saturdayTo}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setSaturdayTo(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, e.target.value, sundayFrom, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);
                                    }}
                                />
                            </form>
                        </>
                        :
                        <>
                            {intl.formatMessage({ id: "weekDay.Saturday" })} 
                        </>
                    }
                </Grid >
                <Grid item sm={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ color: colors.main }}
                                checked={isSundayEnabled}
                                onClick={(e) => {
                                    setIsSundayEnabled(e.target.checked);
                                    setSundayFrom("00:00")
                                    setSundayTo("23:59")
                                    handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                        thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, "00:00", "23:59",
                                        isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                        isSaturdayEnabled, e.target.checked)

                                }

                                }
                            />
                        }
                        classes={{ label: classes.label }}
                        label={props.day}
                    />
                    {isSundayEnabled ?
                        <>
                            {intl.formatMessage({ id: "weekDay.Sunday" })} 
                            <form noValidate>
                                <label>{intl.formatMessage({ id: "commissionsList.table.pickerStart" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeFrom}
                                    value={sundayFrom}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setSundayFrom(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, tuesdayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, e.target.value, sundayTo,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled);
                                    }}
                                />
                                <label>{intl.formatMessage({ id: "commissionsList.table.pickerEnd" })} </label>
                                <TextField
                                    id="time"
                                    type="time"
                                    //defaultValue={activeTimeTo}
                                    className={classes.textField}
                                    value={sundayTo}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={(e) => {
                                        setSundayTo(e.target.value);
                                        handleTimeChange(mondayFrom, mondayTo, mondayFrom, tuesdayTo, wednesdayFrom, wednesdayTo,
                                            thursdayFrom, thursdayTo, fridayFrom, fridayTo, saturdayFrom, saturdayTo, sundayFrom, e.target.value,
                                            isMondayEnabled, isTuesdayEnabled, isWednesdayEnabled, isThursdayEnabled, isFridayEnabled,
                                            isSaturdayEnabled, isSundayEnabled)
                                    }}
                                />
                            </form>
                        </>
                        :
                        <>
                            {intl.formatMessage({ id: "weekDay.Sunday" })} 
                        </>
                    }
                </Grid >
            </>
        )
    }

    return (
        <>{renderContent()}</>
    )
};

export default injectIntl(ScheduleCard);
