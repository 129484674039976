import QueryTable from 'components-lib/QueryTable/QueryTableRefresh';
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { pathOr } from "rambda";
import Box from "@material-ui/core/Box";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import StreamStatusEnum from 'common/enums/StreamStatusEnum';

const StreamTable = (props) => {
    const {
        intl,
        data,
        loadData,
        defaultFilter = null,
        queryDataPath
    } = props

    const [streamPlaying, setStreamPlaying] = useState(null);
    const [isPlaying, setIsPlaying] = useState(null);

    const getStreamStatus = (rowData) => {
        const getStreamStatus = pathOr('', 'status', rowData);
        return getStreamStatus === StreamStatusEnum.ONLINE
            ? <Box color="success.main">{StreamStatusEnum.ONLINE}</Box>
            : <Box color="error.main">{StreamStatusEnum.OFFLINE}</Box>
    }

    const managePlayer = (rowData) => {
        return isPlaying && isPlaying === rowData.id
            ? <StopIcon
                style={{ cursor: 'pointer' }}
                onClick={() => stopAudio(rowData)}>
            </StopIcon>
            : <PlayArrowIcon
                style={{ cursor: 'pointer' }}
                onClick={() => playAudio(rowData)}>
            </PlayArrowIcon>
    }

    useEffect(() => {
        return () => {
            streamPlaying && stopAudio()
        }
    })

    const playAudio = (rowData) => {
        streamPlaying && stopAudio()
        const streamUrl = rowData.listenUrl;
        const stream = new Audio(streamUrl);
        setStreamPlaying(stream);
        setIsPlaying(rowData.id);
        stream.play();
    }

    const stopAudio = () => {
        streamPlaying.pause();
        setStreamPlaying(null);
        setIsPlaying(null);
    }

    const getColumns = () => {
        return [
            {
                Header: intl.formatMessage({ id: 'streams.table.id' }),
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'streams.table.name' }),
                accessor: 'name',
                sortKey: 'name',
                filterKey: 'name',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'streams.table.url' }),
                accessor: "url",
                sortKey: "url",
                filterKey: 'url',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'streams.table.state' }),
                accessor: (rowData) => getStreamStatus(rowData),
                sortKey: 'status',
                filterKey: 'status',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: StreamStatusEnum.ONLINE,
                        label: StreamStatusEnum.ONLINE
                    },
                    {
                        value: StreamStatusEnum.OFFLINE,
                        label: StreamStatusEnum.OFFLINE
                    }
                ]
            },
            {
                Header: intl.formatMessage({ id: 'streams.table.actions' }),
                accessor: (rowData) => ((rowData.status === StreamStatusEnum.ONLINE) && managePlayer(rowData))
            }
        ]
    }

    return (
        <>
            <QueryTable
                data={data}
                loadData={loadData}
                permanentFilter={defaultFilter}
                queryDataPath={queryDataPath}
                columns={getColumns()}
            />
        </>
    )
}

export default injectIntl(StreamTable)
