export let colors = {
    main: '#0D224B',
    buttons: '#0D224B',
    selectedItemInSidebar: '#0D224B',
    //background: '#F5F7F7',
    sidebarHidden: '#FFFFFF',
    sidebar: '#FFFFFF'
};
export const shadows = {
    icons: false,
};
export const rounded = {
    buttons: "30px",
};
export const defaultColors = {
    one: '#0D224B',
    //one: '#8E0F54',
    two: '#FFB203',
    three: '#DB261A',
    four: '#0D224B',
    five: '#255726'
}

