import { scope, action } from "common/enums/GroupsPermissionEnum"
class AuthGuard {
    grants = [];
    constructor(permissions) {
        this.grants = this.parse(permissions);
    }
    parse(permissions) {
        return permissions.map(p => {
            const match = p.match(/((?:[\w\-_]+)|\*):((?:[\w\-_]+)|\*):((?:all|own|\*))/);
            return (match) ? { resource: match[1], action: match[2], scope: match[3] } : null;
        })
            .filter(p => !!p)
            .sort((a, b) => this.compareGrants(a.resource, b.resource))
            .sort((a, b) => this.compareGrants(a.action, b.action))
            .sort((a, b) => this.compareGrants(a.scope, b.scope))
            .map(p => ({
                resource: new RegExp(p.resource.replace(/\*|all/, '.*')),
                action: new RegExp(p.action.replace(/\*|all/, '.*')),
                scope: ['all', '*'].includes(p.scope) ? 'all' : 'own'
            }))
    }
    compareGrants(valueA, valueB) {
        const isAnyA = ['all', '*'].includes(valueA);
        const isAnyB = ['all', '*'].includes(valueB);
        if (isAnyA && !isAnyB) return -1;
        if (!isAnyA && isAnyB) return 1;
        return 0
    }
    hasPermission(resource, action) {
        const grant = this.grants.find(g => g.resource.test(resource) && g.action.test(action));
        return (grant) ? grant.scope : null;
    }
}

let actions
if (window.localStorage.getItem("permissions")) {
    let permissionLocalStorage = window.localStorage.getItem("permissions");
    let helpPermission = (permissionLocalStorage)?.substring((permissionLocalStorage).indexOf("[") + 1)
    actions = helpPermission.split(',');
} else {
    actions = [
        "profile:list:own"
    ]
}

const ac = new AuthGuard(actions)
export const companyList = ac.hasPermission(scope.COMPANY, action.LIST)
export const companyDetail = ac.hasPermission(scope.COMPANY, action.DETAIL)
export const companyCreate = ac.hasPermission(scope.COMPANY, action.CREATE)
export const companyUpdate = ac.hasPermission(scope.COMPANY, action.UPDATE)
export const companyDelete = ac.hasPermission(scope.COMPANY, action.DELETE)
export const companyManageMembers = ac.hasPermission(scope.COMPANY, action.MEMBERS)

export const deviceList = ac.hasPermission(scope.DEVICE, action.LIST)
export const deviceAssign = ac.hasPermission(scope.DEVICE, action.ASSIGNMENT)
export const deviceDisc = ac.hasPermission(scope.DEVICE, action.DISCOVER)
export const deviceDetail = ac.hasPermission(scope.DEVICE, action.DETAIL)
export const deviceRestart = ac.hasPermission(scope.DEVICE, action.RESTART)
export const deviceEn = ac.hasPermission(scope.DEVICE, action.ENABLE) //changed
export const deviceDis = ac.hasPermission(scope.DEVICE, action.DISABLE) //changed
export const deviceIdentify = ac.hasPermission(scope.DEVICE, action.IDENTIFY) //changed
export const deviceTerminal = ac.hasPermission(scope.DEVICE, action.TERMINAL) //changed

export const storeList = ac.hasPermission(scope.STORE, action.LIST)
export const storeDetail = ac.hasPermission(scope.STORE, action.DETAIL)
export const storeCreate = ac.hasPermission(scope.STORE, action.CREATE)
export const storeUpdate = ac.hasPermission(scope.STORE, action.UPDATE)
export const storeDel = ac.hasPermission(scope.STORE, action.DELETE)

export const storeUpdateCampaigns = ac.hasPermission(scope.STORE, action.UPDATE_MEDIA_CAMPAIGNS)
export const storeUpdateStreams = ac.hasPermission(scope.STORE, action.UPDATE_MEDIA_STREAMS)
export const storeUpdateJingle = ac.hasPermission(scope.STORE, action.UPDATE_MEDIA_JINGLE)


export const commercialCampaignList = ac.hasPermission(scope.COMMERCIAL_CAMPAIGN, action.LIST)
export const commercialCampaignDetail = ac.hasPermission(scope.COMMERCIAL_CAMPAIGN, action.DETAIL)
export const commercialCampaignCreate = ac.hasPermission(scope.COMMERCIAL_CAMPAIGN, action.CREATE)
export const commercialCampaignUpdate = ac.hasPermission(scope.COMMERCIAL_CAMPAIGN, action.UPDATE)
export const commercialCampaignDelete = ac.hasPermission(scope.COMMERCIAL_CAMPAIGN, action.DELETE)
export const commercialCampaignApp = ac.hasPermission(scope.COMMERCIAL_CAMPAIGN, action.APPROVE)
export const commercialCampaignRej = ac.hasPermission(scope.COMMERCIAL_CAMPAIGN, action.REJECT)
export const commercialCampaignCan = ac.hasPermission(scope.COMMERCIAL_CAMPAIGN, action.CANCEL)

export const internalCampaignList = ac.hasPermission(scope.INTERNAL_CAMPAIGN, action.LIST)
export const internalCampaignDetail = ac.hasPermission(scope.INTERNAL_CAMPAIGN, action.DETAIL)
export const internalCampaignCreate = ac.hasPermission(scope.INTERNAL_CAMPAIGN, action.CREATE)
export const internalCampaignUpdate = ac.hasPermission(scope.INTERNAL_CAMPAIGN, action.UPDATE)
export const internalCampaignDelete = ac.hasPermission(scope.INTERNAL_CAMPAIGN, action.DELETE)
export const internalCampaignApp = ac.hasPermission(scope.INTERNAL_CAMPAIGN, action.APPROVE)
export const internalCampaignRej = ac.hasPermission(scope.INTERNAL_CAMPAIGN, action.REJECT)
export const internalCampaignCan = ac.hasPermission(scope.INTERNAL_CAMPAIGN, action.CANCEL)

export const streamList = ac.hasPermission(scope.STREAM, action.LIST)
export const streamDisc = ac.hasPermission(scope.STREAM, action.DISCOVER)

export const mediaList = ac.hasPermission(scope.MEDIA, action.LIST)
export const mediaUpload = ac.hasPermission(scope.MEDIA, action.UPLOAD)
export const mediaDownload = ac.hasPermission(scope.MEDIA, action.DOWNLOAD)
export const mediaApp = ac.hasPermission(scope.MEDIA, action.APPROVE)
export const mediaRej = ac.hasPermission(scope.MEDIA, action.REJECT)
export const mediaDel = ac.hasPermission(scope.MEDIA, action.DELETE)

export const userList = ac.hasPermission(scope.USER, action.LIST)
export const userCreate = ac.hasPermission(scope.USER, action.CREATE)
export const userDetail = ac.hasPermission(scope.USER, action.DETAIL)
export const userUpdate = ac.hasPermission(scope.USER, action.UPDATE)
export const userBlock = ac.hasPermission(scope.USER, action.BLOCK)
export const userUnblock = ac.hasPermission(scope.USER, action.UNBLOCK)
export const userPasswordReset = ac.hasPermission(scope.USER, action.PASSWORDRESET)

export const invoiceList = ac.hasPermission(scope.INVOICE, action.LIST)
export const invoiceDetail = ac.hasPermission(scope.INVOICE, action.DETAIL)
export const invoiceCreate = ac.hasPermission(scope.INVOICE, action.CREATE)

export const payoutList = ac.hasPermission(scope.PAYOUT, action.LIST)
export const payoutDetail = ac.hasPermission(scope.PAYOUT, action.DETAIL)
export const payoutCreate = ac.hasPermission(scope.PAYOUT, action.CREATE)

export const commissionList = ac.hasPermission(scope.COMMISSION, action.LIST)
export const commissionExport = ac.hasPermission(scope.COMMISSION, action.EXPORT)
export const commissionDetail = ac.hasPermission(scope.COMMISSION, action.DETAIL)

export const dashboardList = ac.hasPermission(scope.OVERVIEW, action.DETAIL)

export const operationList = ac.hasPermission(scope.OPERATION, action.LIST)

export const groupList = ac.hasPermission(scope.GROUP, action.LIST)
export const groupDetail = ac.hasPermission(scope.GROUP, action.DETAIL)
export const groupCreate = ac.hasPermission(scope.GROUP, action.CREATE)
export const groupUpdate = ac.hasPermission(scope.GROUP, action.UPDATE)
export const groupDelete = ac.hasPermission(scope.GROUP, action.DELETE)

export const settingsDetail = ac.hasPermission(scope.SETTINGS, action.DETAIL)
export const settingsUpdate = ac.hasPermission(scope.SETTINGS, action.UPDATE)

export const segmentList = ac.hasPermission(scope.SEGMENT, action.LIST)


/*const getPrivilegion = (actionParam) => {
    let actions

    if (window.localStorage.getItem("permissions")) {
        let permissionLocalStorage = window.localStorage.getItem("permissions");
        let helpPermission = (permissionLocalStorage)?.substring((permissionLocalStorage).indexOf("[") + 1)
        actions = helpPermission.split(',');
    } else {
        actions = [
            "profile:list:own"
        ]
    }
    let permission = null
    actions.filter(action => action.includes(actionParam)).map(item => (
        permission = item.substr(item.length - 3)
    ))
    return permission
}*/

