import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import CampaignTypeEnum from "../../common/enums/CampaignType";
import PageHeader from "../../components-lib/PageHeader/PageHeader";
import { Formik } from "formik";
import { getValidationSchemaInternal, getValidationSchemaCommercial } from "./validationSchema";
import { Grid } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ALL_TOPICS } from "./queries/allTopics";
import { pathOr } from "rambda";
import IconCard from "../../components-lib/IconCard/IconCard";
import Favorite from "@material-ui/icons/Favorite";
import paths from "../../paths";
import Button from "components/CustomButtons/Button.js";
import InfoCard from './CampaignCreateCards/InfoCard'
import BudgetCard from './CampaignCreateCards/BudgetCard'
import SpotsCard from './CampaignCreateCards/SpotsCard'
import MyHoursCard from './CampaignCreateCards/HoursCards/MyHoursCard'
import { useSnackbar } from "notistack";
import { CAMPAIGN_CREATE } from "./queries/campaignCreate"
import { generatePath } from "react-router-dom";
import Form from "../../components-lib/CampaignWidget/component/form/Form";
import { getAxiosClient } from "../../configFiles/axios";
import SETTINGS_QUERY from 'queries/SettingsQueries/settingsQuery'

const CampaignNewPage = (props) => {
    const { intl, history } = props
    const [times, setTimes] = useState([]);
    const [campaignType, setCampaignType] = useState(null);
    const [spots, setSpots] = useState([]);
    const [topics, setTopics] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [spotType, setSpotType] = useState(null)

    const { enqueueSnackbar } = useSnackbar();
    const [createCampaign] = useMutation(CAMPAIGN_CREATE);
    const isInternal = props.match.path.indexOf('internal') > -1;
    const axios = getAxiosClient()
    const forcedCompanyIds = [];
    let locationsIds = [];
    let segmentsIds = [];
    let companiesIds = [];
    let zonesIds = [];

    useEffect(() => {
        if (history.location.pathname.indexOf(CampaignTypeEnum.INTERNAL) > -1) {
            setCampaignType("Internal");
        }
        if (history.location.pathname.indexOf(CampaignTypeEnum.COMMERCIAL) > -1) {
            setCampaignType("Commercial");
        }
    }, [history.location])

    console.log("companyId", companyId)
    const { loading: topicsLoading } = useQuery(ALL_TOPICS)
    const { data } = useQuery(SETTINGS_QUERY);

    let set = (pathOr([], ['settings', 'defaultSpotPlaybackPrice'], data));
    let parsed = parseFloat(set);

    const handleCreateCampaign = (values) => {
        let setTopicIds = topics.map(topic => topic.value);
        let setSpotIds = spots.map(spot => spot.value);
        let setBudget = (!isInternal) ? Number(values.campaign.budget) : 0.0
        let setMaxBid = (!isInternal) ? Number(values.campaign.maxBid) : 0.0
        let setSpotType = null
        setSpotType =
            (isInternal) ?
                (spotType) ?
                    setSpotType = spotType
                    :
                    setSpotType = "Advertisement"
                :
                setSpotType = "Advertisement"

        createCampaign({
            variables: {
                input: {
                    type: campaignType,
                    spotType: setSpotType,
                    name: values.campaign.name,
                    companyId: values.campaign.companyId,
                    topicIds: setTopicIds,
                    description: values.campaign.description,
                    validFrom: values.campaign.validFrom,
                    validTo: values.campaign.validTo,
                    minSpotInterval: values.campaign.minSpotInterval,
                    activeTimes: times,
                    maxBid: setMaxBid,
                    budget: setBudget,
                    spotIds: setSpotIds,
                    criteria: {
                        locationIds: locationsIds,
                        segmentIds: segmentsIds,
                        companyIds: companiesIds,
                        zoneIds: zonesIds,
                    }
                }
            }
        }).then((response) => {
            const campaignName = pathOr('', ['data', 'campaignCreate', 'name'], response);
            const id = pathOr("", ["data", "campaignCreate", "id"], response);

            campaignType === "Internal"
                ? history.push(`/admin${generatePath(paths.campaigns.internalDetail, { campaignId: Number(id) })}`)
                : history.push(`/admin${generatePath(paths.campaigns.commercialDetail, { campaignId: Number(id) })}`)
            enqueueSnackbar(intl.formatMessage({ id: "campaigns.create.success" }), {
                variant: 'success'
            });
        }).catch((err) => {
            console.log(err);
        });

    };

    const handleChangeLocationsWidget = (locationIds, segmentIds, companyIds, zoneIds) => {
        locationsIds = locationIds
        segmentsIds = segmentIds
        companiesIds = companyIds
        zonesIds = zoneIds
    }

    return topicsLoading ? 'loading..' : (
        <>
            <PageHeader
                title={
                    campaignType === "Internal"
                        ? intl.formatMessage({ id: "campaigns.new.internal.title" })
                        : intl.formatMessage({ id: "campaigns.new.commercial.title" })
                }
                handleBackAction={(e) => history.goBack()}
            />

            <Formik
                initialValues={{}}
                onSubmit={(values) => {
                    handleCreateCampaign(values);
                }}
                validationSchema={(isInternal) ? getValidationSchemaInternal(intl) : getValidationSchemaCommercial(intl, parsed)}
            >
                {(formikProps) =>
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={4}>

                                <InfoCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    internal={isInternal}
                                    callback={(newValue) => setTopics(newValue)}
                                    callbackSpotType={(newValue) => setSpotType(newValue.value)}
                                    callbackCompanyId={(newValue) => {
                                        setCompanyId(newValue);
                                    }}
                                />
                                <MyHoursCard
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    times={[]}
                                    callbackTimes={(newValue) => setTimes(newValue)}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={6} lg={8}>
                                <Grid container spacing={3}>
                                    {!isInternal &&
                                        <Grid item xs={12} sm={12} md={6} lg={6} >
                                            <BudgetCard
                                                data={formikProps.values}
                                                formikProps={formikProps}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <SpotsCard
                                            internal={isInternal}
                                            spType={spotType}
                                            callback={(newValue) => setSpots(newValue)}
                                            compId={companyId}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid>
                                    {!isInternal ?
                                        <IconCard
                                            title={intl.formatMessage({ id: 'campaigns.targetingCard.title' })}
                                            icon={<Favorite />}
                                        >
                                            <Form
                                                axios={axios}
                                                campaignType={'Commercial'}
                                                forcedCompanyIds={forcedCompanyIds}
                                                onChange={handleChangeLocationsWidget}
                                            />
                                        </IconCard>
                                        : companyId &&
                                        <IconCard
                                            title={intl.formatMessage({ id: 'campaigns.targetingCard.title' })}
                                            icon={<Favorite />}
                                        >
                                            <Form
                                                axios={axios}
                                                campaignType={'Internal'}
                                                forcedCompanyIds={[companyId]}
                                                onChange={handleChangeLocationsWidget}
                                            />
                                        </IconCard>
                                    }
                                </Grid>

                            </Grid>
                        </Grid>
                        <Button
                            round
                            color="primary"
                            onClick={(e) => {
                                formikProps.handleSubmit();
                            }}
                        >
                            {intl.formatMessage({ id: 'campaigns.new.createButton' })}
                        </Button>
                        <Button
                            round
                            onClick={() =>
                                campaignType === "Internal"
                                    ? history.push(`/admin${paths.campaigns.internal}`)
                                    : history.push(`/admin${paths.campaigns.commercial}`)
                            }
                        >
                            {intl.formatMessage({ id: 'settings.cancel' })}
                        </Button>
                    </>
                }
            </Formik>
        </>
    )
}

export default injectIntl(withRouter(CampaignNewPage))