import React, { useEffect, useState } from "react";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import IconCard from "components-lib/IconCard/IconCard";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import MusicVideoIcon from '@material-ui/icons/MusicVideo';
import Input from "@material-ui/core/Input";
import { Grid } from "@material-ui/core";
import FormikField from "components-lib/FormikField/FormikField";
import Switch from "@material-ui/core/Switch";
import { pathOr } from 'rambda';
import { useQuery } from "@apollo/react-hooks";
import ALL_STREAM_NAMES from "queries/StoresQueries/allStreamNames";
import MEDIAS_BY_COMPANY from "queries/StoresQueries/mediasByCompany";
import ALL_TOPICS from "queries/StoresQueries/allTopics";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import { colors } from "common/styles/configLayout.js";
import { withStyles } from '@material-ui/core/styles';
import Slider from "components-lib/Slider/Slider";
import { mediaList, storeUpdateCampaigns, storeUpdateStreams, storeUpdateJingle } from 'configFiles/roles'
import RestoreIcon from '@material-ui/icons/Restore';
import EditIcon from '@material-ui/icons/Edit';
import { convertToObject } from "typescript";
import Forward10Icon from '@material-ui/icons/Forward10';
import MultipleValuesField from "components-lib/MultipleValuesField/MultipleValuesField";
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => styles(theme));


const marks = [
    {
        value: 3,
        scaledValue: 3,
        label: <p style={{ paddingTop: "5px" }}>3m</p>,
    },
    {
        value: 60,
        scaledValue: 60,
        label: <p style={{ paddingTop: "5px" }}>1h</p>,
    },
    {
        value: 240,
        scaledValue: 240,
        label: <p style={{ paddingTop: "5px" }}>4h</p>,
    },
    {
        value: 475,
        scaledValue: 480,
        label: <p style={{ paddingTop: "5px" }}>8h</p>,
    },
];

const minValue = 3

function valueLabelFormat(value) {
    if (value >= 60 && value < 120) return "1+h"
    else if (value >= 120 && value < 180) return "2+h"
    else if (value >= 180 && value < 240) return "3+h"
    else if (value >= 240 && value < 300) return "4+h"
    else if (value >= 300 && value < 360) return "5+h"
    else if (value >= 360 && value < 420) return "6+h"
    else if (value >= 420 && value < 480) return "7+h"
    else if (value === 480) return "8h"
    else return value + "m"
}

const MediaCard = (props) => {
    const {
        data,
        formikProps,
        intl,
        commercialAuthorized,
        offlineRandom,
        internalAuthorized,
        intervalValue,
        forbidden,
        callbackForbidden,
        companyId,
        storeUpdate,
        multipleUpdate
    } = props;
    const classes = useStyles();

    const [availableStreams, setAvailableStreams] = useState([])
    const [availableTopics, setAvailableTopics] = useState([])
    const [availableJingles, setAvailableJingles] = useState([])
    const [haveCompany, setHaveCompany] = useState(0)

    const [streamChange, setStreamChange] = useState(multipleUpdate ? false : true);
    const [callbackIntervalChange, setCallbackIntervalChange] = useState(multipleUpdate ? false : true);
    const [internalEnabledChange, setInternalEnabledChange] = useState(multipleUpdate ? false : true);
    const [commercialEnabledChange, setCommercialEnabledChange] = useState(multipleUpdate ? false : true);
    const [internalSlotChange, setInternalSlotChange] = useState(multipleUpdate ? false : true);
    const [commercialSlotChange, setCommercialSlotChange] = useState(multipleUpdate ? false : true);
    const [isBeginJingleEnabledChange, setBeginJingleEnabledChange] = useState(multipleUpdate ? false : true);
    const [beginJingleChange, setBeginJingleChange] = useState(multipleUpdate ? false : true);
    const [isEndJingleEnabledChange, setEndJingleEnabledChange] = useState(multipleUpdate ? false : true);
    const [endJingleChange, setEndJingleChange] = useState(multipleUpdate ? false : true);
    const [musicChange, setMusicChange] = useState(multipleUpdate ? false : true);
    const [forbiddenTopicsChange, setForbiddenTopicsChange] = useState(multipleUpdate ? false : true);

    /*let offline = null
    useEffect(() => {
        offline = formikProps?.values?.zones?.[0]?.preferences?.musicPlaybackOrder
        if (offline === "Random")
            formikProps.setFieldValue("zones.0.preferences.musicPlaybackOrder", true)
        else formikProps.setFieldValue("zones.0.preferences.musicPlaybackOrder", false)
    }, [formikProps?.values?.zones?.[0]?.preferences?.musicPlaybackOrder])
*/
    const { data: allStreamsData } = useQuery(ALL_STREAM_NAMES, { skip: !storeUpdate })
    const { data: allTopics } = useQuery(ALL_TOPICS, { skip: !storeUpdate })
    const { data: allJingles } = useQuery(MEDIAS_BY_COMPANY((!multipleUpdate) ? companyId : haveCompany),
        {
            variables: {
                filter: [
                    {
                        category: {
                            eq: "Jingle",
                        },
                        acceptanceStatus: {
                            eq: "Approved"
                        }
                    }

                ]
            },
            skip: companyId === 0 || !storeUpdate || !mediaList || haveCompany === 0
        },
    );

    useEffect(() => {
        const comp = (Object.values(data))?.map(item => {
            return item?.company?.id
        })

        if (comp.length > 1)
            ((comp.every((val, i, arr) => val === arr[0])) === true)
                ? setHaveCompany(comp[0])
                : setHaveCompany(0)
        else setHaveCompany(0)
    }, [data])

    useEffect(() => {
        let streamsObj = pathOr([], ['streams', 'items'], allStreamsData).map((stream) => {
            return {
                value: stream.id,
                label: stream.name
            }
        })
        setAvailableStreams(streamsObj);
    }, [allStreamsData])


    useEffect(() => {
        let dd = pathOr([], ['mediasByCompany', 'items'], allJingles).map((jingle) => {
            return {
                value: jingle.id,
                label: jingle.name
            }
        })
        setAvailableJingles(dd);
    }, [allJingles])

    useEffect(() => {
        let dd = pathOr([], ['topics', 'items'], allTopics).map((topic) => {
            return {
                value: topic.id,
                label: topic.name
            }
        })
        setAvailableTopics(dd);
    }, [allTopics])

    const renderExcludedTopics = () => {
        return (
            <Grid item sm={12}>
                {!forbiddenTopicsChange ?
                    <>
                        {storeUpdateJingle &&
                            <MultipleValuesField
                                callback={(newValue) => setForbiddenTopicsChange(newValue)}
                                labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.forbiddenTopics' })}
                            />
                        }
                    </>
                    :
                    <>
                        <FormControl fullWidth style={{ marginBottom: "15px" }}>
                            {intl.formatMessage({ id: 'storeDetail.mediaCard.forbiddenTopics' })}
                            <AutocompleteSelect
                                style={{ marginBottom: "15px" }}
                                placeholder={intl.formatMessage({ id: 'storeDetail.mediaCard.chooseForbiddenTopics' })}
                                disabled={!storeUpdateCampaigns}
                                isMulti
                                options={availableTopics}
                                value={forbidden}
                                onChange={(e) => callbackForbidden(e)}
                                input={<Input />}
                            />
                            {(multipleUpdate) &&
                                <Grid item sm={12}>
                                    <FormHelperText>
                                        <span
                                            className={classes.multiUpdate}
                                            style={{ color: "blue" }}
                                            onClick={() => {
                                                callbackForbidden([])
                                                setForbiddenTopicsChange(false)
                                            }}
                                        >
                                            {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                        </span>
                                    </FormHelperText>
                                    <hr style={{ marginTop: "1px", marginBottom: "0px" }} />
                                </Grid>
                            }
                        </FormControl>
                    </>
                }
            </Grid>
        );
    };

    const renderStartJingle = () => {
        return (
            <Grid item sm={12}>
                {!isBeginJingleEnabledChange ?
                    <>
                        {storeUpdateJingle &&
                            <MultipleValuesField
                                callback={(newValue) => setBeginJingleEnabledChange(newValue)}
                                labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.beginJingle' })}
                                type={'toggle'}
                            />
                        }
                    </>
                    :
                    <>
                        <FormikField
                            name="zones.0.preferences.isBeginJingleEnabled"
                            labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.beginJingle' })}
                            formikProps={formikProps}
                            disabled={!storeUpdateJingle}
                            checkedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom.on' })}
                            uncheckedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom.off' })}
                            type="switch"
                            highlightChange
                            helperText={(multipleUpdate) &&
                                <span
                                    className={classes.multiUpdate}
                                    style={{ color: "blue" }}
                                    onClick={() => {
                                        formikProps.setFieldValue("zones.0.preferences.isBeginJingleEnabled", undefined);
                                        formikProps.setFieldValue("zones.0.preferences.beginJingleMedia.id", undefined);
                                        setBeginJingleEnabledChange(false)
                                    }}
                                >
                                    {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                </span>
                            }
                        />
                        {formikProps?.values?.zones?.[0]?.preferences?.isBeginJingleEnabled && <>
                            {!beginJingleChange ?
                                <>
                                    {storeUpdateJingle &&
                                        <MultipleValuesField
                                            callback={(newValue) => setBeginJingleChange(newValue)}
                                            labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.chooseBeginJingle' })}
                                        />
                                    }
                                </>
                                :
                                <FormikField
                                    name="zones.0.preferences.beginJingleMedia.id"
                                    labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.chooseBeginJingle' })}
                                    disabled={(formikProps?.values?.zones?.[0]?.preferences?.isBeginJingleEnabled) ? (storeUpdateJingle) ? false : true : true}
                                    formikProps={formikProps}
                                    highlightChange
                                    type="select"
                                    options={availableJingles}
                                    helperText={(multipleUpdate) &&
                                        <span
                                            className={classes.multiUpdate}
                                            style={{ color: "blue" }}
                                            onClick={() => { formikProps.setFieldValue("zones.0.preferences.beginJingleMedia.id", undefined); setBeginJingleChange(false) }}
                                        >
                                            {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                        </span>
                                    }
                                />
                            }
                        </>
                        }
                    </>
                }
            </Grid>
        )
    }

    const renderEndJingle = () => {
        return (
            <Grid item sm={12}>
                {!isEndJingleEnabledChange ?
                    <>
                        {storeUpdateJingle &&
                            <MultipleValuesField
                                callback={(newValue) => setEndJingleEnabledChange(newValue)}
                                labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.endJingle' })}
                                type={'toggle'}
                            />
                        }
                    </>
                    :
                    <>
                        <FormikField
                            name="zones.0.preferences.isEndJingleEnabled"
                            labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.endJingle' })}
                            formikProps={formikProps}
                            disabled={!storeUpdateJingle}
                            checkedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom.on' })}
                            uncheckedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom.off' })}
                            type="switch"
                            highlightChange
                            helperText={(multipleUpdate) &&
                                <span
                                    className={classes.multiUpdate}
                                    style={{ color: "blue" }}
                                    onClick={() => {
                                        formikProps.setFieldValue("zones.0.preferences.isEndJingleEnabled", undefined);
                                        formikProps.setFieldValue("zones.0.preferences.endJingleMedia.id", undefined);
                                        setEndJingleEnabledChange(false)
                                    }
                                    }
                                >
                                    {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                </span>
                            }
                        />
                        {formikProps?.values?.zones?.[0]?.preferences?.isEndJingleEnabled && <>
                            {!endJingleChange ?
                                <>
                                    {storeUpdateJingle &&
                                        <MultipleValuesField
                                            callback={(newValue) => setEndJingleChange(newValue)}
                                            labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.chooseEndJingle' })}
                                        />
                                    }
                                </>
                                :
                                <FormikField
                                    name="zones.0.preferences.endJingleMedia.id"
                                    labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.chooseEndJingle' })}
                                    disabled={(formikProps?.values?.zones?.[0]?.preferences?.isEndJingleEnabled) ? (storeUpdateJingle) ? false : true : true}
                                    formikProps={formikProps}
                                    highlightChange
                                    type="select"
                                    options={availableJingles}
                                    helperText={(multipleUpdate) &&
                                        <span
                                            className={classes.multiUpdate}
                                            style={{ color: "blue" }}
                                            onClick={() => { formikProps.setFieldValue("zones.0.preferences.endJingleMedia.id", undefined); setEndJingleChange(false) }}
                                        >
                                            {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                        </span>
                                    }
                                />
                            }
                        </>
                        }
                    </>
                }
            </Grid>
        )
    }

    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: "routes.media" })}
                className={classes.cardStyle}
                icon={<MusicVideoIcon />}
            >
                {storeUpdate ?
                    <Grid container spacing={1}>

                        <Grid item sm={12}>
                            {!streamChange ?
                                <>
                                    {storeUpdateStreams &&
                                        <MultipleValuesField
                                            callback={(newValue) => setStreamChange(newValue)}
                                            labelText={"Radio stream"}
                                        />
                                    }
                                </>
                                :
                                <FormikField
                                    name="zones.0.preferences.musicStream.id"
                                    labelText={"Radio stream"}
                                    formikProps={formikProps}
                                    disabled={!storeUpdateStreams}
                                    type="select"
                                    helperText={(multipleUpdate) &&
                                        <span
                                            className={classes.multiUpdate}
                                            style={{ color: "blue" }}
                                            onClick={() => { formikProps.setFieldValue("zones.0.preferences.musicStream.id", undefined); setStreamChange(false) }}
                                        >
                                            {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                        </span>
                                    }
                                    options={availableStreams}
                                />
                            }
                        </Grid>

                        <Grid item sm={12}>
                            {!callbackIntervalChange ?
                                <MultipleValuesField
                                    callback={(newValue) => setCallbackIntervalChange(newValue)}
                                    labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.callbackInterval' })}
                                    type={'slider'}
                                    icon={<Forward10Icon />}
                                />
                                :
                                <FormikField
                                    name="zones.0.preferences.spotsPlaybackInterval"
                                    labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.callbackInterval' })}
                                    formikProps={formikProps}
                                    type="slider"
                                    maxValueSlider={480}
                                    //minValueSlider={minValue}
                                    defaultValueSlider={100}
                                    marks={marks}
                                    valueLabel={valueLabelFormat}
                                    highlightChange
                                    helperText={(multipleUpdate) &&
                                        <span
                                            className={classes.multiUpdate}
                                            style={{ color: "blue" }}
                                            onClick={() => { formikProps.setFieldValue("zones.0.preferences.spotsPlaybackInterval", undefined); setCallbackIntervalChange(false) }}
                                        >
                                            {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                        </span>
                                    }
                                />
                            }
                        </Grid>

                        <Grid item sm={12}>
                            {!internalEnabledChange ?
                                <>
                                    {storeUpdateCampaigns &&
                                        <MultipleValuesField
                                            callback={(newValue) => setInternalEnabledChange(newValue)}
                                            labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.internalAuthorized' })}
                                            type={'toggle'}
                                        />
                                    }
                                </>
                                :
                                <>
                                    <FormikField
                                        name="zones.0.preferences.isInternalSlotEnabled"
                                        labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.internalAuthorized' })}
                                        formikProps={formikProps}
                                        disabled={!storeUpdateCampaigns}
                                        checkedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.authorized.on' })}
                                        uncheckedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.authorized.off' })}
                                        type="switch"
                                        highlightChange
                                        helperText={(multipleUpdate) &&
                                            <span
                                                className={classes.multiUpdate}
                                                style={{ color: "blue" }}
                                                onClick={() => {
                                                    formikProps.setFieldValue("zones.0.preferences.isInternalSlotEnabled", undefined);
                                                    formikProps.setFieldValue("zones.0.preferences.internalSpotsCount", undefined);
                                                    setInternalEnabledChange(false)
                                                }}
                                            >
                                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                            </span>
                                        }
                                    />
                                    {formikProps?.values?.zones?.[0]?.preferences?.isInternalSlotEnabled && <>
                                        {!internalSlotChange ?
                                            <MultipleValuesField
                                                callback={(newValue) => setInternalSlotChange(newValue)}
                                                labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.internalSpotsCount' })}
                                            />
                                            :
                                            <FormikField
                                                name="zones.0.preferences.internalSpotsCount"
                                                labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.internalSpotsCount' })}
                                                disabled={(formikProps?.values?.zones?.[0]?.preferences?.isInternalSlotEnabled) ? (storeUpdateCampaigns) ? false : true : true}
                                                formikProps={formikProps}
                                                highlightChange
                                                helperText={(multipleUpdate) &&
                                                    <span
                                                        className={classes.multiUpdate}
                                                        style={{ color: "blue" }}
                                                        onClick={() => { formikProps.setFieldValue("zones.0.preferences.internalSpotsCount", undefined); setInternalSlotChange(false) }}
                                                    >
                                                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                                    </span>
                                                }
                                            />
                                        }
                                    </>
                                    }
                                </>
                            }
                        </Grid>

                        <Grid item sm={12}>
                            {!commercialEnabledChange ?
                                <>
                                    {storeUpdateCampaigns &&
                                        <MultipleValuesField
                                            callback={(newValue) => setCommercialEnabledChange(newValue)}
                                            labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.commercialAuthorized' })}
                                            type={'toggle'}
                                        />
                                    }
                                </>
                                :
                                <>
                                    <FormikField
                                        name="zones.0.preferences.isCommercialSlotEnabled"
                                        labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.commercialAuthorized' })}
                                        formikProps={formikProps}
                                        disabled={!storeUpdateCampaigns}
                                        checkedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.authorized.on' })}
                                        uncheckedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.authorized.off' })}
                                        type="switch"
                                        highlightChange
                                        helperText={(multipleUpdate) &&
                                            <span
                                                className={classes.multiUpdate}
                                                style={{ color: "blue" }}
                                                onClick={() => {
                                                    formikProps.setFieldValue("zones.0.preferences.isCommercialSlotEnabled", undefined);
                                                    formikProps.setFieldValue("zones.0.preferences.commercialSpotsCount", undefined);
                                                    setCommercialEnabledChange(false)
                                                }}
                                            >
                                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                            </span>
                                        }
                                    />
                                    {formikProps?.values?.zones?.[0]?.preferences?.isCommercialSlotEnabled && <>
                                        {!commercialSlotChange ?
                                            <MultipleValuesField
                                                callback={(newValue) => setCommercialSlotChange(newValue)}
                                                labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.commercialSpotsCount' })}
                                            />
                                            :
                                            <FormikField
                                                name="zones.0.preferences.commercialSpotsCount"
                                                labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.commercialSpotsCount' })}
                                                disabled={(formikProps?.values?.zones?.[0]?.preferences?.isCommercialSlotEnabled) ? (storeUpdateCampaigns) ? false : true : true}
                                                formikProps={formikProps}
                                                highlightChange
                                                helperText={(multipleUpdate) &&
                                                    <span
                                                        className={classes.multiUpdate}
                                                        style={{ color: "blue" }}
                                                        onClick={() => {
                                                            formikProps.setFieldValue("zones.0.preferences.commercialSpotsCount", undefined); setCommercialSlotChange(false)
                                                        }}
                                                    >
                                                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                                    </span>
                                                }
                                            />
                                        }
                                    </>
                                    }
                                </>
                            }
                        </Grid>

                        <Grid item sm={12}>
                            <hr style={{ marginBottom: "1px" }} />
                            {renderExcludedTopics()}
                        </Grid>


                        {(!multipleUpdate) ?
                            <>
                                <Grid item sm={12}>
                                    {renderStartJingle()}
                                </Grid>
                                <Grid item sm={12}>
                                    {renderEndJingle()}
                                </Grid>
                            </>
                            : (haveCompany !== 0) &&
                            <>
                                <Grid item sm={12}>
                                    {renderStartJingle()}
                                </Grid>
                                <Grid item sm={12}>
                                    {renderEndJingle()}
                                </Grid>
                            </>
                        }

                        <Grid item sm={12}>
                            {(!musicChange) ?
                                <MultipleValuesField
                                    callback={(newValue) => setMusicChange(newValue)}
                                    labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom' })}
                                    type={'toggle'}
                                />
                                :
                                <FormikField
                                    name="zones.0.preferences.musicPlaybackOrder"
                                    labelText={intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom' })}
                                    checkedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom.on' })}
                                    uncheckedLabelText={intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom.off' })}
                                    formikProps={formikProps}
                                    type="switch"
                                    highlightChange
                                    helperText={(multipleUpdate) &&
                                        <span
                                            className={classes.multiUpdate}
                                            style={{ color: "blue" }}
                                            onClick={() => { formikProps.setFieldValue("zones.0.preferences.musicPlaybackOrder", undefined); setMusicChange(false) }}
                                        >
                                            {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                                        </span>
                                    }
                                />
                            }
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'storeDetail.mediaCard.radioStream' })}
                            <Box className={classes.floatRight}>
                                {(formikProps.values.zones?.[0].preferences?.musicStream?.name) ? (formikProps.values.zones?.[0].preferences.musicStream.name) : "-"}
                            </Box>
                            <hr style={{ margin: "10px" }} />
                        </Grid>

                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'storeDetail.mediaCard.callbackInterval' })}
                            <Box className={classes.floatRight}>
                                {intervalValue}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'storeDetail.mediaCard.internalAuthorized' })}
                            <Box className={classes.floatRight}>
                                {internalAuthorized ? <label>{intl.formatMessage({ id: 'storeDetail.mediaCard.authorized.on' })}</label> : <label>{intl.formatMessage({ id: 'storeDetail.mediaCard.authorized.off' })}</label>}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'storeDetail.mediaCard.internalSpotsCount' })}
                            <Box className={classes.floatRight}>
                                {(formikProps.values.zones?.[0].preferences?.internalSpotsCount) ? (formikProps.values.zones?.[0].preferences.internalSpotsCount) : "-"}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'storeDetail.mediaCard.commercialAuthorized' })}
                            <Box className={classes.floatRight}>
                                {commercialAuthorized ? <label>{intl.formatMessage({ id: 'storeDetail.mediaCard.authorized.on' })}</label> : <label>{intl.formatMessage({ id: 'storeDetail.mediaCard.authorized.off' })}</label>}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'storeDetail.mediaCard.commercialSpotsCount' })}
                            <Box className={classes.floatRight}>
                                {(formikProps.values.zones?.[0].preferences?.commercialSpotsCount) ? (formikProps.values.zones?.[0].preferences.commercialSpotsCount) : "-"}
                            </Box>
                            <hr style={{ margin: "10px" }} />
                        </Grid>


                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'storeDetail.mediaCard.beginJingle' })}
                            <Box className={classes.floatRight}>
                                {(formikProps.values.zones?.[0].preferences?.beginJingleMedia?.name) ? (formikProps.values.zones?.[0].preferences.beginJingleMedia.name) : "-"}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'storeDetail.mediaCard.endJingle' })}
                            <Box className={classes.floatRight}>
                                {(formikProps.values.zones?.[0].preferences?.endJingleMedia?.name) ? (formikProps.values.zones?.[0].preferences.endJingleMedia.name) : "-"}
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom' })}
                            <Box className={classes.floatRight}>
                                {offlineRandom ? <label>{intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom.on' })}</label> : <label>{intl.formatMessage({ id: 'storeDetail.mediaCard.offlineRandom.off' })}</label>}
                            </Box>
                        </Grid>
                    </Grid>
                }
            </IconCard>
        </>
    );
};

export default injectIntl(MediaCard);
